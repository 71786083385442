@import '../../../../assets/sass/global';

.noResultsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.noResultsText {
  font-size: 16px;
  line-height: 20px;
  font-family: $font-source-sans;
  font-weight: 400;
  color: $seity-black;
  text-align: left;
}

.subcategoriesContainer {
  margin-top: 10px;
}
