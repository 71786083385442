@import "../../../../assets/sass/global";

.plotline-quiz-body {
  width: 100%;
  padding-bottom: rem(35);
  @include respond-to(mobile) {
    padding: 1rem 0;
  }

  .question {
    font-family: "Source Sans Pro";
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: $seity-dark-grey;
  }

  .answer-grid {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .answer-card {
      background: #ffffff;
      box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.1);
      padding: 0 2rem;
      width: 100%;
      height: 5rem;
      border-radius: 0.625rem;
      display: flex;
      align-items: center;
      gap: 1.5rem;
      cursor: pointer;

      img {
        height: 1.5rem;
        width: auto;
      }

      p {
        font-family: "Source Sans Pro";
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.76rem;
        color: $seity-black;
      }
    }

    .answer-card.selected {
      background-color: $seity-teal-medium;

      p {
        color: $seity-white;
      }
      img {
        height: 1.5rem;
        width: auto;
        filter: invert(99%) sepia(4%) saturate(50%) hue-rotate(211deg) brightness(116%) contrast(100%);
      }
    }
  }
}
