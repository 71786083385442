@import '../../../../assets/sass/global';

.cardContainer {
  margin: 8px 0;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subcategoryCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
}

.subcategoryImage {
  margin-right: 12px;
  width: 40px;
  height: 40px;
}

.svgPlaceholder {
  width: 40px;
  height: 40px;
  background-color: #e0e0e0;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.svgPlaceholderText {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}

.subcategoryTextContainer {
  flex: 1;
}

.subcategoryText {
  font-size: 16px;
  font-family: $font-source-sans-bold;
  color: $seity-black;
}

.dragonImage {
  width: 40px;
  height: 40px;
  margin-left: 12px;
}

.expandedContent {
  padding: 16px;
  background-color: #fff;
}

.dragonScoreText {
  font-size: 28px;
  font-weight: 600;
  font-family: $font-source-sans;
  color: $seity-black;
  text-align: center;
  margin-bottom: 4px;
}

.dragonTitleText {
  font-size: 18px;
  font-weight: 600;
  font-family: $font-source-sans;
  color: $seity-black;
  text-align: center;
  margin-bottom: 20px;
}

.dragonText {
  font-size: 14px;
  font-family: $font-source-sans;
  color: $seity-black;
  margin-bottom: 10px;
}

.retakeSurveyButton {
  margin-bottom: 10px;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid $seity-black;
  border-radius: 56px;
  color: $seity-black;
  padding: 10px;
  font-family: $font-source-sans;
  font-weight: 600;
  cursor: pointer;
}

.historyTitle {
  font-size: 18px;
  font-weight: 600;
  font-family: $font-source-sans;
  color: $seity-black;
  line-height: 28px;
  text-align: left;
  margin-bottom: 10px;
}

.historyContentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scoreHistoriesContainer {
  display: flex;
  align-items: center;
}

.scoreHistoryItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
}

.scoreHistoryDate {
  font-size: 12px;
  font-family: $font-source-sans;
  color: #677280;
  text-align: center;
  margin-top: 5px;
  white-space: nowrap;
}

.historyText {
  flex: 1;
  font-size: 14px;
  font-family: $font-source-sans;
  color: $seity-black;
  text-align: left;
  padding-left: 10px;
}

.scrollContainer {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
}

.chevronLeft,
.chevronRight {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.chevronLeft {
  position: absolute;
  left: 0;
  z-index: 1;
}

.chevronRight {
  position: absolute;
  right: 0;
  z-index: 1;
}

.scoreHistoriesScroll {
  flex: 1;
  margin: 0 30px;
  overflow-x: auto;
  display: flex;
  scrollbar-width: none; /* Firefox */
}

.scoreHistoriesScroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
