@import "../../../assets/sass/global";

.participation-widget-container {
  padding: 2rem;
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 3.25rem;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    margin-bottom: 3.75rem;

    h1 {
      font: 600 2.625rem $font-source-sans-pro-regular;
      color: $seity-black;
      line-height: 3.125rem;
    }
  }

  .daily-checkin {
    &-header {
      display: flex;
      gap: 0.5rem;
      height: fit-content;
      margin-bottom: 1rem;

      h3 {
        font: 600 1.625rem $font-source-sans;
        color: $seity-black;
        position: relative;
      }

      .more-info {
        height: 1rem;
        width: 1rem;
        cursor: pointer;
        display: none;
      }
    }

    > h5 {
      font: 400 1.25rem $font-source-sans;
      color: $seity-dark-grey;
    }
  }

  hr {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }

  .refresh-data {
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
      font: 400 1.25rem $font-source-sans;
      color: $seity-dark-grey;
    }

    div {
      display: flex;
      cursor: pointer;
      height: 2.3rem;
      width: 5.5rem;
      justify-content: center;
      align-items: center;
      background-color: #539fa3;
      border-radius: 6.25rem;

      img {
        height: 1.5rem;
        width: 1.5rem;
      }

      @keyframes rotating {
        from {
          -ms-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        to {
          -ms-transform: rotate(360deg);
          -moz-transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      .rotating {
        -webkit-animation: rotating 1s linear infinite;
        -moz-animation: rotating 1s linear infinite;
        -ms-animation: rotating 1s linear infinite;
        -o-animation: rotating 1s linear infinite;
        animation: rotating 1s linear infinite;
      }
    }
  }
}
