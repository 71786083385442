@import '../../../assets/sass/global';

.srb-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: rem(10) 0 rem(10) 0;

  img {
    margin: 0 rem(10) 0 rem(10);
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;

    @include respond-to(mobile) {
      margin: 0 rem(8) 0 rem(8);
    }
  }
}
