.outcome-assessment-intro-container {
  padding: 20px;
}

.intro-content-container {
  padding-bottom: 40px;
}

.intro-heading-container {
  margin-bottom: 10px;
}

.intro-category-heading {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #539fa3;
  margin-bottom: 4px;
}

.intro-section-name {
  font-size: 27px;
  font-weight: 600;
  line-height: 36px;
  color: #202223;
  margin: 0;
}

.intro-header-image-placeholder {
  width: 100%;
  border-radius: 12px;
  margin-bottom: 20px;
  background-color: #eee;
}

.intro-header-image {
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
  margin-bottom: 20px;
  transition: opacity 0.3s ease;
}

.intro-image-invisible {
  opacity: 0;
  position: absolute;
  left: -9999px;
}

.intro-text-container {
  margin-bottom: 20px;
}

.intro-section-heading {
  font-size: 20px;
  font-weight: 600;
  color: #202223;
  line-height: 36px;
  margin: 0 0 5px 0;
}

.intro-section-text {
  font-size: 14px;
  color: #1d1b20;
  line-height: 20px;
  margin: 0;
}

.intro-lock-image {
  display: block;
  width: 30%;
  margin: 20px auto;
}

.intro-disclaimer-text {
  font-size: 16px;
  font-weight: 700;
  color: #677280;
  line-height: 24px;
  margin-bottom: 40px;
  text-align: left;
}

.intro-footer-container {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.intro-footer-container button {
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
}

/* On mobile screens, make the button full width */
@media (max-width: 768px) {
  .intro-footer-container button {
    width: 100%;
    max-width: none;
  }
}
