@import "../../../assets/sass/global";

.intention-card {
  width: rem(436);
    @include respond-to(tablet) {
      width: rem(463);
    }
    @include respond-to(desktop) {
      width: rem(568);
    }
  &__header {
    background-color: $seity-grey;
    width: 100%;
    height: rem(291);
    position: relative;
    h3 {
      margin-right: rem(150);
      width: 100%;
      text-align: center;
      color: $seity-black;
      @include font-base($font-source-serif, normal, 600);
      font-size: rem(42);
    }
    .header-background {
      width: 100%;
      height: 100%;
      position: absolute;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top center;
    }
    .header-background-overlay {
      width: 100%;
      height: 100%;
      background-color: $seity-black;
      opacity: 0.7;
      position: absolute;
    }
    .header-wrap {
      z-index: 3;
      button img {
        object-fit: contain;
      }
      p {
        z-index: 7;
        text-align: center;
        @include font-base($font-source-serif, normal, 600);
        font-size: rem(28);
        color: white;
        white-space: pre-line;
      }
      > div {
        position: relative;
        .ellips-menu {
          background-color: white;
          border-radius: rem(16);
          position: absolute;
          right: rem(75);
          top: rem(35);
          z-index: 8;
          &__button {
            @include font-base($font-source-sans, normal, 600);
            font-size: rem(14);
            color: $seity-orange;
          }
        }
      }
      .check-icon {
        background-color: #34a77e;
        display: flex;
        width: rem(24);
        height: rem(24);
        border-radius: rem(12);
        margin-left: auto;
        margin-right: rem(65);
        margin-top: auto;
        margin-bottom: rem(24);
      }
    }
  }
  &__body {
    > button {
      width: rem(310);
      height: rem(60);
      @include font-base($font-source-sans, normal, 500);
      font-size: rem(21);
      color: #34a77e;
      background-color: transparent;
      border-radius: rem(56);
      border: rem(1) solid #34a77e;
      &.completed {
        background-color: #34a77e;
        color: white;
      }
    }
    > span {
      @include font-base($font-source-sans, normal, 600);
      letter-spacing: rem(1);
      font-size: rem(16);
      text-align: left;
      color: $journal-gray;
    }
    .cv-selection {
      background-color: white;
      border-radius: rem(16);
      height: rem(64);
      width: rem(437);
      box-shadow: 0 2px 4px 0 rgba(96, 97, 112, 0.16), 0 0 1px 0 rgba(40, 41, 61, 0.04);
      padding: 0 rem(24);
      margin-bottom: rem(116);
      margin-top: rem(10);
      &:hover {
        cursor: pointer;
        background-color: #f0f0f0;
      }
      &.disabled {
        pointer-events: none;
      }
      > div {
        margin: 0 !important;
      }
      > span {
        @include font-base($font-source-sans, normal, 400);
        font-size: rem(16);
        color: $journal-gray;
      }
      @include respond-to(tablet) {
        width: rem(462);
      }
      @include respond-to(desktop) {
        width: rem(559);
      }
    }
  }
}
