.practice-assessment-outro-container {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 20px;
  box-sizing: border-box;
}

.loadingContainer {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}

.errorText {
  color: red;
  font-size: 16px;
  text-align: center;
  margin: 10px 0;
}

.outro-content-container {
  padding-bottom: 20px;
}

.outro-heading-container {
  margin-bottom: 10px;
}

.outro-category-heading {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #539fa3;
  margin-bottom: 4px;
}

.outro-section-name {
  font-size: 27px;
  font-weight: 600;
  line-height: 36px;
  color: #202223;
  margin: 0;
}

.outro-header-image-placeholder {
  width: 100%;
  border-radius: 12px;
  margin-bottom: 20px;
}

.outro-header-image {
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
  margin-bottom: 20px;
  transition: opacity 0.3s ease;
}

.outro-image-invisible {
  opacity: 0;
  position: absolute;
  left: -9999px;
}

.outro-dragon-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.outro-dragon-image {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  margin-right: 16px;
  object-fit: cover;
}

.outro-dragon-text-container {
  flex: 1;
}

.outro-dragon-heading {
  font-size: 20px;
  font-weight: 600;
  color: #343B43;
  line-height: 30px;
  margin: 0 0 5px 0;
}

.outro-section-text {
  font-size: 14px;
  color: #1d1b20;
  line-height: 20px;
  margin: 0;
}

.outro-results-text {
  font-size: 16px;
  font-weight: 400;
  color: #677280;
  line-height: 24px;
  margin-bottom: 20px;
  text-align: left;
}

.outro-footer-container {
  position: sticky;
  bottom: 0;
  padding-top: 20px;
}

.outro-dragon-info-text {
  font-size: 16px;
  font-weight: 700;
  color: #677280;
  line-height: 24px;
  margin-bottom: 20px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

.outro-footer-container button {
  display: block;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .outro-footer-container button {
    width: 100%;
    max-width: none;
  }

  .outro-dragon-container {
    flex-direction: column;
    align-items: center;
  }

  .outro-dragon-image {
    margin: 0 0 16px 0;
  }

  .outro-dragon-text-container {
    text-align: center;
  }
}
