@import '../../../assets/sass/global';

.close-button-container {
  
  :hover {
    cursor: pointer;
  }
  &-header {
    top: $rem-desktop-header-height;
    @include respond-to(mobile) {
      right: rem(20);
    }
  }
  img {
    width: rem(32);
  }
}
