@import '../../../assets/sass/global';

.question-container {
  border-radius: rem(10);
  margin-bottom: rem(20);
  margin-top: rem(10);
  max-width: rem(650);
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  padding-bottom: rem(30);
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(96, 97, 112, 0.16),
    0 2px 4px 0 rgba(40, 41, 61, 0.04);
}

.question-not-answered {
  border: rem(2) solid $seity-orange;
}

.question-section-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.question-text {
  font-family: $font-source-sans;
  margin-left: rem(25);
  margin-top: rem(15);
  margin-right: rem(15);
  margin-bottom: rem(15);
  color: $seity-dark-green;
}

.no-answers-text {
  font-family: $font-source-sans;
  margin-left: rem(25);
  margin-top: rem(10);
  margin-right: rem(15);
  margin-bottom: rem(5);
  color: $seity-dark-green;
}

input[type='radio'] {
  -webkit-appearance: none;
  margin-left: rem(40);
  width: rem(25);
  height: rem(25);
  margin-right: rem(16);
  border: solid rem(2) $seity-teal;
  border-radius: 50%;
  outline: none;
  accent-color: $seity-teal;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type='radio']:checked:before {
  background: $seity-teal;
}

label {
  font-family: $font-source-sans;
  color: $seity-dark-green;
}

.input-div {
  display: flex;
  flex-direction: row;
  margin-top: rem(15);
}
