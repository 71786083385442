@import "../../../assets/sass/global";

.plotline-wellbeing-body {
  width: 100%;
  padding-bottom: rem(25);

  .title-2 {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: rem(21);
    line-height: 1.75rem;
    color: $seity-black;
    @include respond-to(desktop) {
      font-size: rem(26);
    }
  }

  .icon-grid {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;

    button {
      height: rem(56);
      width: fit-content;
      @include respond-to(tablet) {
        height: rem(128);
      }
      @include respond-to(desktop) {
        height: rem(194);
      }
    }

    img {
      width: auto;
      height: 100%;
      cursor: pointer;
    }
  }
}
