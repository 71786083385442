@import "../../../../assets/sass/global";

.fs-member-profile-container {
  padding: 1.5rem 2rem 3rem 2rem;
  width: 100%;

  .member-avatar {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .member-name {
    font: 600 2.625rem $font-source-sans-pro-regular;
    color: $seity-black;
    margin-bottom: 3rem !important;
    text-align: center;
  }

  .member-info {
    background: #fff;
    border-radius: 0.625rem;
    padding: 1rem;
    margin-bottom: 1.5rem;

    .info-item {
      display: flex;
      align-items: center;
      gap: 1rem;

      img {
        width: 2rem;
        height: 2rem;
      }

      p {
        font: 400 1rem $font-source-sans;
        color: #343b43;
      }
    }

    hr {
      background: #f0f0f0;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  .member-core-values {
    p {
      font: 600 1.125rem $font-source-sans;
      color: $seity-black;
    }

    .cv-item-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.875rem 1rem;
      border-radius: 0.625rem;
      background: #fff;
      box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.16), 0px 0px 2px 0px rgba(40, 41, 61, 0.04);
      margin-top: 1rem;
      cursor: pointer;

      > div {
        display: flex;
        align-items: center;
        gap: 1rem;

        p {
          font: 600 1.3125rem $font-source-sans;
        }
      }

      > img {
        height: 1.5rem;
        width: auto;
      }
    }
  }

  .remove-member {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 2rem;
    cursor: pointer;

    img {
      height: 1rem;
      width: 1rem;
    }

    p {
      font: 600 1rem $font-source-sans;
      color: #de5135;
    }
  }
}
