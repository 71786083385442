@import '../../../../assets/sass/global';

.sd-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: rem(55);
  margin-bottom: rem(200);
  max-width: rem(436);
  padding: 0 rem(10);
  @include respond-to(tablet) {
    margin-top: rem(62);
    max-width: rem(463);
    padding: 0;
  }
  @include respond-to(desktop) {
    max-width: rem(766);
    padding: 0;
  }
  p {
    color: $seity-dark-green;
    font: rem(18) / rem(20) $font-source-sans;
  }
  a {
    color: $seity-dark-green;

    &:hover {
      color: $seity-dark-green;
    }
  }
  &-wrapper {
    width: 100%;
    .emotionsPopup-wrap {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
  .check-icon {
    background-color: #34a77e;
    width: rem(32);
    height: rem(32);
    border-radius: rem(16);
    object-fit: none;
  }
}

.sd-back-button {
  margin-top: 0;
  @include respond-to(tablet) {
    margin-top: rem(16);
  }
  @include respond-to(desktop) {
    margin-top: rem(32);
  }
}

.sd-mask {
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(114, 142, 152, 0), #262626);
  padding: rem(24) rem(26.8);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @include respond-to(tablet) {
    padding: rem(24) rem(28.5);
  }
  @include respond-to(desktop) {
    padding: rem(24) rem(47);
  }
  h5 {
    font: rem(21) $font-source-sans;
    opacity: 0.8;
    text-align: left;
    color: $seity-white;
    width: 100%;
  }
  h1 {
    color: $seity-white;
    font: rem(40) $font-serif-display;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    z-index: 1;
    width: 100%;
    margin: rem(10) 0 0 0;
  }
  div {
    display: flex;
    align-items: center;
    span {
      font: rem(16) $font-source-sans;
      opacity: 0.8;
      color: $seity-white;
      margin-top: rem(-2);
    }
  }
}

.sd-background {
  background-size: cover;
  background-position: center center;
}

.sd-foreground {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: rem(326);
  width: 100%;
  z-index: 1;

  @include respond-to(tablet) {
    height: rem(346);
  }
  @include respond-to(desktop) {
    height: rem(572);
  }
}

.sd-description {
  position: relative;
  width: 100%;
  p {
    color: $seity-dark-green;
    font: rem(18) / rem(20) $font-source-sans;
  }
}

.sd-reference {
  border-top: rem(1) solid #e4e4e4;
  position: relative;
  width: 100%;
  margin-bottom: rem(8);
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4f7e81;
    font: 700 rem(18) / rem(20) $font-source-sans;
  }
  p {
    margin-bottom: rem(7) !important;
  }
}

.sd-story-choose {
  background-color: white;
  padding: rem(32);
  box-shadow: 0 2px 4px 0 rgba(96, 97, 112, 0.16), 0 0 1px 0 rgba(40, 41, 61, 0.04);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    height: rem(23);
  }
  p {
    text-align: center !important;
  }
  h3, h5 {
    text-align: center;
    color: $seity-black;
  }
  h3 {
    font: 700 rem(24) $font-source-sans;
  }
  h5 {
    margin-top: rem(40);
    margin-bottom: rem(40);
    font: 700 rem(21) $font-source-sans;
  }
}

.sd-sync-choose,
.sd-story-choose {
  p {
    font: rem(16) $font-source-sans;
    color: $seity-black;
    width: 100%;
  }
  button {
    margin-top: rem(24);
    border: solid rem(1) $seity-teal-medium;
    height: rem(60);
    border-radius: rem(56);
    width: 100%;
    color: $seity-teal-medium;
    font: 600 rem(18) $font-source-sans;
    &.disabled {
      opacity: 0.7;
      cursor: default;
    }
    &.selected {
      background-color: $seity-teal-medium;
      color: white;
    }
    &.incorrect {
      border: solid rem(2) #f75a3b;
      color: $seity-black;
    }
  }
  .completed-sync {
    padding: rem(32) 0;
    background-color: white;
    span {
      font: rem(18) $font-source-sans;
      color: $seity-black;
    }
  }
}
.sd-numberOfUsers {
  margin-top: rem(24) !important;
  font: rem(18) $font-source-sans;
  color: $seity-black;
  text-align: center;
  width: 100%;
}
.sd-actionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 0;
  height: rem(119);
  // background-color: #fbf9f7;
  object-fit: contain;
  background-image: linear-gradient(to bottom, rgba(217, 217, 217, 0) 0%, rgba(232, 231, 230, 0.44) 30%, var(--white-0) 100%);
  span {
    font: 400 rem(16) $font-source-sans;
    color: $seity-dark-grey;
  }
  button {
    width: rem(166);
    height: rem(55);
    border-radius: 100px;
    box-shadow: 0 8px 16px 0 rgba(96, 97, 112, 0.16), 0 2px 4px 0 rgba(40, 41, 61, 0.04);
    background-color: #fff;
    &:last-child {
      img {
        filter: invert(45%) sepia(21%) saturate(312%) hue-rotate(174deg) brightness(91%) contrast(84%);
      }
    }
  }
}