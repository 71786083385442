@import '../assets/sass/global';

html,
body {
  overscroll-behavior: none;
}

#root, .App {
    width: 100%;
}

.App {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: $seity-white;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.App.journals {
    background-color: $journal-bg;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.seity-main {
  position: relative;
  width: 100%;
  &.showNavbar {
    @include main-margin-top;
    @include main-min-height;
  }
  &.hideNavbar {
    margin-top: 0;
    min-height: 100vh;
  }
}

.seity-main-fullwidth {
  @include main-margin-top;
  @include main-full-width;
  @include main-min-height;
  position: absolute;
}

.seity-main-medium {
  @include main-margin-top;
  @include main-medium-width;
  @include main-min-height;
  position: absolute;
}

.seity-main-small {
  @include main-margin-top;
  @include main-small-width;
  @include main-min-height;
  position: absolute;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  margin: 0;
  min-width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: $seity-white !important;
}

p {
  margin-left: 1em;
  margin-top: 15px;
  font-size: medium;
  color: black;
}

.modal-content {
  background-color: #41625e !important;
  border-radius: none !important;
  border: none !important;
  height: 140px;
  color: white;
}

.modal-header {
  border: none;
  border-bottom: none !important;
}

.modal-footer {
  text-align: center;
  border-top: none !important;
  justify-content: center !important;
}

h1 {
  font-family: 'Source Serif Pro';
}
