@import '../../../../assets/sass/global';

.mainContainer {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 800px;
  padding: 0 16px;
}

.bannerContainer {
  border-radius: 16px;
  height: 250px;
  margin-bottom: 40px;
  position: relative;
}

.bannerImage {
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  position: relative;
  height: 100%;
  width: 100%;
}

.bannerContent {
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: 16px;
}

.bannerText {
  color: $seity-white;
  font-size: 2rem;
  font-weight: 600;
  font-family: $font-source-sans;
  text-align: left;
  line-height: 1.2;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.infoIconContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px;
  background: none;
  border: none;
  cursor: pointer;
}

.infoIcon {
  width: 24px;
  height: 24px;
}

.tabsContainer {
  display: flex;
  flex-direction: row;
  background-color: #FFFFFF;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.tab {
  flex: 1;
  padding: 20px 0;
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
}

.activeTab {
  border-bottom: 2px solid #545F71;
}

.inactiveTabLeft,
.inactiveTabRight {
  border-bottom: 2px solid $seity-white;
}

.tabContent {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.tabTextContainer {
  position: relative;
  display: inline-block;
}

.tabText {
  font-size: 16px;
  font-family: $font-source-sans;
  color: #545F71;
}

.activeTabText {
  font-weight: bold;
}

.inactiveTabText {
  color: $seity-black;
}

.bubble {
  position: absolute;
  top: -10px;
  right: -20px;
  background-color: #F75A3B;
  border-radius: 10px;
  min-width: 20px;
  height: 20px;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bubbleText {
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  font-family: $font-source-sans;
}

.scrollViewContent {
  padding: 0;
  overflow-y: auto;
  flex: 1;
  background-color: $seity-white;
  margin-top: 20px;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $seity-white;
  height: 100vh;
}

@media (max-width: 768px) {
  .mainContainer {
    margin-top: 20px;
    padding: 0 8px;
  }

  .bannerContent {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: center;
    text-align: center;
  }

  .bannerText {
    font-size: 1.5rem;
  }

  .tabText {
    font-size: 14px;
  }

  .bubble {
    right: -10px;
  }
}
