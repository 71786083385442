@import '../../../../assets/sass/global';

.register-five {
  &-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    color: $seity-dark-green;
    padding: rem(15) 0 rem(10) 0;
    h1 {
      font: rem(42) $font-source-serif !important;
      color: $seity-black !important;
      font-weight: 600 !important;
      margin: rem(56) 0 rem(56) 0;
      @include respond-to(mobile) {
        font: rem(35) $font-source-serif;
      }
    }
    h6 {
      font: rem(18) $font-source-sans;
      line-height: rem(30);
      @include respond-to(mobile) {
        font: rem(16) $font-source-serif;
        margin: rem(20) 0 0 0;
      }
    }
  }
  &-seity-logo {
    width: rem(160);
    height: rem(160);
  }
  &-next-button {
    width: 100%;
    max-width: rem(284);
  }
}
