@import "../../../assets/sass/global";

.seity-select-box-form-group {
    margin: 0 0 rem(20) 0;
    label {
        color: $seity-dark-green;
        font: rem(18) $font-source-sans;
    }
}

.seity-select-box-wrapper {
    position: relative;
    user-select: none;
    width: 100%;
}

.seity-select-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: start;
    width: 100%;

    &-icon {
        width: rem(15);
        height: rem(7);
        position: absolute;
        right: rem(12);
        bottom: rem(18);
    }
}

.seity-select-box-trigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    border: rem(1) solid $seity-middle-grey;
    border-radius: rem(8);
    background-color: white;
    cursor: pointer;
    padding: 0 rem(10) rem(5) 0;
    width: 100%;
    min-height: rem(48);
    div {
        position: relative;
        padding: rem(10) rem(20) rem(5) rem(20);
        font: rem(18) $font-source-sans;
        width: 91%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    &-error {
        border-color: $seity-orange;
    }
}

.seity-select-box-trigger-options {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    border: rem(2) solid $seity-teal;
    border-radius: rem(8);

    padding: 0 rem(10) 0 0;
    cursor: pointer;
    
    div {
        position: relative;
        padding: rem(10) rem(20) rem(5) rem(20);
        font: rem(18) $font-source-sans;
        width: 91%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    img {
        padding: rem(7) 0 0 0;
        position: absolute;
        right: rem(12);
        bottom: rem(18);
    }
}

.seity-select-box-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 100%;
    top: 100%;
    z-index: 1;
    background-color: white;
    border-radius: rem(8);
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);;
    overflow: hidden;

    ul {
        list-style: none;
        height: auto;

        max-height: rem(200);
        overflow-y: auto;
        li {
            display: flex;
            align-items: center;
            height: rem(48);
            padding: rem(5) rem(20) rem(5) rem(20);
            width: 100%;
            font: rem(18) $font-source-sans;
            &:hover, &:active, &:focus {
                background-color: $seity-teal;
                color: $seity-white;
                cursor: pointer;
            }
        }
    }
}
