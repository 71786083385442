@import '../../../../assets/sass/global';

.mcv-container {
  width: 100%;
  margin: rem(20) 0 rem(30) 0;
  font: rem(16) $font-source-serif;
  color: $seity-dark-green;
  
  @include respond-to(mobile) {
    width: 90% !important;
    align-self: center;
  }
  .backward-button {
    position: fixed;
    z-index: 9;
    left: rem(20);
    top: rem(450);
    padding: rem(10);
    border-radius: rem(20);
    @extend .intention-button;
    @include respond-to(mobile) {
      top: rem(360);
    }
  }
  .forward-button {
    position: fixed;
    z-index: 9;
    right: rem(20);
    top: rem(450);
    padding: rem(10);
    border-radius: rem(20);
    @extend .intention-button;
    @include respond-to(mobile) {
      top: rem(360);
    }
  }
  .mcv-carousel {
    .rec-carousel-item {
      height: 100%;
      .rec-item-wrapper {
        height: 100%;
      }
    }
  }
}
.mcv-main-wrapper {
  display: flex;
  flex-direction: column;
  margin: rem(20) 0 0 0;
  height: 100%;
  width: 100%;
  >h1 {
    font: 600 rem(42) $font-source-serif;
  }
  &-button {
    width: 100%;
    max-width: rem(284);
  }
}
.mcv-core-value-assess {
  display: flex;
  flex-direction: column;
  width: rem(544);
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  padding: rem(20) rem(40);
  background-color: $seity-gallery;
  border-radius: rem(10);
  @include respond-to(mobile) {
    padding: rem(6);
    border-bottom: $seity-grey;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    width: 90%;
    &-without-border {
      border: none;
    }
  }
  &-order {
    font-size: rem(36);
    color: rgba(49, 73, 71, 0.3);
    font-weight: 700;
    margin: rem(24) 0 rem(10) 0 !important;
  }
  &-img {
    width: rem(123) !important;
    height: rem(123) !important;
  }
  &-title {
    margin: rem(25) 0 rem(20) 0;
    font-size: rem(22);
    font-weight: bold;
    font-family: $font-source-serif;
    color: $seity-black;
  }
  &-description {
    width: 100%;
    margin: rem(30) 0 rem(15) 0;
    font-family: $font-source-sans;
    font-weight: bold;
    font-size: rem(18);
    color: $seity-black;
    text-align: center;
  }
  &-quote {
    font-style: normal;
    margin: 0 0 rem(10) 0;
    font-size: rem(16);
    color: $seity-black;
    font-family: $font-source-sans;
    font-style: italic;
    text-align: center;
    &-author {
      font-style: normal;
      margin-top: rem(30);
      margin-bottom: rem(15);
      font-size: rem(16);
      line-height: rem(18);
      font-family: $font-source-sans;
      text-align: center;
    }
  }
}