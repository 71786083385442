@import '../../../../assets/sass/global';

.msl-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  width: 100%;
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.msl-heading {
  margin: rem(40) 0 rem(20) 0;
  @include respond-to(mobile) {
    margin: rem(20) 0 rem(0) 0;
  }
  h1 {
    font: 600 rem(42) $font-source-serif;
    color: $seity-black;
  }
  h4 {
    font: rem(17) $font-source-sans;
  }
}

.msl-back-button {
  margin-top: rem(40);
  @include respond-to(mobile) {
    margin-top: rem(20);
  }
}

.msl-items-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: rem(558);
  &-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @include respond-to(mobile) {
    padding: 0 rem(10);
  }
  @include respond-to(mobile) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.msl-sync-item {
  width: 100%;
  margin: rem(15) 0 rem(15) 0;
  height: rem(146.5);
  padding-bottom: rem(15.5);
  border-bottom: rem(1) solid #e4e4e4;
  justify-content: space-between;

  h5 {
    align-self: center;
    width: min-content;
    padding: 0 rem(8) 0 rem(8);
    line-height: 1.54;
    letter-spacing: 1px;
    background-color: $seity-world;
    color: white;
    border-radius: rem(4);
    font: bold rem(14) $font-source-sans;
    text-transform: uppercase;
  }
  h2 {
    color: $seity-black;
    font: 700 rem(21) $font-source-serif;
    text-align: left;
    margin-top: rem(8);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  p {
    font: bold rem(13) $font-source-sans;
    color: $seity-dark-grey;
  }
  &-info {
    flex-direction: column;
    display: flex;
    height: rem(120);
    max-width: rem(245);
    bottom: rem(0);
    margin: 0;
    background-color: $seity-white;
    border-radius: 0 0 rem(16) rem(16);
    &:hover {
      cursor: pointer;
    }
    @include respond-to(mobile) {
      width: calc(100% - 140px);
    }
    @include respond-to(tablet) {
      min-width: rem(272);
    }
    @include respond-to(desktop) {
      min-width: rem(377);
    }
    h2 {
      margin: 0;
      font: 600 rem(24) $font-source-serif;
      color: $seity-black;
    }
    h3 {
      margin: 0;
      margin-top: rem(10);
      margin-bottom: rem(10);
      text-align: left;
      font: 600 rem(21) $font-source-serif;
      color: $seity-dark-grey;
    }
  }
  &-img {
    width: rem(175);
    height: rem(131);
    border-radius: rem(16);
    overflow: hidden;
    position: relative;
    @include respond-to(mobile) {
      width: rem(140);
      height: rem(104.8);
    }
    > span {
      position: absolute;
      top: rem(8);
      left: rem(8);
      border-radius: rem(4);
      padding: rem(0) rem(8);
      text-transform: uppercase;
      font: 700 rem(13) $font-source-sans;
    }
    > span.New {
      background-color: $seity-gold-yellow;
    }
    > span.Read {
      background-color: $seity-teal;
      color: white;
    }
    .check-icon {
      position: absolute;
      top: rem(6);
      right: rem(8);
      background-color: #34a77e;
      width: rem(24);
      height: rem(24);
      border-radius: rem(12);
      object-fit: none;
    }
    &__overlay {
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      display: flex;
      align-items: flex-end;
      transition: .5s ease;
      &:hover {
        opacity: 1;
        button {
          transition: .5s ease;
          bottom: 0;
        }
      }
      .check-icon {
        &:hover {
          cursor: pointer;
        }
        &.incompleted {
          background-color: transparent;
          border: white 1px solid;
          &:hover {
            background-color: #34a77e;
            border: none;
          }
        }
      }
      .btn-journal {
        position: absolute;
        width: 100%;
        height: rem(41);
        bottom: rem(-41);
        left: 0;
        background-color: $seity-teal;
        &:active {
          background-color: #3f6c6d;
        }
      };
    }
  }
}

.msl-sync-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.msl-arrow-item-reactions {
  display: flex;
  align-items: center;
  img {
    width: rem(20);
    height: rem(20);
    margin-right: rem(3);
  }
  span {
    font: 400 rem(21) $font-source-sans;
    color: $journal-gray;
  }
}

.journal-entry-modal-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: rem(8);
  display: flex;
  flex-direction: column;
  max-width: rem(476);
  min-height: rem(640);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  box-shadow: 0 20px 32px 0 rgba(96, 97, 112, 0.24), 0 2px 8px 0 rgba(40, 41, 61, 0.08);
}

.journal-entry-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 10;
}
