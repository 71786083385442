@import "../../../assets/sass/global";

.fsWelcome {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 19;
  background-color: $seity-white;
  padding: rem(76) rem(24) rem(48) rem(24);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include respond-to(tablet) {
    padding: rem(76) rem(72) rem(48) rem(72);
  }
  @include respond-to(desktop) {
    padding: rem(76) rem(208) rem(48) rem(208);
  }
  > h1 {
    margin-top: rem(16);
    font: 600 rem(26) $font-source-serif;
    color: black;
    text-align: left;
    @include respond-to(tablet) {
      font-size: rem(27);
    }
    @include respond-to(desktop) {
      font-size: rem(42);
    }
  }
  .close-button {
    position: absolute;
    top: rem(42);
    right: rem(72);
  }
  &-items {
    display: flex;
    flex-direction: column;
    margin-top: rem(48);
    gap: rem(40);
    > div {
      display: flex;
      > img {
        object-fit: contain;
        width: rem(40);
        @include respond-to(desktop) {
          object-fit: none;
          width: rem(56);
        }
      }
      > div {
        margin-left: rem(21);
        display: flex;
        flex-direction: column;
        > h3 {
          font: 600 rem(21) $font-source-sans;
          color: black;
          @include respond-to(desktop) {
            font-size: rem(26);
          }
        }
        > p {
          font: 400 rem(16) $font-source-sans;
          color: black;
        }
      }
    }
  }
  &-continue {
    width: 100%;
    margin-top: auto;
  }
}
