@import '../../../../../assets/sass/global';


.series-gallery-wrapper {
  width: 100%;
  .items-wrapper {
    display: grid;
    column-gap: rem(10);
    row-gap: rem(10);
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;

    @include respond-to(tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond-to(desktop) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}