@import '../../../assets/sass/global';

.get-help-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: rem(8);
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: rem(460);
  z-index: 20;
  padding: rem(32);
  width: rem(326);
  @include respond-to(tablet) {
    width: rem(463);
  }
  @include respond-to(desktop) {
    width: rem(504);
  }
  .close-button {
    position: absolute;
    top: rem(32);
    right: rem(32);
  }
}
.get-help-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(50, 50, 50, 0.65);
  z-index: 105;
}

.get-help-modal-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 510%;

  h1 {
    margin: rem(24) 0;
    @include font-style(
      rem(32),
      normal,
      $seity-black,
      left,
      1.25,
      $family: $font-serif-display
    );
    width: 100%;
  }
  h3 {
    @include font-style(
      rem(18),
      600,
      $seity-black,
      left,
      $family: $font-source-sans
    );
    width: 100%;
    margin-bottom: rem(24);
  }
  p {
    @include font-style(
      rem(18),
      normal,
      $seity-black,
      left,
      rem(21),
      $family: $font-source-sans
    );
    width: 100%;
  }

  .top-button, .down-button {
    height: rem(48);
    width: 100%;
  }
}

.get-help-img {
  bottom: 0;
  height: rem(219);
  @include respond-to(mobile) {
    height: rem(195);
  }
}

.someone-who-cares {
  @include font-style(
    rem(18),
    600,
    $seity-black,
    left,
    rem(24),
    $family: $font-source-sans
  );
  margin-top: rem(24);
}

.top-button {
  @include font-style(
    rem(21) !important,
    600!important,
    center,
    rem(28) !important,
    $family: $font-source-sans
  );
}

.down-button {
  @include font-style(
    rem(18) !important,
    bold !important,
    $seity-world !important,
    center,
    1.11 !important,
    $family: $font-source-sans
  );
  margin-top: rem(5);
  border-width: 0 !important;
  background-color: transparent !important;
}
