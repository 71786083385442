@import "../../../assets/sass/global";

.check-in-banner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 3rem;
  border-radius: 1.25rem;
  background: #2f696c;
  box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-bottom: 3.25rem;
  color: $seity-white;

  .content {
    h3 {
      font: 600 2.625rem $font-source-sans-pro-regular;
      margin-bottom: 1.5rem;
    }
    h5 {
      font: 400 1.25rem $font-source-sans;
      margin-bottom: 2rem;
      margin-right: 2rem;
    }
    .check-in-button {
      background-color: $seity-white;
      border-color: $seity-white;
      color: #2f696c;
      width: 27rem;
      @include respond-to(mobile) {
        width: 100%;
      }
    }
  }

  .content-2 {
    @extend .content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include respond-to(mobile) {
      flex-direction: column;
    }
  }

  .incentive {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
    align-items: center;
    gap: 1.5rem;
    border-radius: 1.25rem;
    background: rgba(102, 146, 149, 0.3);
    box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.1);

    p {
      font: 600 1.25rem $font-source-sans;
      color: $seity-white;
    }

    img {
      border-radius: 0.5rem;
      object-fit: cover;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
      width: 16.25rem;
      height: 10.5rem;
    }
  }
}
