@import '../../../assets/sass/global';

.mp-main-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  h1 {
    color: $seity-black;
    font: rem(42) $font-source-serif;
    line-height: rem(44);
    font-weight: 600;
    margin: clamp(5px, 2vh, 20px) 0 rem(30) 0;
  }
}

.mp-logoImage {
  width: rem(155);
  z-index: 1;
  box-shadow: 0 8px 16px 0 rgba(96, 97, 112, 0.16),
    0 2px 4px 0 rgba(40, 41, 61, 0.04);
  border-radius: rem(80);

  @include respond-to(small-mobile) {
    width: rem(108);
  }
}

.mp-back-button {
  top: $rem-desktop-header-height;
  margin-top: rem(16);
}

.mp-background-wrapper {
  width: 100vw;
  max-width: rem(770);
  border-radius: 0 rem(52) rem(52) rem(52);
  margin: rem(-80) 0 0 0;
  box-shadow: 0 8px 16px 0 rgba(96, 97, 112, 0.16),
    0 2px 4px 0 rgba(40, 41, 61, 0.04);
  background-color: $seity-timberwolf;

  @include respond-to(small-mobile) {
    margin: rem(-50) 0 0 0;
    width: 90vw;
  }

  @include respond-to(mobile) {
    width: 90vw;
  }
}

.mp-total-score {
  position: relative;
  left: rem(150);
  top: rem(5);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include respond-to(small-mobile) {
    left: 33%;
  }
  @include respond-to(mobile) {
    left: 33%;
  }

  h3 {
    color: $seity-world;
    font: rem(32) $font-source-serif;
    margin-top: rem(16);

    @include respond-to(small-mobile) {
      font-size: rem(26);
    }
  }
}

.mp-progress-bar-container {
  margin: rem(-5) 0 0 0;
  border: rem(1) solid $seity-world;
  width: rem(78);

  @include respond-to(small-mobile) {
    width: rem(72);
  }
}

.mp-foreground-wrapper {
  width: 100%;
  max-width: rem(770);

  margin: rem(30) 0 0 0;
  border-radius: 0 rem(52) 0 rem(52);
  padding: rem(20) 0 rem(20) 0;
  //

  @include respond-to(small-mobile) {
    margin: rem(20) 0 0 0;
  }
}

.mp-detail {
  display: flex;
  width: 100%;
  // max-width: rem(600);
  align-items: center;
  justify-content: space-evenly;
  height: clamp(65px, 8vh, 80px);
  cursor: pointer;

  @include respond-to(small-mobile) {
    height: rem(65);
  }

  h4 {
    width: 67%;
    font: rem(18) $font-source-serif;
    margin: 0 rem(5) 0 0;

    @include respond-to(mobile) {
      width: auto;
    }
    @include respond-to(mobile) {
      width: 40%;
    }
  }

  h5 {
    width: 5%;
    max-width: rem(300);
    font: rem(18) $font-source-sans;
    @include respond-to(small-mobile) {
      width: auto;
    }
  }

  &-icon {
    width: rem(70);
    height: rem(70);

    @include respond-to(small-mobile) {
      width: rem(60);
      height: rem(60);
    }
  }

}
