@import "../../assets/sass/global";

.wellbeing-only-container {
  width: 100%;

  @include respond-to(desktop) {
    max-width: rem(1158);
  }

  @include respond-to(large-desktop) {
    max-width: rem(1400);
  }

  .header-background {
    position: absolute;
    height: 18rem;
    width: auto;
    right: 0;
    display: none;
  }
  .header {
    margin-bottom: 6rem;
    > h3 {
      font: 600 2.625rem $font-source-sans-pro-regular;
      color: $seity-black;
    }
    > h5 {
      font: 600 1.625rem $font-source-sans;
      color: $seity-dark-grey;

      .more-info {
        height: 1rem;
        width: 1rem;
        cursor: pointer;
        margin-bottom: 2rem;
      }
    }
  }
  .footer {
    .checkin-incentive {
      display: flex;
      gap: 2rem;
      overflow-x: auto;
      margin-bottom: 3.5rem;
      border-radius: rem(30);
      box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.1);

      @include respond-to(mobile) {
        flex-direction: column;
      }

      .graphs {
        width: 100%;
      }
    }
  }
}
