.radioButtonItem {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.radioButtonItem input[type='radio'] {
  margin-right: 8px;
  margin-left: 0;
}

.radioLabel {
  font-size: 14px;
  color: #000;
}
