@import "../../../assets/sass/global";

.cvList-margin {
  margin: 0 rem(24);
  @include respond-to(tablet) {
    margin: 0 rem(72);
  }
  @include respond-to(desktop) {
    margin: 0 rem(208);
  }
}

.cvList-padding {
  padding: 0 rem(24);
  @include respond-to(tablet) {
    padding: 0 rem(72);
  }
  @include respond-to(desktop) {
    padding: 0 rem(208);
  }
}

.cvList {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .first {
    margin-bottom: rem(32);
  }

  .list-header {
    margin-top: rem(48);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: rem(4);

    .list-title {
      @extend .cvList-margin;
      margin-right: 0;
      font: 600 rem(26) $font-source-serif;
      color: black;
      text-align: left;
      @include respond-to(tablet) {
        font-size: rem(27);
      }
      @include respond-to(desktop) {
        font-size: rem(42);
        line-height: rem(53);
      }
    }

    .title-tooltip {
      align-self: flex-start;
      margin: 0;
      padding: 0;
      width: rem(18);
      height: rem(18);
      line-height: rem(18);
    }

    .more-info {
      cursor: pointer;
      width: rem(18);
      height: 18;
    }
  }

  .list-header-cvd {
    position: absolute;
    top: rem(23);
    right: rem(32);

    .title-tooltip-cvd {
      margin: 0;
      padding: 0;
      width: rem(24);
      height: rem(24);
      line-height: rem(24);
    }

    .more-info-cvd {
      cursor: pointer;
      width: rem(24);
      height: rem(24);
    }
  }

  &-list {
    overflow-y: auto;
    @extend .cvList-padding;
    > h5 {
      font: 400 rem(16) $font-source-sans;
      @extend .cvList-margin;
    }
    &__item {
      display: flex;
      align-items: center;
      border-bottom: rem(1) solid #f0f0f0;
      height: rem(80);
      cursor: pointer;

      &-img {
        width: rem(48);
        height: rem(48);
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        background-color: transparent; // Ensure no red background color
        border: none; // Remove the border if present
      }

      > span {
        font: 400 rem(16) $font-source-sans;
        margin-left: rem(8);
        margin-right: auto;
      }

      > div {
        display: flex;
        flex-direction: column;
        margin-left: rem(8);
        margin-right: auto;

        span {
          &:first-child {
            font: 600 rem(18) $font-source-sans;
            color: $seity-black;
          }
          &:last-child {
            font: 600 rem(14) $font-source-sans;
            color: $seity-dark-grey;
          }
        }
      }

      &-initials {
        margin: 0 !important;
      }
    }
  }

  &-add {
    position: fixed;
    bottom: rem(32);
    right: rem(32);
    background-color: $seity-teal-medium;
    width: rem(56);
    height: rem(56);
    border-radius: rem(44);

    > img {
      width: rem(25);
    }

    @include respond-to(desktop) {
      width: rem(88);
      height: rem(88);

      > img {
        width: rem(35);
      }
    }
  }

  &-noDesc {
    > h5 {
      font: 400 rem(18) $font-source-sans;
      color: $seity-dark-grey;
    }

    > button {
      font: 400 rem(18) $font-source-sans;
      color: $seity-teal-medium;
    }
  }
}

.newPLModal {
  width: rem(606);

  > h2 {
    text-align: center;
  }

  > button {
    margin-bottom: 0;
  }

  &-content {
    width: 100%;

    .seity-input-wrapper {
      margin-top: rem(24);
      width: 100%;
    }

    > button {
      margin-top: rem(24);
      height: rem(48);
      width: 100%;
      border-radius: rem(56);
    }
  }
}

.toolTipModal {
  min-height: unset;
  max-width: rem(601);

  .toolTipModal-header {
    color: var(--Black-0, $seity-black);
    text-align: center;
    font-family: $font-source-serif;
    font-size: rem(24);
    font-style: normal;
    font-weight: 600;
    line-height: rem(28);
    margin-bottom: rem(24);
  }

  .toolTipModal-definition {
    color: var(--Black-0, $seity-black);
    font-feature-settings: "clig" off, "liga" off;
    font-family: $font-source-sans;
    font-size: rem(18);
    font-weight: 400;
    line-height: rem(24);
    margin-bottom: rem(24);
  }

  .tooltip-modal-button {
    width: 100%;
    max-height: rem(48);
  }
}

.char-limit-reached {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;

  div {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      height: 20px;
      width: 20px;
    }

    p {
      font-weight: 600;
      color: #f75a3b;
      font-size: 16px;
      font-family: $font-source-sans;
    }
  }

  p {
    font-weight: 400;
    color: #f75a3b;
    font-size: 16px;
    font-family: $font-source-sans;
  }
}

.error-outline {
  border: solid #f75a3b !important;
}
