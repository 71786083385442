@import "../../../../assets/sass/global";

.plotline-header-body {
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 2rem;

  .tag-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 1.5rem;
    width: 4.5rem;
    height: 2rem;
    background: $seity-gallery;
    border-radius: 1.25rem;
    margin-bottom: 0.25rem;

    img {
      height: auto;
      width: auto;
    }
  }

  .icon-medium {
    width: auto;
    height: 2.125rem;
    margin-right: 1rem;
  }

  .icon-large {
    width: auto;
    height: 6.5rem;
    margin: 2.125rem auto;
  }

  // change styles based on template id
  .copy-text-container-8,
  .icon-heading-8,
  .icon-heading-9 {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }
  .icon-heading-5 {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .copy-text-container-9 {
    display: flex;
    flex-direction: column;
    .text-item {
      position: absolute;
      width: 100%;
      bottom: rem(150);
      left: 0;
    }
  }

  .icon-heading-7,
  .icon-heading-6,
  .icon-heading-10 {
    display: flex;
    align-items: center;
  }

  .subtitle {
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: rem(12);
    line-height: rem(16);
    color: $seity-teal-medium;
    margin: 0.5rem 0 0 !important;
    @include respond-to(desktop) {
      font-size: rem(14);
    }
  }

  .subtitle-8,
  .subtitle-9 {
    font-size: 1.5rem;
    line-height: 2.625rem;
  }

  // full page templates
  .copy-text-container-4,
  .copy-text-container-5,
  .copy-text-container-8,
  .copy-text-container-9 {
    p {
      color: white;
      text-align: center;
    }
    .text-item {
      color: white;
      text-align: center;
    }
  }

  .title {
    font-family: "Source Serif Pro";
    font-weight: 600;
    font-size: rem(27);
    line-height: rem(36);
    color: #000000;
    @include respond-to(desktop) {
      font-size: rem(28);
      line-height: rem(35);
    }
  }

  .text-item, .title2 {
    font-family: "Source Sans Pro";
    font-weight: 400;
    font-size: rem(16);
    line-height: rem(20);
    color: $seity-dark-grey;
    @include respond-to(desktop) {
      font-size: rem(16);
      line-height: rem(20);
    }
    h5 {
      font: 600 rem(21) $font-source-sans;
      color: black !important;
      margin: rem(24) 0;
    }
  }
}

// change styles based on template id
.plotline-header-mt-6,
.plotline-header-mt-10 {
  margin-top: 4.625rem;
}

.plotline-header-mt-8,
.plotline-header-mt-9 {
  margin-top: 1.625rem;
}
