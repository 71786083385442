@import '../../../../assets/sass/global';
   
   .intro-container {
        display: flex;
        
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;   
        position: static;          
    }

    .assess-section-intro-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: rem(568);
        width: 100%;
        h1 {
            color: $seity-black;
            font: rem(42) $font-source-serif;
            font-weight: bold !important;
            line-height: rem(44);
            margin-top: rem(16);
            margin-bottom: rem(26);
        }
        @include respond-to(mobile) {
            height: 82%;
            margin-bottom: 0 !important;
            justify-content: space-evenly;
        } 
    }
       
    .assess-section-intro-text {
        max-width: rem(360);
        width: 100%;
        text-align: center;
        margin-bottom: rem(40) !important;
    }

    .section-intro-text {
        width: rem(260);
        text-align: center;
        margin-top: rem(0) !important;
        margin-bottom: rem(10) !important;
        font: rem(17) $font-source-sans;
        color: $seity-dark-green;
    }

    .assess-intro-img {
        margin-bottom: rem(17) !important;
        width: 100%;
    }

    .number-img {
        width: 45%;
        max-width: rem(300);
        margin-bottom: rem(20) !important;
        @include respond-to(mobile) {
            // width: 30%;
            max-width:  rem(200);
            margin-bottom: rem(10) !important;
        } 
    }

