@import "../../../../assets/sass/global";

.plotline-video-body {
  width: 100%;
  height: 100%;
  video {
    object-fit: cover;
    display: block;
  }
}
