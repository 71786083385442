@import '../../../assets/sass/global';

.finish-checkin-later-wrapper {
  top: 40%;
  background-color: #ffffff;
  border-radius: rem(8);
  padding: rem(32) rem(32) rem(19) rem(32);
}

.finish-checkin-later-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.finish-checkin-later {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: rem(500);
  overflow: hidden;
  align-items: center;

  &-title {
    white-space: pre-wrap;
    @include font-style(
      rem(21),
      normal,
      $seity-black,
      center,
      1.33,
      $family: $font-source-sans
    );
  }
  &-button {
    &-stay {
      padding: rem(10) rem(110) !important ;
    }
    &-leave {
      color: #2f696c !important;
      background-color: transparent !important;
      border-width: 0 !important;
      margin-top: rem(12);
    }
  }
  &-container {
    display: flex;
    flex-direction: column;
    margin-top: rem(24);
  }
}
