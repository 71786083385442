@import "../../../../assets/sass/global";

.changePwd {
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: rem(40) 0 rem(40) 0;
    width: 100%;
    color: $seity-dark-green;

    @include respond-to(mobile) {
      margin: rem(20) 0 rem(20) 0;
    }
    form {
      width: rem(300);
    }
    h1 {
      font: rem(40) $font-source-serif;
      margin: 0 0 rem(40) 0;

      @include respond-to(small-mobile) {
        font: rem(35) $font-source-serif;
        margin: 0 0 rem(20) 0;
      }
    }
    h4 {
      font: rem(16) $font-source-sans;
      margin: 0 0 rem(40) 0;

      @include respond-to(small-mobile) {
        margin: 0 0 rem(20) 0;
      }
    }
  }
  &-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    h2 {
      font: rem(18) $font-source-sans;
      color: $seity-black;
      width: rem(225);
      margin: 0 0 rem(20) 0;
    }
  }
}
