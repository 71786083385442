.survey-info-modal-container {
  padding: 20px;
}

.survey-info-modal-close-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;

  .close-icon {
    width: 14px;
    height: 14px;
    filter: invert(0);
  }
}

.survey-info-modal-content {
  margin-top: 40px; // To give space for the close button
}

.survey-info-modal-header {
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  color: #202223;
  margin-bottom: 10px;
  font-family: 'Source Serif Pro', serif;
}

.survey-info-modal-header-2 {
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  color: #202223;
  margin-bottom: 10px;
  margin-top: 20px;
  font-family: 'Source Serif Pro', serif;
}

.survey-info-modal-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #202223;
  margin-bottom: 20px;
  font-family: 'Source Sans Pro', sans-serif;
}

.survey-info-modal-footer {
  padding-bottom: 20px;
  margin-top: 20px;

  button {
    width: 100%;
  }
}
