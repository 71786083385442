$seity-white: #fbf9f7;
$seity-green: #41625e;
$seity-dark-green: #314947;
$seity-cream: #fff8eb;

$seity-transparent: #ffffff00;
$seity-pink: #f08080;
$seity-orange: #f75a3b;
$seity-warning: #da6767;
$seity-red-orange: #DE5135;
$seity-world: #2f696c;
$seity-teal: #56a3a6;
$seity-teal-medium: #539fa3;
$seity-mind: #9ac8ca;
$seity-spirit: #bbdadb;
$seity-blue: #67acaf;
$seity-dark-grey: #778595;
$seity-grey: #c1c4ba;
$seity-light-grey: rgba(190, 191, 186, 0.5);
$seity-middle-grey: #c1c7cd;
$seity-dark-grey: #677280;
$seity-gold-yellow: #fcbd31;
$seity-yellow: #fdcb5d;
$seity-light-yellow: #ffd67b;
$seity-black: #343b43;
$seity-onyx: #353439;
$seity-gray: #cdc7c1;
$seity-timberwolf: #dcd8d6;
$seity-gainsboro: #d9dfd9;
$seity-grey-gainsboro: #b0b0b0;
$seity-middle-teal: #539fa3;
$seity-slate-grey: #4f7e81;

$raging-dragon: #5b6f6c;
$awake-dragon: #485b59;
$tame-dragon: #535f5d;
$dragon-dark-green: #2e4947;
$dragon-light-green: #697a75;
$seity-loblolly: #c1c7cd;
$seity-gallery: #f0f0f0;

$journal-gray: #677280;
$journal-bg: #fbf9f7;
