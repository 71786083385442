@import "../../../assets/sass/global";

.intention-selected {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .mobile-header {
    display: flex;
    justify-content: space-between;
    padding: rem(15) rem(20);
    button {
      &:first-child {
        display: block;
        @include respond-to(mobile) {
          display: none;
        }
      }
      &:last-child {
        margin-left: auto;
        display: none;
        @include respond-to(mobile) {
          display: block;
        }
      }
    }
  }
  .header-back-button {
    position: absolute;
    left: rem(10);
    @include respond-to(tablet) {
      left: rem(72);
    }
    @include respond-to(desktop) {
      left: rem(120);
    }
    img {
      object-fit: contain;
    }
  }
  &__card {
    display: inline-block;
    width: rem(326);
    @include respond-to(tablet) {
      width: rem(326);
    }
    @include respond-to(desktop) {
      width: rem(372);
    }
    background-color: $seity-teal-medium;
    padding: rem(16);
    border-radius: rem(16);
    border: rem(2) solid $seity-teal-medium;
    .listitem-middle {
      overflow: hidden;
      height: rem(200);
      position: relative;
      border-radius: rem(16);
      .background-img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        background-color: rgb(40, 39, 39);
      }
      .listitem-overlay {
        z-index: 9;
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        transition: .5s ease;
        &:hover {
          background: rgba(0, 0, 0, 0.6);
          cursor: pointer;
        }
        p {
          z-index: 7;
          text-align: center;
          @include font-base($font-source-serif, normal, 600);
          font-size: rem(18);
          color: white;
          white-space: pre-line;
        }
      }
    }
    .listitem-top {
      position: relative;
      span {
        color: white;
        @include font-base($font-source-sans, normal, 600);
        font-size: rem(18);
        text-transform: uppercase;
        letter-spacing: rem(1.5);
      }
      text-align: center;
      .check-icon {
        right: 0;
        position: absolute;
        background-color: #2f696c;
        width: rem(32);
        height: rem(32);
        border-radius: rem(16);
        object-fit: none;
      }
    }
    .listitem-bottom {
      span {
        margin-right: auto;
        @include font-base($font-source-sans, normal, 400);
        font-size: rem(18);
      }
    }
  }
  > p {
    @media screen and (max-height: rem(700)) {
      font-size: rem(18);
    }
    @include font-base($font-source-sans, normal, 400);
    font-size: rem(28);
    color: $seity-black;
    text-align: center;
  }
  .btn-idk {
    @include font-base($font-source-sans, normal, 600);
    font-size: rem(18);
    color: #4f7e81;
  }
  .intention-button {
    width: rem(310);
  }
}
