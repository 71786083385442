/* styles.scss */

@import '../../../../assets/sass/global';

.thermometerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}

.topLabel,
.bottomLabel {
  font-size: 10px;
  color: #677280;
  margin: 2px 0;
}

.thermometerWrapper {
  position: relative;
  width: 20px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thermometer {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #E4E4E4;
  position: relative;
}

.thermometerTop {
  position: absolute;
  bottom: 69%;
  width: 100%;
  height: 31%;
  background-color: #71AE3C;
}

.thermometerMiddle {
  position: absolute;
  bottom: 49%;
  width: 100%;
  height: 20%;
  background-color: #D9AE2E;
}

.thermometerBottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 49%;
  background-color: #D92E2E;
}

.circle {
  position: absolute;
  left: -5px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid $seity-white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circleText {
  color: $seity-white;
  font-weight: bold;
  font-size: 12px;
}
