@import '../../../../assets/sass/global';

.privacy-modal {
  border-radius: rem(8) !important;
  background-color: white;
  max-width: rem(568);
  max-height: rem(528);
  width: 80%;
  height: 100%;

  h3 {
    font-family: $font-source-sans;
    font-size: rem(28);
    font-weight: 600;
  }

  p {
    color: $seity-black;
    font-weight: 500;
    font-style: bold;
    font-family: $font-source-sans;
    font-size: rem(20);
    text-align: center;
  }

  img {
    margin-top: rem(30);
    margin-bottom: rem(30);
  }

  &-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: rem(90);
    margin-top: rem(10);
  }
  &-button {
    width: 100%;
    max-width: rem(284);
  }
  &-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }
}
