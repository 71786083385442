@import "../../../assets/sass/global";

ul {
  margin: 0 !important;
  padding: 0 !important;
}
.seity-header {
  width: 100%;
  background-color: white;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  z-index: 10;
  @include header-height;
  &-transparent {
    background-color: $seity-transparent;
  }
  @include respond-to(desktop) {
    padding: 0 4rem;
  }
}
.seity-caret {
  display: none;
  @include respond-to(desktop) {
    display: block;
    height: rem(32);
  }
  @include respond-to(tablet) {
    display: block;
    height: rem(32);
  }
}

.seity-header__bg {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
}

.seity-header__main {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @include respond-to(mobile) {
    padding: 0 rem(20);
  }
}

.seity-header__main__left {
  @include respond-to(desktop) {
    display: none;
    min-width: rem(100);
  }
  left: rem(10);
  display: flex;
  align-items: center;
  margin-right: auto;
  @include respond-to(mobile) {
    width: auto !important;
  }
  img {
    margin: rem(10) rem(20);
    cursor: pointer;
    @include respond-to(mobile) {
      margin: 0;
    }
  }
  a {
    @include header-menu-back;
    text-decoration: none !important;
    padding: rem(8);
    &:hover {
      color: $seity-cream;
      background-color: $seity-dark-green;
      border-radius: rem(8);
      border-bottom-left-radius: 0;
    }
    &.disabled {
      user-select: none;
      pointer-events: none;
    }
  }
}

.seity-header__main__center {
  justify-content: center;
  text-align: center;

  @include respond-to(mobile) {
    width: rem(100) !important;
  }

  ul {
    list-style-type: none;
    display: flex;
    @include respond-to(mobile) {
      display: none;
    }
    li {
      display: inline;
      min-width: rem(120);

      a {
        color: $seity-green !important;
        padding: rem(10) rem(20);
        font: rem(16) $font-source-sans !important;
        font-weight: 600 !important;
        text-decoration: none;
        @include header-menu-item;
        &:hover {
          color: $seity-blue !important;
          font-weight: 900 !important;
        }
        &.disabled {
          user-select: none;
          pointer-events: none;
          opacity: 0.7;
        }
      }
    }
  }
}

.seity-header__main__right {
  display: flex;
  justify-content: center;
  margin-left: auto;
  width: fit-content;
  gap: 1.75rem;

  @include respond-to(mobile) {
    justify-content: flex-end;
  }
  .get-help-button {
    border-color: #2d4340;
    color: #2d4340;
    height: 3rem;

    @include respond-to(mobile) {
      padding: 0 1rem;
      font-size: 1rem;

      img {
        height: 1rem;
        object-fit: contain;
      }
    }
  }
  a {
    display: flex;
    align-items: center;
    z-index: 30;
    &:hover {
      text-decoration: none;
    }
    div {
      width: rem(12);
      height: rem(12);
      background: $seity-pink;
      color: $seity-cream;
      font-size: rem(10);
      font-family: $font-source-sans;
      font-weight: 600;
      border-radius: rem(6);
      margin-left: rem(-10);
      margin-top: rem(-15);
    }
    p {
      font: rem(18) $font-source-sans-bold;
      color: $seity-world;
      @include respond-to(mobile) {
        width: 100% !important;
        margin-left: rem(20);
      }
    }
  }
}

.seity-header__nav-button {
  span {
    font: rem(17) $font-source-sans-bold;
    text-decoration: underline;
  }
}

.seity-header__title {
  position: absolute;
  left: 47.5%;
  top: 35%;
  font: rem(20) $font-source-sans-bold;
  color: $seity-cream;
  letter-spacing: rem(2);
}

.seity-burger {
  display: none;
  width: rem(25);

  @include respond-to(mobile) {
    display: block;
  }
}

.invisible {
  display: none !important;
}

.invisible-burger {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate3d(-100vw, 0, 0);
  width: 100vw;
  height: 100vh;
}

.visible-burger {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: #4f7e81;
  overflow-x: hidden;
  transition: transform 0.8s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.burger-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: rem(35);
  padding: 0 rem(30);
  margin-bottom: rem(60);
}

.burger-close {
  width: rem(40);
  height: rem(40);
}

.small-seity-letters {
  width: rem(72);
  height: rem(17);
}

.top-image {
  opacity: 100%;
}

.bottom-image {
  animation: fadeInAnimation 1s linear;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

.burger-menu-item-container {
  margin-left: rem(40);
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: rem(20);

  ul {
    width: fit-content;
    height: 100%;
    list-style: none;
    li {
      width: fit-content;
      margin-bottom: rem(64);
      position: relative;
    }
    a {
      width: fit-content;
      color: $seity-white;
      font: normal rem(42) $font-source-sans;
      text-transform: uppercase;
      text-decoration: none;
      &.disabled {
        user-select: none;
        pointer-events: none;
        opacity: 0.7;
      }
    }
    img {
      height: rem(32);
      margin-left: rem(8);
    }
    .mobile-horizont-line {
      position: absolute;
      bottom: 0;
    }
  }
}

.highlighted-menu-item {
  font-family: $font-source-sans-bold !important;
  font-weight: 900 !important;
  width: fit-content;
}

.highlighted-menu-item::after {
  content: "";
  align-self: center;
  border-bottom: 8px solid #fff;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  width: 100%;
  display: block;
}

.center-menu-items {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  &__img-logo {
    cursor: pointer;
    width: rem(72);
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    margin-bottom: rem(4);
    &-highlited {
      color: $seity-blue !important;
      font-weight: 900 !important;
    }
  }
}

.vert-line {
  margin-right: rem(30);
  margin-left: rem(40);
  height: rem(42);
  @include respond-to(mobile) {
    display: none !important;
  }
}

.horizont-line {
  width: rem(70);
  margin-top: rem(10);
  position: absolute;
  bottom: rem(16);
}

.submenu-parent {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
  height: 100%;
}

.grow-submenu {
  background-color: #fff;
  border-radius: 1rem;
  width: 24rem;
  box-shadow: 0px 20px 32px 0px rgba(96, 97, 112, 0.24), 0px 2px 8px 0px rgba(40, 41, 61, 0.08);
  position: absolute;

  @include respond-to(mobile) {
    background-color: unset;
    box-shadow: unset;
    width: auto;
    position: relative;
  }

  .submenu-item {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;

    @include respond-to(mobile) {
      padding: 1rem;
    }

    div {
      display: flex;
      align-items: center;
      gap: 0.63rem;
      text-decoration: none;

      img {
        height: 1.5rem;

        @include respond-to(mobile) {
          display: none;
        }
      }

      p {
        color: #343b43;
        font-family: $font-source-sans;
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;

        @include respond-to(mobile) {
          color: #fff;
          font-size: 1rem;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
  }

  .submenu-item:hover {
    background-color: rgba(187, 218, 219, 0.2);
  }
}
