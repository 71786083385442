@import '../../../../assets/sass/global';

/* Styles for the MySurveysTab component */

.no-results-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

.no-results-text {
  font-size: 16px;
  line-height: 20px;
  font-family: $font-source-sans;
  font-weight: 400;
  color: #808080;
  text-align: left;
}

.category-details {
  padding: 10px 0;
}
