@import "../../../assets/sass/global";

.journal-entry-modal {
  height: calc(100% - 211px - 32px);
  @include respond-to(mobile) {
    flex-direction: column;
    margin: 0;
  }
  > div > h3 {
    @include font-base($font-source-serif, normal, 600);
    font-size: rem(28);
    color: $seity-teal-medium;
  }
  &__individual {
    > img {
      object-fit: contain;
    }
    span {
      @include font-base($font-source-serif, normal, 600);
      color: $journal-gray;
    }
  }
  &__toolbar {
    border-bottom: solid rem(1) #c1c7cd;
    p {
      @include font-base($font-source-sans, normal, 300);
      font-size: rem(23);
      color: $seity-black;
      span {
        font-size: rem(20);
        color: #677280;
      }
    }

    [type="file"] {
      height: 0;
      overflow: hidden;
      width: 0;
    }
    
    [type="file"] + label {
      width: rem(80);
      height: rem(48);
      background-repeat: no-repeat;
      background-image: url("../../../assets/graphics/journals/save.png");
      background-position: center;
      border-radius: rem(16) !important;
      border: 1px solid #c1c7cd !important;
      background-color: transparent;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      transition: all 0.3s;
      vertical-align: middle;
      margin: 0;
      &:hover {
        background-color: darken($seity-grey, 10%);
      }
    }
  }
  &__center {
    span {
      color: $seity-black;
      @include font-base($font-source-sans, normal, 400);
      font-size: rem(20);
    }
  }
  &__attach {
    height: rem(100);
    object-fit: contain;
  }
  &__saveBtn {
    border-radius: rem(16) !important;
    border: 1px solid #c1c7cd !important;
    padding: rem(7) rem(23);
    background-color: transparent;
  }
  &__favBtn {
    border: none;
    background-color: transparent;
  }
  &__outcomesBtn, &__feelBtn {
    width: 50%;
    span {
      color: $seity-world;
      @include font-base($font-source-sans, normal, 600);
      font-size: rem(20);
    }
  }
  &__outcomesBtn {
    border-right: 1px solid #c1c7cd !important;
  }
  &__sep {
    position: absolute;
    top: rem(300);
    left: 0;
    height: rem(1);
    width: 100%;
    background-color: #c1c7cd;
  }
  &__footer {
    width: 100%;
    height: rem(211);
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #c1c7cd;
    p {
      @include font-base($font-source-sans, normal, 400);
      font-size: rem(18);
      color: $seity-black;
    }
    button {
      margin: 0 rem(5);
    }
    .btn-idk {
      @include font-base($font-source-sans, normal, 600);
      font-size: rem(18);
      color: #4f7e81;
    }
  }
  .btn-delete {
    img {
      filter: invert(31%) sepia(75%) saturate(5000%) hue-rotate(342deg) brightness(95%) contrast(97%);
    }
    &:hover {
      background-color: $seity-grey;
      transition-duration: 0.5s;
    }
  }
  .body-text-wrap {
    // margin-bottom: rem(300);
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: rem(55);
    padding-bottom: rem(20);
    .body-text {
      resize: none;
      flex: 1;
    }
  }

  div > &__titleInput {
    width: 100%;
    max-height: rem(150);
    min-height: rem(38);
    resize: none;
    box-sizing: border-box;

    border: none;
    outline: none;
    background-color: transparent;
    @include font-base($font-source-serif, normal, 600);
    font-size: rem(42);
    color: $journal-gray;
    margin-right: rem(21);
    border-bottom: rem(1) solid $journal-gray;
    &.max-limit {
      border-bottom: rem(1) solid $seity-orange;
    }
  }
  div > span {
    text-align: right;
    color: $journal-gray;
    @include font-base($font-source-sans, normal, 400);
    font-size: rem(16);
    &.max-limit {
      color: $seity-orange;
    }
  }

  .emotionsPopup-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .journal-checkin-container {
    position: absolute;
    width: rem(460);
    border-radius: 8px;
    box-shadow: 0 20px 32px 0 rgba(96, 97, 112, 0.24), 0 2px 8px 0 rgba(40, 41, 61, 0.08);
    background-color: #fff;
    overflow-y: scroll;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &::-webkit-scrollbar {
      display: none;
      scrollbar-width: none;
      -ms-overflow-style: none;
    }
    scrollbar-width: none;
  }
}

