.toggle-switch {
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-switch.on {
  background-color: #008080;
}

.toggle-switch.off {
  background-color: #ccc;
}

.toggle-switch.disabled {
  opacity: 0.5; /* Visually indicate the toggle is disabled */
  cursor: not-allowed; /* Change the cursor to indicate it's not clickable */
}

.toggle-handle {
  width: 23px;
  height: 23px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: left 0.3s;
}

.toggle-switch.on .toggle-handle {
  left: 26px;
}
