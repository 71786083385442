.unlock-wholehealth-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.unlock-wholehealth-modal {
  position: relative;
  background-color: #fff;
  width: 90%;
  max-width: 400px;
  border-radius: 12px;
  padding: 20px;
  box-sizing: border-box;
  outline: none;
}

.closeButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1;

  .closeIcon {
    width: 14px;
    height: 14px;
  }
}

.unlock-wholehealth-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px; // to give space for the close button
}

.title {
  font-size: 24px;
  color: #202223;
  font-weight: 600;
  font-family: 'Source Serif Pro', serif;
  margin-bottom: 10px;
  text-align: center;
}

.subTitleWrapper {
  padding: 0 10px;
  width: 100%;

  .subTitle {
    margin-top: 10px;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    color: #202223;
    font-family: 'Source Sans Pro', sans-serif;
  }
}

.buttonWrapper {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .buttonContainer {
    margin-bottom: 15px;
    width: 100%;
    max-width: 300px;
  }
}
