@import "../../../../assets/sass/global";

.register-four {
  &-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    color: $seity-dark-green;
    padding: rem(10) 0 rem(10) 0;
    h1 {
      font: rem(42) $font-source-serif !important;
      color: $seity-black !important;
      font-weight: 600 !important;
      margin: 0 0 rem(5) 0;
      @include respond-to(mobile) {
        font: rem(35) $font-source-serif;
      }
    }
    h5 {
      font: rem(18) $font-source-sans;
      width: 100% !important;
      max-width: rem(700) !important;
      margin: rem(30) 0 rem(40) 0;
      @include respond-to(mobile) {
        font: rem(16) $font-source-serif;
        margin: rem(20) 0 rem(10) 0;
      }
    }
    form {
      margin-top: rem(40);
      text-align: left;
      width: 95%;
      display: inline-block;
    }

    .country-code-phone-number {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 1rem;
      position: relative;
      margin-top: 3.25rem;

      .seity-select-box-form-group {
        width: 5.25rem;
        label {
          position: absolute;
          top: -1.25rem;
        }
        .seity-select-box-trigger {
          padding-bottom: 0;
          div {
            padding: 0.6rem;
            text-align: center;
          }
          img {
          }
        }
        .seity-select-box-options {
          width: 19rem;
        }
      }

      .seity-input-with-label {
        label {
          display: none;
        }
      }
    }

    &-bottom {
      width: 100%;
      margin-bottom: rem(50);
    }
    &-error {
      text-align: center;
      color: $seity-orange;
      height: 2.5em;
      line-height: rem(24);
      font-size: rem(18);
      margin-top: rem(32);
      margin-bottom: rem(32);
      max-width: rem(700);
    }
  }
  &-next-button {
    width: 100%;
    max-width: rem(284);
    margin-left: auto;
    margin-right: auto;
  }
}

.textbox-form {
  max-width: rem(300);
}
