@import '../../../../../assets/sass/global';


.sciences-gallery-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: rem(56);
  grid-column-gap: rem(36);
  @include respond-to(tablet) {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: rem(36);
  }
  @include respond-to(desktop) {
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: rem(48);
  }
  @include respond-to(large-desktop) {
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: rem(48);
  }
  .ts-asset-item {
    margin: 0 !important;
  }
}