/*
HEADING LEVELS
*/
/*
BASE VALUES MIXIN
NOTE: by default, type elements should not have top margins
*/

@mixin font-style(
  $size: null,
  $bold: null,
  $color: null,
  $align: null,
  $lineHeight: null,
  $transform: null,
  $spacing: null,
  $verticalAlign: null,
  $family: null,
  $decoration: null
) {
  @if $size {
    font-size: $size;
  }
  @if $bold {
    font-weight: $bold;
  }
  @if $color {
    color: $color;
  }
  @if $align {
    text-align: $align;
  }
  @if $lineHeight {
    line-height: $lineHeight;
  }
  @if $transform {
    text-transform: $transform;
  }
  @if $spacing {
    letter-spacing: $spacing;
  }
  @if $verticalAlign {
    vertical-align: $verticalAlign;
  }
  @if $family {
    font-family: $family;
  }
  @if $decoration {
    text-decoration: $decoration;
  }
}

@mixin font-base($font-family, $font-style, $font-weight) {
  font-family: $font-family;
  font-style: $font-style;
  font-weight: $font-weight;
  margin-top: 0;
}

@mixin header-menu-item() {
  font-family: 'Source Serif Pro';
  font-weight: bold;
  font-size: rem(18);
  color: $seity-cream;
}

@mixin header-menu-back() {
  @include font-base('Source Sans Pro', bold, 400);
  color: $seity-cream;
  font-size: rem(20);
}

// NOTE: Use for all <h1> elements
// Uses the following Figma styles per breakpoint -
// Mobile: Header / H3;
// Tablet: Header / H2;
// Desktop: Header / H1;
@mixin heading-h1($color: $white, $textAlign: left) {
  // @include font-base($font-family-barlow-bold, bold, 500);
}

// NOTE: Use for all <h2> elements
// Uses the following Figma styles per breakpoint -
// Mobile: Header / H3;
// Tablet: Header / H2;
// Desktop: Header / H1;
@mixin heading-h2($color: $white, $textAlign: left) {
}

// NOTE: Use for all <h3> elements
// Uses the following Figma styles per breakpoint -
// Mobile: Header / H3;
// Tablet: Header / H2;
// Desktop: Header / H1;
@mixin heading-h3($color: $white, $textAlign: left) {
}

// NOTE: Use for all <h3> elements
// Uses the following Figma styles per breakpoint -
// Mobile: Header / H3;
// Tablet: Header / H2;
// Desktop: Header / H1;
@mixin heading-h4($color: $white, $textAlign: left) {
}

@mixin text-body($color: $gray-dark, $textAlign: left) {
}
