@import '../../../../assets/sass/global';

.survey-progressbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  &__backbar {
    height: rem(4);
    width: 90%;
    background-color: $seity-grey;
    border-radius: rem(2);
  }
  .survey-progressbar__progression {
    height: 100%;
    background-color: $seity-dark-green;
    border-radius: rem(2);
    transition-duration: 0.4s;
  }
  span {
    display: inline-block;
    margin-left: rem(10);
    font-size: 1rem;
    @include font-base($font-source-sans, normal, 400);
    min-width: rem(30);
  }
}
