@import "../../../assets/sass/global";

.fsProfileCard {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: rem(16);
  padding-bottom: rem(16);
  .inviteStatus {
    font: 600 rem(10) $font-source-sans;
    @include respond-to(desktop) {
      font-size: rem(14);
    }
    &.InvitePending {
      color: #2F696C;
    }
    &.InviteDeclined {
      color: #F75A3B;
    }
  }

  .user-details {
    margin-left: rem(8);
    .user-name {
      font: 600 rem(15) $font-source-sans;
      @include respond-to(desktop) {
        font-size: rem(18);
      }
      line-height: rem(20);
      color: $seity-black;
    }

    .user-cv-images {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    .user-cv-names {
      font: 600 rem(10) $font-source-sans;
      line-height: 1rem;
      color: $seity-dark-grey;
      @include respond-to(desktop) {
        font-size: rem(12);
      }
    }
  }
  .avatar-container {
    width: rem(48) !important;
    height: rem(48) !important;
    @include respond-to(desktop) {
      width: rem(64) !important;
      height: rem(64) !important;
    }
  }
}
