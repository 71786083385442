.core-values-confirmation-view {
  .core-values-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }

  .core-values-header {
    position: relative;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .core-values-back-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

    img {
      width: 16px;
      height: 15px;
      filter: invert(0);
    }
  }

  .core-values-content {
    margin-top: 20px;
    text-align: center;
  }

  .core-values-title {
    font-size: 27px;
    line-height: 36px;
    color: #202223;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .core-values-subheading {
    font-size: 18px;
    color: #202223;
    margin-bottom: 20px;
  }

  .four-core-values-container {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .core-value-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .core-value-card {
    background-color: #DCD8D6;
    width: 48%;
    height: 224px;
    border-radius: 40px 40px 40px 0;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-align: center;
    box-shadow: 0px 2px 3.84px rgba(0, 0, 0, 0.25);
  }

  .core-value-image {
    height: 96px;
    width: 96px;
    margin-bottom: 10px;
  }

  .core-value-text {
    font-size: 16px;
    font-weight: 600;
    color: #202223;
    font-family: "Source Sans Pro", sans-serif;
  }

  .core-values-last-taken {
    font-size: 16px;
    line-height: 24px;
    color: #767676;
    margin-top: 20px;
  }

  .core-values-confirm-text {
    font-size: 16px;
    color: #202223;
    margin-top: 20px;
  }

  .defer-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .buttons-container {
    margin-top: 40px;
    padding: 0 20px;

    button {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      display: block;
      margin-bottom: 20px;
    }
  }
}
