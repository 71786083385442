@import '../../../../../assets/sass/global';


.guides-gallery-wrapper {
  width: 100%;
  display: flex;
  max-width: 100%;

  @include respond-to(tablet) {
    max-width: rem(744);
  }
  @include respond-to(desktop) {
    max-width: rem(1120);
  }
}