@import "../../../assets/sass/global";

.fsGroups-margin {
  margin: 0 rem(24);
  @include respond-to(tablet) {
    margin: 0 rem(72);
  }
  @include respond-to(desktop) {
    margin: 0 rem(208);
  }
}

.fsGroups-padding {
  padding: 0 rem(24);
  @include respond-to(tablet) {
    padding: 0 rem(72);
  }
  @include respond-to(desktop) {
    padding: 0 rem(208);
  }
}

.fsGroups {
  display: flex;
  flex-direction: column;
  width: 100%;
  > h1 {
    margin-top: rem(48);
    @extend .fsGroups-margin;
    font: 600 rem(26) $font-source-serif;
    color: black;
    text-align: left;
    @include respond-to(tablet) {
      font-size: rem(27);
    }
    @include respond-to(desktop) {
      font-size: rem(42);
    }
  }
  &-list {
    margin-top: rem(32);
    overflow-y: auto;
    > h5 {
      font: 400 rem(16) $font-source-sans;
      @extend .fsGroups-margin;
    }
    &__item {
      display: flex;
      align-items: center;
      border-bottom: rem(1) solid #F0F0F0;
      height: rem(80);
      @extend .fsGroups-padding;
      cursor: pointer;
      &-img {
        width: rem(48);
        height: rem(48);
        border-radius: 50%;
        object-fit: cover;
        border: none; // Remove the border if present
      }
      > span {
        font: 400 rem(16) $font-source-sans;
        margin-left: rem(8);
        margin-right: auto;
      }
    }
  }
  &-add {
    position: fixed;
    bottom: rem(32);
    right: rem(32);
    background-color: $seity-teal-medium;
    width: rem(56);
    height: rem(56);
    border-radius: rem(44);
    > img {
      width: rem(25);
    }
    @include respond-to(desktop) {
      width: rem(88);
      height: rem(88);
      > img {
        width: rem(35);
      }
    }
  }
}

.fsNewFGModal {
  width: rem(606);
  > h2 {
    text-align: center;
  }
  > button {
    margin-bottom: 0;
  }
  &-content {
    width: 100%;
    .seity-input-wrapper {
      margin-top: rem(24);
      width: 100%;
    }
    > button {
      margin-top: rem(24);
      height: rem(48);
      width: 100%;
      border-radius: rem(56);
    }
  }
}