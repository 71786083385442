@import '../../../assets/sass/global';

// Override react-modern-calendar-datepicker package styles
.DatePicker {
  display: flex;
}
.seity-date-picker {
  height: 3rem;
  border-radius: rem(8) !important;
  background-color: white !important;
  border: rem(1) solid $seity-middle-grey !important;
  padding: 0 rem(14) !important;
  outline: none !important;
  font: rem(18) $font-source-sans !important;
  span {
    font-family: $font-source-serif !important;
  }
}

.seity-date-picker::placeholder {
  color: black;
  opacity: 1;

}
.eQmQXs {
  z-index: 10;
}
