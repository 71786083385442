@import "../../../../assets/sass/global";

.service-resource-group-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.service-resource-group-header {
  font-family: $font-source-serif;
  color: $seity-dark-green;
  font-weight: normal;
  margin-top: rem(40);
  margin-bottom: rem(15);
  text-align: center;
}

.resource-group-footer {
  font-family: $font-source-sans;
  color: $seity-dark-green;
  font-style: normal;
  font-size: 36px;
  font-weight: 400;
  margin-top: rem(20);
  margin-bottom: rem(20);

}