@import '../../../../assets/sass/global';

.finish-assessment-later {
  max-width: rem(500);
  overflow: hidden;
  justify-content: space-between;
  height: 100%;
  &__title {
    font-size: rem(28);
    line-height: rem(24);
    color: $seity-black;
    margin-top: rem(18) !important;
    margin-bottom: rem(42) !important;
    white-space: pre-wrap;
    text-align: center;
    @include font-base($font-source-serif, bold, 550);
  }

  h3 {
    font-family: $font-source-sans;
    font-size: rem(28);
    font-weight: 600;
  }
}

.finish-assessment-later button {
  width: 100%;
}

.finish-assessment-button {
  width: 100%;
  max-width: rem(284);
}

.finish-assessment-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: rem(90);
  .seity-hyper-text-container {
    height: rem(60);
    max-width: rem(284);
    width: 100%;
  }
}

.seity-modal-assessment {
  border-radius: rem(8) !important;
  background-color: white;
  max-width: rem(568);
  max-height: rem(250);
  width: 80%;
  height: 100%;
}
