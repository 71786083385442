@import "../../../assets/sass/global";

.fsi-invite-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top:0;
  left: 0;
  z-index: 10;
  background-color: $seity-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  .invite-form-container {
    width: 75vw;
    min-width: 400px;
    padding: 0 rem(40);
    height: auto;
  }

  .title-container {
    width: 100%;
    padding-inline: rem(24);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .title {
    flex-grow: 1;
    font: rem(21) $font-source-sans !important;
    color: $seity-black !important;
    font-weight: 600 !important;
    margin: rem(40) 0 0 0;
  }

  .subtitle {
    font: rem(16) $font-source-sans !important;
    color: var(--gray-gray-0, #677280);
    font-weight: 400 !important;
    margin: rem(24) 0;
    text-align: left;
    width: 100%;
  }

  .invite-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond-to(tablet) {
      max-width: 930px;
    }

    @include respond-to(desktop) {
      max-width: 992px;
    }

    @include respond-to(large-desktop) {
      max-width: 1392px;
    }
  }

  .form-inputs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: rem(24);
    padding-block: rem(24);
  }

  .form-inputs-select {
    margin: 0;
  }

  .invite-date-picker {
    position: relative;
    > img {
      height: 1.5rem;
      width: 1.5rem;
      position: absolute;
      left: 1rem;
      bottom: 0.75rem;
    }
  }

  .invite-date-picker-label {
    font: rem(18) $font-source-sans;
    color: var(--black-0, #343B43);
  }

  .form-inputs-datepicker {
    padding-left: 3rem !important;
  }

  .validation-error {
    color: var(--orange-orange-1, #F75A3B) !important;
    font: rem(16) $font-source-sans !important;
    font-size: rem(16);
    font-weight: 400 !important;
  }

  .invite-button {
    margin-block: 40px;
  }
}
