@import "../../../../assets/sass/global";

.pcc-wrapper {
  width: 100%;
  height: 100%;
  border-radius: rem(35) rem(35) 0 rem(35);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  img {
    margin: rem(55) 0 rem(50) 0;
    width: rem(144);
  }
  h3 {
    font: rem(28) $font-source-sans;
    font-weight: 600;
    margin: 0 0 rem(25) 0;
    color: $seity-black;
    text-align: center;
    width: 90%;
  }
  h1 {
    font: rem(30) $font-source-sans;
    line-height: rem(40);
    margin: 0 0 rem(15) 0;
    color: $seity-black;
  }
}

.pcc-wrapper-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: rem(768);
  max-height: rem(434);
  width: 90%;
  height: 90%;
  h2 {
    margin-top: rem(30);
    font: rem(42) $font-source-serif;
    font-weight: 600;
    margin-bottom: rem(26);
    color: $seity-black;
    text-align: center;
  }
}

.pcc-progress-bar {
  width: rem(131);
  margin-bottom: rem(24);
}

:export {
  pink: $seity-pink;
}

@media (max-height: 715px) {
  .pcc-wrapper {
    margin-bottom: rem(10);
  }
  .pcc-wrapper img {
    margin: rem(20) 0 rem(15) 0;
  }
  .pcc-wrapper h3 {
    margin: 0 0 rem(10) 0;
  }
}
