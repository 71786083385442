@import '../../../../assets/sass/global';

.breathing-all {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

.breathing-01 {
  animation-delay: 1s;
}

.breathing-02 {
  animation-delay: 3.5s;
}

.breathing-03 {
  animation-delay: 5.5s;
}
.breathing-04 {
  animation-delay: 7.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.breathing-exercise {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  h1 {
    color: $seity-black;
    text-align: center;
    @include font-base($font-source-serif, normal, 600);
    font-size: rem(42);
  }
  p {
    color: $seity-black;
    text-align: center;
    @include font-base($font-source-sans, normal, 600);
    font-size: rem(26);
    padding-right: rem(15) !important;
    padding-left: rem(15) !important;
  }
  &-wrapper {
    display: flex;
    flex-direction: column;

    align-items: center;
    width: 100%;
    height: 100%;
  }
  &-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: rem(100);
  }
  &-button {
    max-width: rem(284);
    width: 100%;
  }
  &-text-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}
