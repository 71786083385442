@import '../../../assets/sass/global';

.scp-container {
  width: 100%;
}

.svp-picture-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &-play-button {
    position: absolute;
  }

  &-background {
    width: 100%;
  }
}

.svp-video-wrapper {
  width: 100%;
}
