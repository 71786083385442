@import "../../../assets/sass/global";

.choose-intention {
  position: absolute;
  .mobile-header {
    display: flex;
    justify-content: space-between;
    padding: rem(15) rem(20);
    button {
      &:last-child {
        display: none;
        @include respond-to(mobile) {
          display: block;
        }
      }
    }
  }
  h1 {
    color: $seity-black;
    @include font-base($font-source-serif, normal, 600);
    font-size: rem(42);
    margin-right: rem(44) !important;
    width: 100%;
    margin-top: rem(-50);
    @include respond-to(mobile) {
      margin-top: 0;
    }
    .more-info {
      height: 1rem;
      width: 1rem;
      cursor: pointer;
      margin-bottom: 2rem;
    }
  }
  .info-button {
    width: rem(34);
    height: rem(34);
    &:hover {
      background-color: #cbcaca;
      border-radius: rem(3);
      cursor: pointer;
    }
    z-index: 10;
  }
  .choose-intention-carousel {
    // margin-top: rem(100) !important;
    // position: absolute;
    &.loading {
      margin-right: rem(100);
    }
    img {
      pointer-events: none;
    }
    li {
      width: 100%;
      display: inline-block;
      margin: 0 rem(8);
      max-width: rem(315);
      background-color: $seity-teal-medium;
      padding: rem(16);
      border-radius: rem(16);
      .listitem-middle {
        overflow: hidden;
        height: rem(362);
        position: relative;
        border-radius: rem(16);
        .background-img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
          background-color: rgb(40, 39, 39);
        }
        .listitem-overlay {
          z-index: 8;
          background: rgba(0, 0, 0, 0.3);
          position: absolute;
          transition: 0.5s ease;
          &:hover {
            opacity: 1;
            cursor: pointer;
            background: rgba(0, 0, 0, 0.6);
          }
          p {
            z-index: 7;
            text-align: center;
            @include font-base($font-source-serif, normal, 600);
            font-size: rem(18);
            color: white;
            white-space: pre-line;
          }
        }
      }
      .listitem-top {
        span {
          color: white;
          @include font-base($font-source-sans, normal, 600);
          font-size: rem(18);
          text-transform: uppercase;
          letter-spacing: rem(1.5);
        }
        text-align: center;
      }
      .listitem-bottom {
        span {
          @include font-base($font-source-sans, normal, 400);
          font-size: rem(18);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .backward-button {
      position: absolute;
      z-index: 9;
      left: rem(20);
      top: rem(320);
      padding: rem(10);
      border-radius: rem(20);
      @extend .intention-button;
      @include respond-to(mobile) {
        top: rem(360);
      }
    }
    .forward-button {
      position: absolute;
      z-index: 9;
      right: rem(20);
      top: rem(320);
      padding: rem(10);
      border-radius: rem(20);
      @extend .intention-button;
      &.loading {
        right: rem(80);
      }
      @include respond-to(mobile) {
        top: rem(360);
      }
    }
  }
  .no-intentions {
    width: rem(310);
    p {
      @include font-base($font-source-sans, normal, 400);
      font-size: rem(18);
      color: $journal-gray;
      text-align: center;
    }
    h3 {
      @include font-base($font-source-serif, normal, 600);
      font-size: rem(42);
    }
  }
}
