@import "../../../assets/sass/global";

.school-rankings-table {
  .rankings-table-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    border-radius: 0.625rem;

    &-heading {
      font: 400 1.25rem $font-source-sans;
      color: $seity-dark-grey;
      text-transform: none;
    }

    .rank {
      width: 2.7rem;
      font: 700 0.875rem $font-source-sans;
    }

    .school {
      width: 18.75rem;
      display: flex;
      align-items: center;
      gap: 1.5rem;
      font: 700 0.875rem $font-source-sans;
      text-transform: uppercase;
      letter-spacing: 0.0625rem;
      > p {
        flex: 1;
      }
    }

    .school-extended {
      width: 45.4rem;
      display: flex;
      align-items: center;
      gap: 1.5rem;
      font: 700 0.875rem $font-source-sans;
      text-transform: uppercase;
      letter-spacing: 0.0625rem;
      > p {
        flex: 1;
      }
    }

    .participation {
      width: 27.5rem;

      .progress-outer {
        height: 2.5rem;
        border-radius: 1.875rem;
        background: var(--gray-gray-3, #f0f0f0);
        overflow: hidden;
        position: relative;
        p {
          position: absolute;
          z-index: 2;
          top: rem(12);
          right: rem(8);
          font: 700 0.75rem $font-source-sans;
        }
        .black {
          color: $seity-black;
        }
        .white {
          color: $seity-white;
        }
      }
      .progress-inner {
        height: 2.5rem;
        border-radius: 1.875rem;
        position: relative;

        p {
          position: absolute;
          z-index: 3;
          top: rem(12);
          right: rem(8);
          font: 700 0.75rem $font-source-sans;
        }
        .black {
          color: $seity-black;
        }
        .white {
          color: $seity-white;
        }
      }
    }

    .points {
      width: 3.3rem;
      font: 700 0.875rem $font-source-sans;
    }
  }

  .bordered-box-gray {
    border: 1px solid #ebebeb;
    margin-bottom: 1rem;
  }

  .bordered-box-orange {
    border: 2px solid #f86b4f;
    box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
  }
}
