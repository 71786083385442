@import "../../../assets/sass/global";

.support-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $seity-dark-green;
  padding: rem(50) 0 rem(40) 0;
  width: 95%;
  max-width: rem(766);
  min-width: rem(400);

  @include respond-to(mobile) {
    padding: rem(20) 0 rem(20) 0;
    min-width: rem(300);
  }

  &__submit-btn {
    width: 100%;
  }

  .max-limit {
    font: rem(18) $font-source-sans;
    color: $seity-orange;
  }

  form {
    width: 100%;
  }

  input {
    color: $seity-dark-green;

    &::placeholder {
      color: $seity-dark-green;
    }
  }
}

.support-container__header {
  width: 100%;

  h1 {
    margin-bottom: rem(24);
    @include font-style(
        rem(26),
        600,
        $seity-black,
        left,
        rem(32),
      $family: $font-source-serif
    );
  }

  h2 {
    margin-top: rem(24);
    margin-bottom: rem(30);
    text-align: left;
    @include font-style(
        rem(16),
        500,
        $seity-black,
        left,
        rem(20),
      $family: $font-source-sans
    );
  }



}