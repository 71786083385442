@import '../../../assets/sass/global';

.dragon-main-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  overflow: hidden;
  width: 90%;

  h1 {
    color: $seity-black;
    font: rem(42) $font-source-serif;
    line-height: rem(44);
    font-weight: 600;
    margin: rem(16) 0 rem(16) 0;
    width: 90%;
  }
}

.dragon-logoImage {
  width: rem(108);
  z-index: 1;
  box-shadow: 0 8px 16px 0 rgba(96, 97, 112, 0.16),
    0 2px 4px 0 rgba(40, 41, 61, 0.04);
  border-radius: rem(80);
}

.dragon-see-more-score {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  margin-top: rem(16);
  margin-bottom: rem(24);
  align-items: center;
  :hover {
    cursor: pointer;
  }
  span {
    color: $seity-black;
    font: rem(20) $font-source-sans;
    font-weight: 700;
    padding-right: rem(40);
    @include respond-to(mobile) {
      padding-right: rem(24);
    }
  }
}

.dragon-see-more-score-white {
  span {
    color: $seity-white;
    width: 100%;
  }
  img {
    width: rem(9);
  }
}

.dragon-main-back-button {
  top: $rem-desktop-header-height;
  margin-top: rem(16);
}

.dragon-background-wrapper-01 {
  max-width: rem(770);
  box-shadow: 0 8px 16px 0 rgba(96, 97, 112, 0.16),
    0 2px 4px 0 rgba(40, 41, 61, 0.04);

  background-color: $seity-timberwolf;
  border-radius: 0 rem(52) rem(52) rem(52);
  margin: rem(16) 0 rem(16) 0;
}

.dragon-descripntion-practice {
  position: relative;
  margin-top: rem(32);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: rem(52);
  padding-right: rem(52);
  @include respond-to(mobile) {
    padding-left: rem(32);
    padding-right: rem(32);
  }

  h3 {
    color: $seity-black;
    font: rem(18) $font-source-sans;
  }
}

.dragon-progress-bar-container {
  margin: rem(-5) 0 0 0;
  border: rem(1) solid $seity-world;
  width: rem(78);

  @include respond-to(small-mobile) {
    width: rem(72);
  }
}

.dragon-foreground-wrapper {
  width: 100%;
  max-width: rem(770);
  background-color: $seity-world;
  border-radius: 0 rem(52) 0 rem(52);
  padding: rem(20) rem(52);
  color: $seity-white;

  @include respond-to(small-mobile) {
    margin: rem(20) 0 0 0;
  }
  @include respond-to(mobile) {
    padding-left: rem(32);
    padding-right: rem(32);
  }
}

.header-practice {
  display: flex;

  img {
    margin-right: rem(30);
    width: rem(80);
  }
}

.dragon-score-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: rem(32);
  span {
    color: $seity-white;
    font: rem(30) $font-source-sans;
    margin-bottom: rem(12);
  }
}

.dragon-information {
  display: flex;
  justify-content: center;
  align-items: center;
}

.information-container {
  width: 100%;
  margin: rem(20) auto;

  h2 {
    margin-bottom: rem(20);
    color: $seity-white;
    font-weight: bold !important;
    font: rem(20) $font-source-serif;
  }
  p {
    color: $seity-white;
    font: rem(16) $font-source-sans;
    margin: 30px 0px !important;
  }

  p.link {
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.see-more-score {
  width: 100%;
  cursor: pointer;
  margin: rem(20) 0px 30px 0px;
  img {
    margin-left: 5px;
  }
}
