@import "../../../assets/sass/global";
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px rgba($seity-cream, 1) inset;
}

.login-container {
  position: fixed;
  align-items: center;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding: clamp(51px, 8vh, 100px) 0 0 0;
  margin: 0;
  background-color: $seity-world;
  background-image: url("../../../assets/graphics/web_pattern_login.png");
  background-repeat: repeat-x;
  background-size: rem(1440) clamp(450px, 66vh, 506px);
  overflow: auto;
  @include respond-to(mobile) {
    background-size: 100%;
    padding: clamp(20px, 2vh, 100px) 0 0 0;
  }
  @include respond-to(mobileLandscape) {
    padding: rem(14) rem(44) rem(23);
  }
}

.login-bubble {
  &-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 40px);
    text-align: center;
    padding: rem(10) rem(20) rem(30) rem(20);
    background-color: white;
    border-radius: rem(72) rem(72) rem(72) 0;
    box-shadow: rem(4) 0 rem(4) rgba(0, 0, 0, 0.25);
    color: $seity-dark-green;

    @include respond-to(mobileLandscape) {
      width: 100%;
      height: 100%;
      flex-direction: row;
      padding: rem(40) rem(20);
    }
    @include respond-to(tablet) {
      width: rem(712);
      max-width: rem(712);
      margin: 0 rem(20);
    }

    @include respond-to(mobile) {
      width: rem(390);
      max-width: rem(390);
      margin: 0 rem(20);
    }
    &__top {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include respond-to(mobileLandscape) {
        width: 50%;
        height: 100%;
        padding: 0 rem(20);
      }
      &__signup {
        display: none;
        @include respond-to(mobileLandscape) {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: flex-end;
          span {
            color: $seity-dark-green;
            font-size: 75%;
            u {
              color: $seity-teal;
              font-weight: 600;
            }
          }
        }
      }
    }
    &__down {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: rem(462);
      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 100px white inset;
      }
      @include respond-to(mobileLandscape) {
        width: 50%;
        padding: 0 rem(20);
      }
      @include respond-to(mobile) {
        width: 100%;
        padding: 0 rem(20);
      }
    }
    h1 {
      @include font-style(rem(27), 600, $seity-black, center, 1.3, $family: $font-source-serif);
      @include respond-to(mobileLandscape) {
        font-size: rem(34);
        margin: clamp(10px, 6vh, 30px) 0 0 0;
      }
      @include respond-to(small-mobile) {
        margin: clamp(10px, 2vh, 40px) 0 0 0;
      }
      margin: 24px 0 24px 0;
    }
    h2 {
      @include font-style($size: rem(16), $family: $font-source-sans, $color: $seity-black, $align: center);
      @include respond-to(mobile) {
        margin: 0 0 clamp(10px, 3vh, 24px) 0;
      }
      @include respond-to(mobileLandscape) {
        margin: 0 auto;
      }
      margin: 8px 0 24px 0;
    }
  }
  &-logo {
    width: rem(100);
    margin: 34px 0 0 0;
    @include respond-to(mobileLandscape) {
      width: rem(68);
      margin: 0;
    }
  }
  &-forget-password {
    @include font-style(14px, $align: left, $color: $seity-world, $bold: bold, $family: $font-source-sans, $lineHeight: 1.55);
    margin: clamp(1px, 2vh, 8px) 0 0 0;
    font: 700 rem(16) $font-source-sans;
    display: block;
    width: 100%;
    text-decoration: none;
    cursor: pointer;
  }
  &-button {
    margin-top: rem(24);
    @include respond-to(mobileLandscape) {
      margin: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      width: 60%;
    }
    &-width {
      margin: 0 auto;
      color: white;
      font: 600 rem(21) $font-source-sans !important;
      width: 310px;
    }
  }
  
  &-input {
    padding-block: rem(16);
    font-size: rem(16);
    line-height: rem(22);

    &-container {
      height: unset;
    }
  }

  &-left-icon {
    bottom: rem(16);
    height: rem(24);
    width: rem(24);
    object-fit: scale-down;
  }

  &-right-icon {
    bottom: rem(16);
    height: rem(24);
    width: rem(24);
    object-fit: scale-down;
  }
}
.login-signup {
  @include font-style(16);
  font: rem(14) $font-source-sans;
  color: $seity-world;
  width: rem(414);
  > :first-child {
    margin-right: rem(5);
  }
  margin: 24px 1px 0 0;
  line-height: rem(20);
  @include respond-to(mobile) {
    width: 100%;
  }
  @include respond-to(mobileLandscape) {
    display: none;
  }
  u {
    line-height: rem(20);
    font: 600 rem(14) $font-source-sans;
    cursor: pointer;
    margin-left: 1px;
  }
}
.login-need-support {
  @include font-style(15px, 600, $seity-cream, $lineHeight: 20px, $family: $font-source-sans);
  @include respond-to(mobile) {
    width: 100%;
  }
  @include respond-to(mobileLandscape) {
    display: none;
  }
  u {
    font: 700 rem(16) $font-source-sans;
    cursor: pointer;
    margin: 0 0 0 rem(30);
  }
  &:hover {
    color: $seity-cream;
  }
  text-decoration: underline;
  text-align: center;
  margin: rem(50) 0 rem(8) rem(4);
}
.login-footer {
  @include respond-to(mobile) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: clamp(10px, 1vh, 51px) 0 0 0;
  }
  @include respond-to(mobileLandscape) {
    margin: rem(5) 0 0 0;
    flex-direction: row;
  }
  u {
    cursor: pointer;
    font-weight: 700;
  }
  &-copyright {
    color: $seity-white;
    font: rem(13) $font-source-sans;
    font-weight: 600 !important;
  }
  font: rem(13) $font-source-sans;
  color: $seity-cream;
  margin: 50 0 rem(10) rem(16);
  width: rem(414);
  display: flex;
  justify-content: center;
}

.login-footer-wrapper {
  margin-top: rem(24);
  max-width: rem(436);
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  img {
    margin-right: rem(5);
  }
  span {
    color: $seity-white;
    font: rem(13) $font-source-sans;
    font-weight: 600;
  }
}

// languages modal

.lsm-container {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: rem(8);
  padding: rem(32) rem(40);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: rem(460);
  width: 100%;

  h2 {
    font: 600 rem(28) $font-source-serif;
    margin-top: rem(30);
    margin-bottom: 0;
  }
}

.lsm {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.35);
    z-index: 20;
  }

  &-close {
    align-self: flex-end;
    margin: 0;
  }

  &-save-btn {
    align-self: stretch;
  }

  &-languages {
    margin: rem(24) 0;
    width: 100%;
  }

  &-language-item {
    display: flex;
    justify-content: space-between;
    padding: rem(24) 0;
    border-bottom: rem(2) solid $seity-gallery;
    width: 100%;
  }

  &-radio {
    margin: 0 !important;
  }
}
