@import '../../../../assets/sass/global';

.post-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  .swiper {
    display: flex;
    scroll-behavior: smooth;
    overflow: hidden;
  }

  > div {
    display: flex;
    width: 100%;
  }

  .post-head {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(20);

    &-buttons {
      display: flex;
      align-items: center;

      > input {
        width: rem(32);
        height: rem(32);
        margin-right: rem(13);
        cursor: pointer;
      }

      > input:disabled {
        opacity: 0.5;
      }

      > h4 {
        margin-bottom: 0 !important;
        font: 600 rem(16) $font-source-sans;
        color: $seity-world;
        cursor: pointer;
        margin-left: rem(5);
        &.disabled {
          opacity: 0.7;
          cursor: default;
        }
      }
    }

    &-title {
      display: flex;
      position: relative;

      > h2 {
        font: 600 rem(26) $font-source-serif;
        margin: 0;
      }

      > img {
        width: rem(16);
        height: rem(16);
        margin-left: rem(5);
        cursor: pointer;
      }

      .close-info {
        position: absolute;
        right: rem(16);
        top: rem(16);
        width: rem(24);
        height: rem(24);
        cursor: pointer;
      }
      &-info-modal {
        position: absolute;
        top: rem(35);
        right: rem(8);
        width: rem(370);
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: rem(8);
        z-index: 10;
        transform: translateX(50%);
        box-shadow: 0 20px 32px 0 rgba(96, 97, 112, 0.24),
          0 2px 8px 0 rgba(40, 41, 61, 0.08);
        padding: rem(24);

        @include respond-to(mobile) {
          width: rem(200);
        }

        h2 {
          font: 600 rem(18) $font-source-serif;
        }
        h4 {
          margin: rem(5) 0 0;
          font: normal rem(16) $font-source-sans;
        }
      }

      &-info-modal::before {
        content: '';
        position: absolute;
        top: -16px;
        left: 50%;
        border: solid rem(8) transparent;
        border-bottom-color: #fff;
        z-index: 1;
        transform: translateX(-50%);
      }
    }
  }
}
