@import "../../../assets/sass/global";

.incentive-container {
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;
  align-items: center;
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
  width: rem(340);
  @include respond-to(mobile) {
    width: 100%;
  }

  .prize-name {
    font: 600 1.25rem $font-source-sans;
    line-height: 1.75rem;
    color: $seity-black;
    text-align: center;
  }

  img {
    margin-top: 1.5rem;
    border-radius: 0.5rem;
    object-fit: cover;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    width: 12.625rem;
    height: 6.44rem;
    margin-bottom: 1.5rem;
  }

  .entries {
    font: 700 1rem $font-source-sans;
    color: $seity-black;
    line-height: 1.5rem;
    margin-bottom: 1rem !important;
    text-align: center;
  }

  .sponsor {
    font: 600 0.75rem $font-source-sans;
    color: #949ca4;
  }
}
