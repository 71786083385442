@import '../../../../../assets/sass/global';

.cb {
  &-wrapper {
    position: absolute;
    padding: rem(28) rem(24);
    display: flex;
    background-color: $seity-cream;
    box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04),
      0px 8px 16px rgba(96, 97, 112, 0.16);
    border-radius: rem(16);
  }
  &-slideUp {
    opacity: 0;
    transform: translateY(-700%);
    visibility: hidden;
    transition: all 0.3s ease-out;
    @include respond-to(mobile) {
      transform: translateY(-500%);
    }
  }
  &-slideDown {
    opacity: 1;
    visibility: visible;
    transform: translateY(-450%);
    transition: all 0.3s ease-out;
    @include respond-to(mobile) {
      transform: translateY(-300%);
    }
  }
  &-description {
    font: rem(16) / rem(30) $font-source-sans-bold;
    margin-left: rem(20);
    letter-spacing: rem(1s);
  }
}
