@import '../../../../assets/sass/global';

.cv-outro {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $seity-world;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: rem(75);
  .close-button {
    position: absolute;
    right: 10%;
    top: rem(120);
  }
  > p {
    font: 600 rem(42) $font-source-serif;
    color: $seity-white;
    @include respond-to(mobile) {
      font-size: rem(28);
    }
  }
  > img, > p {
    opacity: 0;
    &.show {
      opacity: 1;
      transition: all 1.5s;
    }
  }

  .cv-outro-anim {
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 4s;
    animation-fill-mode: forwards;
  }
  .cv-outro-01 {
    animation-delay: 1s;
  }
  .cv-outro-02 {
    animation-delay: 2.5s;
  }
  .cv-outro-03 {
    animation-delay: 3.5s;
  }
}
