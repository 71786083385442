@import '../../../assets/sass/global';

.complete-assessment-modal-wrapper {
  padding: 16px;
  width: 500px;
  max-width: 90%;
  text-align: center;

  @media (max-width: 600px) {
    width: 90%;
  }
}

.complete-assessment-modal-content {
  padding: 16px;
}

.complete-assessment-modal-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  color: $seity-black;
}

.complete-assessment-modal-subTitle {
  font-size: 16px;
  margin-bottom: 24px;
  color: $seity-black;
}

.complete-assessment-modal-buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.complete-assessment-modal-buttonContainer {
  width: 100%;
}
