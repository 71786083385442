@import '../../../../assets/sass/global';

.four-core-values-wrapper {
  width: 100vw;
  max-width: rem(1350);
  padding: 0 rem(100);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @include respond-to(mobile) {
    padding: 0 rem(40);
  }
  @include respond-to(small-mobile) {
    padding: 0 rem(5)
  }
  
  h1 {
    color: $seity-black;
    font: rem(42) $font-source-serif;
    margin-top: rem(26);
    font-weight: 600;
  }
}
.four-core-values-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: rem(30) 0 0 0;
  column-gap: rem(24);
  row-gap: rem(24);
  :hover {
    cursor: pointer;
  }
  
  font: rem(16) $font-source-serif;
  color: $seity-dark-green;
  @include respond-to(mobile) {
    grid-template-columns: 1fr 1fr;
  }
  &-value {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    background: $seity-timberwolf;
    border-radius: rem(52) 0 rem(52) 0;
    padding: rem(36) rem(36);
    box-shadow: 0 rem(8) rem(16) 0 rgba(96, 97, 112, 0.16),
      0 rem(2) rem(4) 0 rgba(40, 41, 61, 0.04);
    &:first-child {
      border-radius: 0 rem(52) 0 rem(52);
    }
    &:last-child {
      border-radius: 0 rem(52) 0 rem(52);
    }

    min-width: rem(150);
    min-height: rem(236);
    @include respond-to(small-mobile) {
      padding: rem(25) rem(25);
    }
  }
}

.four-core-value {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(20) rem(20);
  justify-content: flex-start;
  &-img {
    margin-bottom: rem(24);
    margin-top: rem(24);
  }
  &-title {
    margin: rem(24) 0 0 0;
    @include font-style(
      rem(18),
      bold,
      $seity-black,
      center,
      rem(20),
      $family: $font-source-sans
    );
    &-selected {
      color: white;
      font-family: $font-source-sans;
      font-size: rem(18);
      
      &-header {
        font-weight: bold;
        color: white;
        font-family: $font-source-sans;
        font-size: rem(18);
        margin-bottom: rem(50) !important;
      }
    }
  }
  &-bottom {
    padding-bottom: rem(24);
  }
  &-selected {
    background-color: $seity-world;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    @include respond-to(small-mobile) {
      padding: 0 rem(5)
    }
  }
}

.is-accurate {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  width: 100%;
  p {
    @include font-style(
      rem(16),
      normal,
      $seity-black,
      center,
      rem(20),
      $family: $font-source-sans
    );
  }
  &-primary-button {
    box-shadow: 0 4px 8px 0 rgba(96, 97, 112, 0.16),
      0 0 2px 0 rgba(40, 41, 61, 0.04) !important;
    padding: rem(16) rem(81) !important;
  }
  &-cancel-button {
    margin-top: rem(30) !important;
  }
}
