@import '../../../../assets/sass/global';

.assessment-intro {
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 100%;
    justify-content: space-between;

    @include respond-to(mobile) {
      width: 90%;
    }

    h1 {
      color: $seity-dark-green;
      text-align: center;
      @include font-base($font-source-serif, normal, 600);
      font-size: rem(42);
      margin-top: rem(8) !important;

      @include respond-to(mobile) {
        font-size: rem(36);
      }
    }
    p {
      color: $seity-dark-green;
      text-align: center;
      width: 60%;
      @include font-base($font-source-sans, normal, 400);
      font-size: rem(18);
      margin-top: rem(9) !important;

      @include respond-to(mobile) {
        width: 100%;
      }
    }
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    img {
      padding-top: rem(17);
      padding-bottom: rem(17);
      object-fit: contain;
      margin-top: rem(10) !important;

      &.mobile {
        display: none;
      }
      @include respond-to(mobile) {
        width: 100%;
        display: none;
        &.mobile {
          display: inline;
        }
      }
    }
  }
  &-cvonly-wrapper {
    @extend .assessment-intro-wrapper;
    > h1 {
      margin-top: auto !important;
      margin-bottom: rem(30);
    }
    height: 100%;
  }
  &-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: rem(100);
  }
  &-button {
    max-width: rem(284);
    width: 100%;
  }
}
