@import '../../../assets/sass/global';

.aoy-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin: 0 0 rem(30) 0;

  @include respond-to(mobile) {
    flex-direction: column;
    margin: 0;
  }
}

.aoy-intro-wrapper {
  font-family: $font-source-serif;
  margin: 0;
  color: #ffffff;

  width: 100vw;
  min-height: rem(720);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: url('../../../assets/graphics/web_pattern_art_of_you.png');
  background-repeat: round;
  @include respond-to(mobile) {
    flex-direction: column;
    padding-bottom: rem(20);
  }
}
.aoy-intro-wrapper-no-values {
  margin: rem(40) auto 0 auto;
  width: 100%;
  max-width: rem(726);
}

.aoy-header {
  width: 100%;
  min-height: rem(259);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include respond-to(mobile) {
    width: 100%;
    padding-bottom: rem(30);
  }

  &-slider {
    width: 100%;
    height: rem(350);
    overflow: hidden;
    &-inner {
      display: flex;
      width: 200%;
      position: relative;
      right: 00%;

      transition: 0.7s ease-in-out;
    }
  }

  &-slider-left {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-slider-right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.aoy-img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aoy-video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: rem(300);
  h5 {
    font: rem(18) $font-source-sans;
    @include respond-to(mobile) {
      font-size: rem(16);
    }
  }
}

.aoy-circle-button {
  display: flex;
  flex-direction: row;
  background: $seity-loblolly;
  width: rem(18);
  height: rem(18);
  border-radius: rem(100);
  cursor: pointer;
  transition: background-color 0.3s;
  &:last-of-type {
    margin-left: rem(16);
  }
  &-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
}

.aoy-text {
  font-size: rem(36);
  font-weight: 600;
  margin: 0 0 rem(28) 0;
}

.aoy-welcome-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  width: rem(330);
}
.aoy-message-large {
  @include font-style(
    rem(96),
    normal,
    #ffffff,
    $family: $font-serif-display,
    $lineHeight: rem(70)
  );
}
.aoy-message-small {
  @include font-style(rem(42), 300, #ffffff, $family: $font-source-sans);
}

.aoy-core-values-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: rem(72) rem(72) 0 rem(72);
  @include respond-to(mobile) {
    width: 100%;
    padding-left: 0;
    border-radius: rem(40) rem(40) 0 rem(40);
  }
  &-button {
    padding: rem(17) rem(70) !important;
    @include font-style(
      rem(20) !important,
      bold!important,
      #fff!important,
      center!important,
      rem(22) !important,
      $family: $font-source-sans!important
    );
  }
}

.aoy-core-values-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: rem(40) 0;
  > :not(:last-child) {
    margin-bottom: rem(25);
  }
}

.aoy-core-values-text {
  @include font-style(
    rem(28),
    600,
    #fff,
    center,
    rem(24),
    $family: $font-source-serif
  );

  @include respond-to(mobile) {
    margin: 0;
  }
}

.aoy-core-value {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  max-width: rem(370);
  max-height: rem(64);
  height: rem(100);
  border-radius: rem(10);
  background-color: #fff;
  padding-left: rem(25);

  @include respond-to(mobile) {
    max-width: 90%;
  }

  h1 {
    margin: 0;
    @include font-style(
      rem(18),
      600,
      $seity-black,
      center,
      rem(20),
      $family: $font-source-sans
    );
  }
}

.aoy-core-value-img {
  position: absolute;
  top: rem(-15);
  right: rem(-20);
  width: rem(110);
}

.aoy-explore-wrapper {
  height: rem(126);
  border-radius: rem(72) 0 rem(72) 0;
  align-items: flex-end;

  @include respond-to(mobile) {
    border-radius: rem(25) 0 rem(25) 0;
    padding-top: rem(13);
    height: rem(50);
  }
}

.aoy-explore-text {
  font: rem(18) / rem(24) $font-source-sans;
  text-align: right;
  display: flex;
  flex-direction: column;
  @include respond-to(mobile) {
    flex-direction: row;
  }
}

.aoy-explore-caret {
  background: url('../../../assets/graphics/caret_white.png');
  width: rem(11);
  height: rem(22);
  margin: 0 0 0 rem(30);
  background-repeat: no-repeat;
}

.aoy-intro-wrapper-no-values
  .aoy-header
  .aoy-header-slider
  .aoy-header-slider-inner
  .aoy-header-slider-left {
  @include respond-to(desktop) {
    padding-left: rem(0);
  }
}

.aoys-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0;

  @include respond-to(tablet) {
    margin-left: 0;
    flex-direction: row;
  }
  @include respond-to(desktop) {
    margin-left: rem(20);
    flex-direction: row;
  }
}

.aoys-intro-wrapper {
  font-family: $font-source-serif;
  margin: rem(40) 0 0 0;
  color: #ffffff;
  max-width: rem(920);
  width: 76%;
  min-height: rem(370);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: url('../../../assets/graphics/web_pattern_art_of_you.png');
  border-radius: 0 rem(72) 0 rem(72);
  @include respond-to(mobile) {
    flex-direction: column;
    width: 100%;
    border-radius: 0 rem(40) 0 rem(40);
    max-width: rem(951);
  }

  @include respond-to(tablet) {
    width: 78%;
  }
  @include respond-to(desktop) {
    width: 76.2%;
  }
}

.aoys-header {
  width: 50%;
  min-height: rem(259);
  flex-direction: column;
  justify-content: center;

  @include respond-to(mobile) {
    width: 100%;
    padding-bottom: rem(30);
  }

  &-slider {
    width: rem(280);
    height: rem(230);
    overflow: hidden;

    @include respond-to(tablet) {
      width: 60vw;
      max-width: rem(265);
    }
    @include respond-to(desktop) {
      width: 200%;
      max-width: rem(1000);
    }
    &-inner {
      display: flex;
      width: 200%;
      height: rem(200);
      position: relative;
      transition: 0.7s ease-in-out;
    }
  }

  &-slider-left {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-slider-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.aoys-caret {
  @include respond-to(desktop) {
    display: none;
  }
}

.aoys-img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aoys-video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: rem(375);
  width: 85%;
  @include respond-to(tablet) {
    width: 80%;
  }
  @include respond-to(desktop) {
    width: 100%;
    padding-left: rem(30);
  }

  h5 {
    font: rem(18) $font-source-sans;
    @include respond-to(mobile) {
      font-size: rem(16);
    }
  }
}

.aoys-carousel {
  padding: rem(15) 0 0 rem(25);

  @include respond-to(mobile) {
    padding: rem(15) rem(20) 0 0;
  }

  @include respond-to(desktop) {
    display: none;
  }
}

.aoys-text {
  font-size: rem(36);
  font-weight: 600;
  margin: 0 0 rem(28) 0;
}

.aoys-welcome-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  width: rem(250);
  margin-top: rem(50);
}
.aoys-message-large {
  color: $seity-cream;
  font-family: $font-serif-display;
  font-weight: 100;
  line-height: rem(52);
  font-size: rem(60);
}
.aoys-message-small {
  color: $seity-cream;
  font-family: $font-serif-display;
  font-weight: 100;
  line-height: rem(30);
  font-size: rem(25);
}

.aoys-intro-wrapper-no-values
  .aoys-header
  .aoys-header-slider
  .aoys-header-slider-inner
  .aoys-header-slider-left {
  @include respond-to(desktop) {
    padding-left: rem(0);
  }
}

.na-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  color: $seity-cream;
  font-family: $font-source-sans;
  @include respond-to(mobile) {
    flex-direction: column;
  }
}

.na-no-assessment-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: rem(32) rem(40);
  border-radius: 0 rem(40) 0 rem(40);

  background-color: $seity-timberwolf;
  @include respond-to(mobile) {
    width: 100%;
    border-radius: 0 rem(40) rem(40) rem(40);
  }

  h1 {
    @include font-style(
      rem(28),
      600,
      $seity-black,
      center,
      1.14,
      $family: $font-source-serif
    );
    margin: 0 0 rem(24) 0;
  }

  p {
    @include font-style(rem(18), normal, $seity-black, center);
  }

  &-button {
    font-family: $font-source-sans !important;
    font-weight: bold !important;
    margin-top: auto;
    padding: rem(17) rem(79) rem(19) rem(79) !important;

    @media only screen and (max-width: rem(768)) {
      padding: rem(17) rem(89) rem(17) rem(89) !important;
    }
    @media only screen and (min-width: rem(769)) {
      padding: rem(17) rem(39) rem(17) rem(39) !important;
    }
  }
}

.na-start-assessment-wrapper {
  height: rem(188);

  @include respond-to(mobile) {
    height: rem(50);
  }
}
