@import "../../../../../assets/sass/global";

.answer-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: rem(8);
  padding: rem(32) rem(40);
  max-height: 85%;
  display: flex;
  flex-direction: column;
  width: rem(489);
  height: rem(500);
  min-height: rem(500);
  display: flex;
  flex-direction: column;
  align-items: center;
  @include respond-to(mobile) {
    width: rem(359);
    height: rem(450);
  }
  h3 {
    @include font-base($font-source-serif, normal, 600);
    font-size: rem(42);
  }
  p {
    margin-top: rem(5) !important;
    @include font-base($font-source-sans, normal, 400);
  }
  &__title {
    font-size: rem(24);
    color: $seity-black;
    text-align: center;
  }
  &__subtitle {
    font-size: rem(21);
    color: $journal-gray;
  }
  button {
    width: rem(310);
  }
  .btn-notnow {
    @include font-base($font-source-sans, normal, 600);
    font-size: rem(18);
    color: #4f7e81;
  }
}

.answer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 20;
}
