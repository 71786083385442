.popover {
  background-color: #41625e !important;
  border-radius: 16px !important;
  border: none;
  min-width: 20em;
}

.more-info-white-icon {
  width: 25px;
}

.info-close-icon {
  cursor: pointer;
  width: 10px;
}

.info-text {
  color: #fff8eb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 22px;
  font-weight: 400;
  font-size: 18px;
  max-height: 250px;
  overflow: scroll;
}

.popover .arrow {
  visibility: hidden;
}

.more-info-icon {
  cursor: pointer;
  width: 15px;
  margin-top: 0.4em !important;
  left: 3.5em;
  bottom: 3.6em;
}
