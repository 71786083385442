@import "../../../assets/sass/global";

.seityCVModal {
  width: rem(1011);
  height: rem(851);
  max-width: 90%;
  padding-top: rem(16);
  > button {
    margin-bottom: 0;
  }
  &-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    gap: rem(24);
  }
  &-video {
    position: relative;
    display: flex;
    width: 39%;
    justify-content: center;
    > video {
      width: 100%;
      // height: 100%;
      object-fit: cover;
      border-radius: rem(16);
    }
  }
  &-desc {
    width: 61%;

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: rem(8);
      margin-bottom: rem(24);
      margin-top: rem(24);
      h1 {
        font-size: rem(42);
        margin: 0;
      }
    }

    &-divider {
      width: 100%;
      height: rem(0.5);
      background-color: rgba($color: $seity-black, $alpha: 0.2);
      margin-top: rem(24);
    }
    > h2 {
      text-align: center;
    }
    > h3 {
      margin-top: rem(24);
      font: 600 rem(26) $font-source-sans;
      line-height: rem(42);
      color: $seity-black;
      @include respond-to(mobile) {
        font-size: rem(18);
        line-height: rem(24);
      }
    }
    > h4 {
      font: 400 rem(20) $font-source-sans;
      line-height: rem(28);
      margin-top: rem(24);
    }
    > p {
      font: 400 rem(20) $font-source-sans;
      line-height: rem(24);
      color: $seity-black;
      @include respond-to(mobile) {
        font-size: rem(16);
        line-height: rem(24);
      }
    }
  }
}
