@import "../../../../assets/sass/global";

.core-values-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sticky-header {
  padding-top: rem(20);
  position: sticky;
  top: rem(75);
  background-color: $seity-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 101%;

  @include respond-to(mobile) {
    top: rem(60);
  }
}

.sticky-header-2 {
  position: sticky;
  top: 0;
  background-color: $seity-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;

  .filters-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $seity-white;
    width: 100%;
    margin-bottom: 1.12rem;

    p {
      color: $seity-black;
      font: 1.625rem $font-source-sans;
      font-weight: 600;
      line-height: 2.625rem;
      margin-bottom: 0;
    }
  }
}

.search-input {
  background-color: $seity-gallery;
  border-radius: rem(24);
  border: none;
  display: flex;
  justify-content: center;
  &-container {
    height: rem(40);
  }
  &-icon {
    bottom: rem(7);
  }
}
.letters-nav-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: #f0f0f0;
  height: fit-content;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  margin: 1.5rem 0;

  > h2 {
    font: normal rem(18) $font-source-sans;
    color: $seity-dark-grey;
    margin: 0;
    cursor: pointer;
  }
  .disabled-char {
    opacity: 0.4;
    cursor: not-allowed;
  }
  .active-char {
    font-weight: bold;
    color: $seity-world;
  }
}
.top-devider {
  height: rem(3);
  border-bottom: rem(1) solid #f0f0f0;
  width: 100vw;
  align-self: center;
}
.core-values-list {
  margin-top: rem(16);
  padding-bottom: rem(300);
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  &-char-group {
    display: flex;
    flex-direction: column;

    > h2 {
      font: normal rem(18) $font-source-sans;
      color: $seity-dark-grey;
      margin: 0;
    }
    .char-devider {
      height: rem(3);
      border-bottom: rem(1) solid #e4e4e4;
      margin-bottom: rem(16);
      width: 100%;
    }
  }
  &-item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: rem(16) rem(24);
    border: 1px solid transparent;
    border-radius: rem(16);
    box-shadow: 0 0.5px 2px 0 rgba(96, 97, 112, 0.16), 0 0 1px 0 rgba(40, 41, 61, 0.08);
    background-color: #fff;
    margin-bottom: rem(16);
    cursor: pointer;

    > img {
      width: rem(40);
      height: rem(40);
      margin-right: rem(8);
    }
    > h4 {
      font: 600 rem(16) $font-source-sans;
      margin: 0 !important;
      margin-left: rem(8) !important;
    }
  }
  &-item.selected {
    background-color: #bbdadb;
    border: 1px solid $seity-teal-medium;
  }
}

.filters-ctas {
  position: sticky;
  bottom: 0;
  padding: 2rem;
  background-color: $seity-white;
  .button-container {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    padding: 1.5rem 0;
    border-top: 1px solid #f0f0f0;
  }
}

.core-value-modal {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: rem(24);
  overflow-y: auto;
  position: relative;

  &-button {
    cursor: pointer;
    width: rem(64);
    position: absolute;
    right: rem(-75);
    top: 50%;
    transform: translateY(-50%);
    &-disabled {
      pointer-events: none;
      opacity: 40%;
    }
  }
  &-button-next {
    right: rem(-75);
    @include respond-to(mobile) {
      right: rem(-55);
    }
  }
  &-button-prev {
    left: rem(-75);
    @include respond-to(mobile) {
      left: rem(-55);
    }
  }

  &-wrapper {
    width: rem(1075);
    max-width: 90%;
    padding-top: rem(16);
    background-color: #fff;
    > button {
      margin-bottom: 0;
    }
  }

  &-video {
    position: relative;
    display: flex;
    width: 39%;
    justify-content: center;
    > video {
      width: 100%;
      object-fit: cover;
      border-radius: rem(16);
    }
  }
  &-desc {
    width: 61%;

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: rem(8);
      margin-bottom: rem(24);
      margin-top: rem(24);
      h1 {
        font-size: rem(42);
        margin: 0;
      }
    }

    &-divider {
      width: 100%;
      height: rem(0.5);
      background-color: rgba($color: $seity-black, $alpha: 0.2);
      margin-top: rem(24);
    }
    > h2 {
      text-align: center;
    }
    > h3 {
      margin-top: rem(24);
      font: 600 rem(26) $font-source-sans;
      line-height: rem(42);
      color: $seity-black;
      @include respond-to(mobile) {
        font-size: rem(18);
        line-height: rem(24);
      }
    }
    > h4 {
      font: 400 rem(20) $font-source-sans;
      line-height: rem(28);
      margin-top: rem(24);
    }
    > p {
      font: 400 rem(20) $font-source-sans;
      line-height: rem(24);
      color: $seity-black;
      @include respond-to(mobile) {
        font-size: rem(16);
        line-height: rem(24);
      }
    }
  }
}

.padding-horizontal {
  padding-left: 2rem;
  padding-right: 2rem;
}
