@import "../../../assets/sass/global";

.fs-member-sidebar-container {
  z-index: 10;
  top: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  background: $seity-white;
  box-shadow: 0px 20px 32px 0px rgba(96, 97, 112, 0.24), 0px 2px 8px 0px rgba(40, 41, 61, 0.08);
  padding: 2.5rem 0;
  @include respond-to(desktop) {
    width: 43rem;
  }

  .header {
    text-align: center;
    font: 600 1.3rem $font-source-sans;
    margin-bottom: 0.5rem;

    > button {
      position: fixed !important;
      height: 1.5rem;
      width: 1.5rem;
      position: absolute;
      top: 2.5rem;
      right: 2rem;
      cursor: pointer;
      > img {
        width: 1.5rem;
      }
    }
  }

  .fs-delete-icon-row {
    margin-left: rem(40);
    font: 600 rem(16) $font-source-sans;
    color: #DE5135;
  }
}
