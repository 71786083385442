@import '../../assets/sass/global';

.sap-wrapper {
  @include main-width;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: rem(55) 0;
}

.sap-title {
  margin-bottom: rem(32);
  @include font-style(
    rem(42),
    600,
    $seity-black,
    center,
    rem(44),
    $family: $font-source-serif
  );
}

.sap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: rem(770);
  box-shadow: 0 4px 8px 0 rgba(96, 97, 112, 0.16),
    0 0 2px 0 rgba(40, 41, 61, 0.04);

  &-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: rem(32) rem(100);
    background-color: $seity-spirit;

    & > h1 {
      margin-bottom: rem(24);
      @include font-style(
        rem(26),
        600,
        $seity-black,
        center,
        rem(32),
        $family: $font-source-serif
      );
    }

    & > h2 {
      margin-top: rem(24);
      @include font-style(
        rem(16),
        500,
        $seity-black,
        center,
        rem(20),
        $family: $font-source-sans
      );
    }
  }

  &-content {
    width: 100%;
  }
}

.sap-backCaret {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
}
