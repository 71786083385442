@import "../../../assets/sass/global";

.ao-container-due {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin: 0 0 rem(20) 0;
  @include respond-to(mobile) {
    flex-direction: column;
  }
}

.ao-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  @include respond-to(mobile) {
    flex-direction: column;
  }
}

.ao-reflect-wrapper {
  font-family: $font-source-serif;
  color: $seity-dark-green;
  width: 100%;
  height: 100%;
  max-height: rem(1065);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: rem(32) rem(40);
  border-radius: 0 rem(30) 0 rem(30);
  background-color: $seity-timberwolf;

  @include respond-to(mobile) {
    padding: rem(32);
  }

  &__header {
    @include respond-to(mobile) {
      margin-left: 0;
    }
  }
  &-button {
    width: 100%;
    padding: rem(17) rem(41) rem(17) rem(41) !important;
    @include respond-to(tablet) {
      padding: rem(17) rem(31) rem(17) rem(31) !important;
    }
    @include respond-to(desktop) {
      padding: rem(19) rem(59) !important;
    }
  }
  h1 {
    @include font-style(rem(28), 600, $seity-black, center, rem(32));
  }
  h4 {
    @include font-style(rem(18), normal, $seity-black, center, rem(20));
    font: rem(18) $font-source-sans;
  }
}

.ao-refect-content {
  display: flex;
  flex-direction: column;
  padding: 0 rem(22);
}

.ao-reflect-core {
  background-color: #b7b5ac;
  max-width: rem(340);
  height: rem(80);
  border-radius: rem(30) 0 rem(30) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: rem(10) 0 0 rem(120);
  color: $seity-cream;
  cursor: pointer;
  @include respond-to(mobile) {
    margin: rem(10) 0 0 rem(90);
  }
}

.ao-reflect-core-img {
  background: url("../../../assets/graphics/corevalue02.png");
  width: rem(49);
  height: rem(49);
  margin: 0 0 0 rem(60);
  background-repeat: no-repeat;
  @include respond-to(mobile) {
    margin-left: rem(20);
  }
}

.ao-reflect-core-text {
  color: $seity-dark-green;
  margin: 0 0 0 rem(-50);
  @include respond-to(mobile) {
    margin-left: 0;
  }
}

.ao-assessment-summary-score {
  display: inline-block;
  text-align: center;
  width: rem(72);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: rem(48);
  @include respond-to(mobile) {
    margin-right: rem(18);
  }
}

.ao-assessment-summary-content {
  display: flex;
  margin: rem(10) 0 0 0;
  @include respond-to(mobile) {
    margin: rem(10) 0 0 0;
  }
}

.ao-assessment-summary-score-text {
  color: $seity-green;
  font-size: rem(30);
}

.aoy-progress-bar-container {
  border: rem(1) solid $seity-dark-green;
  width: rem(72);
}

.ao-assessment-summary-imgs {
  width: rem(49);
  margin: 0 rem(40) 0 rem(60);
  @include respond-to(mobile) {
    margin: 0 rem(20);
  }
}

.ao-assessment-summary-text {
  display: inline-block;
  vertical-align: middle;
  width: rem(150);
  @include respond-to(mobile) {
    width: rem(100);
  }
}

.ao-assessment-summary-caret {
  background: url("../../../assets/graphics/caret_white.png");
  width: rem(11);
  height: rem(22);
  margin: 0 rem(30) 0 0;
  background-repeat: no-repeat;
}

.ao-assessment-summary-caret-green {
  background: url("../../../assets/graphics/caret_seity_green.png");
  width: rem(11);
  height: rem(22);
  margin: 0 rem(30) 0 0;
  background-repeat: no-repeat;
}

.ao-retake-wrapper {
  height: 100%;
  border-radius: rem(72) 0 rem(72) 0;
  display: flex;
  align-items: flex-start;
}

.ao-retake-text {
  font-size: rem(16);
  text-align: right;
  color: $seity-cream;
  display: flex;
  flex-direction: column;
  @include respond-to(mobile) {
    flex-direction: row;
  }
}

.ao-retake-caret {
  background: url("../../../assets/graphics/caret_white.png");
  width: rem(11);
  height: rem(22);
  margin: 0 0 0 rem(30);
  background-repeat: no-repeat;
}

.ao-ai {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ao-ai-score {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: rem(60);
  margin-right: rem(15);

  @include respond-to(mobile) {
    margin-right: rem(12);
  }

  h2 {
    color: $seity-black;
    font: rem(30) $font-source-sans;
  }

  &-progress-bar {
    border: rem(1) solid $seity-black;
    width: rem(60);
  }
}

.ao-ai-bubble {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: rem(96);
  font-size: rem(18);
  padding: 0 rem(20) 0 rem(16);

  cursor: pointer;
  color: $seity-dark-green;
  box-shadow: 0 2px 4px 0 rgba(96, 97, 112, 0.16), 0 0 1px 0 rgba(40, 41, 61, 0.04);
  h2 {
    @include font-style(rem(20), bold, #ffffff, left);
    justify-self: flex-start;
    width: 70%;
    max-width: rem(260);
    padding: 0 rem(20) 0 rem(15);
    margin: 0 0 0 rem(-50);

    @media screen and (min-width: 768px) and (max-width: 1152px) {
      width: 40%;
    }

    @include respond-to(mobile) {
      width: 60%;
      margin-left: 0;
      padding: 0 0 0 0;
    }
  }

  &-logo {
    align-self: center;
    width: rem(59);
    margin-right: rem(8);
  }

  &-caret {
    width: rem(11);
    height: rem(22);
  }
}

.ao-ai-bubble-corevalues {
  background-color: $seity-grey-gainsboro;
  border-radius: rem(10);
}

.ao-ai-bubble-world {
  @extend .ao-ai-bubble-corevalues;
  background-color: $seity-world;
  border-radius: rem(10);
}

.ao-ai-bubble-body {
  @extend .ao-ai-bubble-corevalues;
  background-color: $seity-teal;
  border-radius: rem(10);
}

.ao-ai-bubble-mind {
  @extend .ao-ai-bubble-corevalues;
  color: $seity-dark-green;
  background-color: $seity-mind;
  border-radius: rem(10);
  h2 {
    color: $seity-black;
  }
}

.ao-ai-bubble-spirit {
  @extend .ao-ai-bubble-corevalues;
  background-color: $seity-spirit;
  border-radius: rem(10);
  h2 {
    color: $seity-black;
  }
}

.ao-ai-bubble-wholehealthoutcomes {
  @extend .ao-ai-bubble-corevalues;
  background-color: $seity-pink;
  border-radius: rem(10);
}
