@import "../../../../assets/sass/global";

.plotline-image-body {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  img {
    height: 100%;
    width: auto;
    @include respond-to(mobile) {
      width: 100%;
      height: auto;
    }
  }
}
