@import '../../../assets/sass/global';

.post-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: rem(40);
  width: 100%;
  > h1 {
    font: 600 rem(42) $font-source-serif;
  }
}

.post-back-link {
  background: none;
  color: $seity-teal;
  border: none;
  text-decoration: underline;
  font: rem(16) $font-source-sans;
  margin: rem(10) 0 rem(40) 0;
}

h1 {
  color: $seity-dark-green;
  text-align: center;
  @include font-base($font-source-serif, normal, 400);
  font-size: rem(40);
  margin-top: rem(20);
}
.no-image {
  background: #b5b5b5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: rem(200);
  border-radius: rem(20);
  border-bottom-left-radius: 0px;
  width: rem(400);
}

.video-container {
  margin-top: rem(20);
  padding: rem(10);
  width: 100%;
  max-width: rem(800);
  height: rem(290);
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;
  aspect-ratio: 16/9;
  > img {
    width: rem(46);
    height: rem(46);
    margin: 0 !important;
  }
}
.video-text-container {
  max-width: rem(800);
  width: 100%;
  align-self: center;
  h3 {
    font-size: rem(20);
    font-weight: bold;
  }
  h4 {
    margin: rem(20) rem(8) 0;
    font: 600 rem(28) $font-source-serif;
  }
}

iframe {
  width: 100%;
}

.default-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
