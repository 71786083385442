@import "../../../assets/sass/global";

.overall-standings-container {
  border-radius: 1.25rem;
  margin-bottom: 3.25rem;

  .header {
    display: flex;
    gap: 0.5rem;
    height: fit-content;
    margin-bottom: 1rem;

    h3 {
      font: 600 1.625rem $font-source-sans;
      color: $seity-black;
      position: relative;
    }

    .more-info {
      height: 1rem;
      width: 1rem;
      cursor: pointer;
      display: none;
    }
  }

  h5 {
    font: 400 1.25rem $font-source-sans;
    color: $seity-dark-grey;
  }

  > .learn-more {
    font: 400 1.25rem $font-source-sans;
    color: #539fa3;
    text-decoration: underline;
    cursor: pointer;
  }

  .meta-content {
    display: flex;
    justify-content: space-between;
    gap: rem(40);
    margin-top: 2.5rem;
    flex: 1;
    @include respond-to(mobile) {
      flex-direction: column;
    }

    .prize-carousel {
      flex: 1;
      .prize-image-container {
        // max-width: 44.125rem;
        height: 14.125rem;
        background: #f0f0f0;
        border-radius: 0.5rem;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          position: absolute;
          height: 100%;
          border-radius: 0.5rem;
          z-index: 1;
        }

        .rank-tag {
          position: absolute;
          background-color: #cbe3e4;
          border-radius: 6.25rem;
          padding: 0.5rem 1.5rem;
          width: fit-content;
          margin-left: auto;
          margin-right: auto;
          top: -1rem;
          left: 0;
          right: 0;
          z-index: 2;

          p {
            font: 700 0.875rem $font-source-sans;
            text-transform: uppercase;
            letter-spacing: 0.0625rem;
            color: $seity-black;
          }
        }

        .prize-info {
          width: fit-content;
          text-align: center;
          z-index: 2;

          .prize-name {
            font: 700 2rem $font-source-sans;
            // text-transform: uppercase;
            letter-spacing: 0.0625rem;
            color: $seity-black;
            margin-bottom: 1rem;
          }

          .sponsor {
            font: 400 1rem $font-source-sans;
            line-height: 1.25rem;
            color: $seity-black;
          }
        }
      }

      &-nav {
        width: fit-content;
        margin: auto;
        display: flex;
        align-items: center;
        gap: 2rem;
        margin-top: 1rem;

        .slides-indicator {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          .bar {
            width: 2rem;
            height: 3px;
            border-radius: 3px;
            background: #d9d9d9;
          }
          .active {
            width: 4rem;
            background: #000;
          }
        }

        .nav-buttons {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          img {
            height: 1rem;
            width: 1rem;
            cursor: pointer;
          }
        }
      }
    }

    .meta-info {
      width: rem(336);
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      @include respond-to(mobile) {
        width: 100%;
      }

      .meta-info-box {
        border-radius: 0.625rem;
        border: 1px solid #ebebeb;
        padding: 2rem;
        width: 100%;
        height: fit-content;
        display: flex;
        gap: 1.5rem;

        img {
          width: 2.5rem;
          height: 2.5rem;
        }

        .content {
          display: flex;
          gap: 1.5rem;

          div {
            .label {
              font: 400 1rem $font-source-sans;
              line-height: 1.25rem;
              color: #677280;
            }

            .value {
              font: 600 1.125rem $font-source-sans;
              color: $seity-black;
            }
          }
        }
      }
    }

    .meta-info-row {
      @extend .meta-info;
      flex-direction: row;
      flex: 1;
    }
  }
}
