@import "../../../assets/sass/global";

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $seity-dark-green;
  padding: rem(50) 0 rem(40) 0;
  width: 95%;
  max-width: rem(766);
  min-width: rem(400);

  @include respond-to(mobile) {
    padding: rem(20) 0 rem(20) 0;
    min-width: rem(300);
  }

  .profile-avatar-container {
    position: relative;
    width: fit-content;
    margin-right: auto;
    margin-bottom: 1.7rem;

    .photo-action-button {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #fff;
      border: 1px solid #e4e4e4;
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      position: absolute;
      bottom: 0;
      right: 0;

      img {
        width: 1rem;
        height: 1rem;
        object-fit: contain;
      }
    }

    .photo-action-drawer {
      border-radius: 0.5rem;
      background: #fff;
      box-shadow: 0px 20px 32px 0px rgba(96, 97, 112, 0.24), 0px 2px 8px 0px rgba(40, 41, 61, 0.08);
      padding: 0rem 1rem;
      width: 11.5rem;
      z-index: 3;
      position: absolute;
      right: -10rem;
      margin-top: -1rem;

      > div {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 1rem 0;

        img {
          height: 1.5rem;
          width: 1.5rem;
          margin-right: 1rem;
        }

        p {
          font-size: 1.125rem;
          font-family: $font-source-sans;
          font-weight: 600;
          line-height: 1.25rem;
        }
      }

      > div:nth-child(2) {
        border-top: 1px solid #f0f0f0;

        p {
          color: #f75a3b;
        }
      }

      input {
        width: 0;
        height: 0;
      }
    }
  }

  h1 {
    font: rem(42) $font-source-serif;
    color: $seity-black;
    font-weight: 600;
    margin: 0 0 rem(10) 0;

    @include respond-to(mobile) {
      margin: 0 0 rem(20) 0;
    }
  }

  h2 {
    margin: rem(30) 0 0 0;
    align-self: flex-start;
    font: rem(26) $font-source-serif;
    font-weight: 600;
  }

  h4 {
    font: 700 rem(16) $font-source-sans;

    color: $seity-world;
    cursor: pointer;
  }

  h4:hover {
    text-decoration: underline;
  }

  hr {
    border: rem(1) solid $seity-dark-green;
    width: 100%;
    margin: rem(15) 0 rem(20) 0;

    @include respond-to(mobile) {
      width: 95%;
    }
  }

  form {
    width: 100%;
  }

  input {
    color: $seity-dark-green;
    &::placeholder {
      color: $seity-dark-green;
    }
  }
}

.profile {
  &-button-wrapper {
    margin: 0 0 rem(30) 0;
    width: 100%;
    text-align: center;

    @include respond-to(mobile) {
      margin: 0 0 rem(20) 0;
    }
  }

  &-button {
    width: 100%;
    max-width: rem(284);
  }

  &-line {
    width: 100%;
    border: rem(1) solid $seity-middle-grey !important;
    margin: rem(15) 0 rem(20) 0;

    @include respond-to(mobile) {
      width: 95%;
    }
  }

  &-date-picker {
    margin-bottom: rem(18);
  }

  &-terms {
    margin-top: rem(40);
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.profile-date-picker {
  position: relative;

  > img {
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    right: 1rem;
    bottom: 0.75rem;
  }
}
