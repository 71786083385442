@import '../../../assets/sass/global';

.assess-container {
  width: 100%;
  align-items: center;
  padding-bottom: rem(10);
}

.assess-top {
  margin-top: rem(155);
  @include respond-to(mobile) {
    margin-top: rem(185);
  }
  @include respond-to(mobile) {
    margin-top: rem(185);
  }
  &-subheader {
    margin-top: rem(45);
  }
}

.aw-header {
  position: fixed;
  left: 0;
  top: $rem-mobile-header-height;
  padding-bottom: rem(10) !important;
  width: 100%;
  border-bottom: rem(1) solid $seity-grey;
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  z-index: 9;

  @include respond-to(tablet) {
    top: $rem-desktop-header-height;
    padding: 0 rem(20);
  }

  @include respond-to(mobile) {
    padding: 0 rem(10);
  }

  &.hideNavbar {
    top: 0;
  }

  &__steps {
    width: rem(90);
    height: rem(30);
    display: flex;
    flex-direction: row;
  }
  &__title {
    display: block;
    margin-left: auto;
    @include font-base($font-source-sans, normal, 400);
    font-size: rem(24);
    line-height: 1.4;
  }
  &__top {
    display: flex;
    width: 100%;
    padding: 0 rem(15);
  }
}