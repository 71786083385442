@import '../../../../../app/App.scss';

.naml-cream {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: $seity-white;
  border-radius: rem(4);
  padding: rem(32);
  width: 95%;
  max-width: rem(425);
  max-height: rem(524);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 20;

  @include respond-to(mobile) {
    padding: rem(32);
    max-height: none;
  }
}

.naml-overlay-dark {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
}

.naml-wrapper {
  color: $seity-dark-green;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include respond-to(mobile) {
    width: clamp(278px, 80vw, 500px);
  }

  h2 {
    font: rem(24) / rem(24) $font-serif-display;
    text-align: start;
    @include respond-to(mobile) {
      font-size: rem(24);
      padding-right: 0;
    }
  }

  h4 {
    margin-top: rem(10);
    margin-bottom: rem(40);
    font: rem(20) $font-source-sans;
    max-width: rem(570);
    text-align: start;
    @include respond-to(mobile) {
      margin-top: rem(10);
      margin-bottom: rem(20);
      font: rem(18) $font-source-sans;
      max-width: 90%;
    }
  }

  &-loop {
    height: rem(120);
    width: rem(385);
    position: relative;
    bottom: rem(55)
  }
}
