@import '../../../assets/sass/global';

.seity-input-password {
  width: 450px;
  height: 60px;
  border-radius: rem(8);
  background-color: $seity-cream;
  border: rem(1.5) solid $seity-green;
  padding: 0 rem(14);
  outline: none;
  font: rem(18) $font-source-sans;
  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0, 123, 255, 0.25);
  }
  &-wrapper {
    position: relative;
    width: 100%;
    height: rem(60);
  }
  &-padding-left {
    padding-left: rem(30);
  }
  &-padding-right {
    padding-right: rem(45);
  }
  &-left-icon {
    position: absolute;
    left: rem(10);
    bottom: rem(16);
  }
  &-right-icon {
    position: absolute;
    width: rem(22);
    right: rem(25);
    bottom: rem(25);
  }
  &-error {
    border: rem(1.5) solid $seity-orange !important;
  }
}

.seity-input-password-with-label {
  label {
    font: rem(18) $font-source-sans;
    color: $seity-dark-green;
    padding: 0;
    margin-left: 0 !important;
  }
}
