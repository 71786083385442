@import '../../../assets/sass/global';

.seity-indicator-container {
    margin-top: rem(30);
    height: 10px;
    flex-direction: row;
    justify-content: center;
    display: inline-flex;
    width: 100%;
 }

 .seity-indicator-active {
    height: 3px;
    border-radius: 2px;
    background-color: $dragon-dark-green;
    margin-left: rem(10);
    margin-right: rem(10);
    max-width: rem(100);
    width: 100%;
    @include respond-to(mobile) {
      margin-left: rem(3);
      margin-right: rem(3);
    }
    @include respond-to(tablet) {
      margin-left: rem(6);
      margin-right: rem(6);
    }
 }

  .seity-indicator-inactive {
    height: 3px;
    border-radius: 2px;
    background-color: $seity-light-grey;
    margin-left: rem(10);
    margin-right: rem(10);
    max-width: rem(100);
    width: 100%;
    @include respond-to(mobile) {
      margin-left: rem(3);
      margin-right: rem(3);
    }
    @include respond-to(tablet) {
      margin-left: rem(6);
      margin-right: rem(6);
    }
 }
