@import '../../../assets/sass/global';

.seity-primary-cancel-nav-container {
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin: 16px 0 0 0;
}
.seity-primary-cancel-nav-container-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: rem(16);
  @include respond-to(mobile) {
    padding: 0 rem(40);
  }
}
.seity-primary-cancel-nav-container-error {
  text-align: center;
  color: red;
  height: 3.5em;
}

.seity-primary-cancel-nav-container .btn {
  margin-right: 5px !important;
  margin-left: 5px !important;
  margin-top: 10px !important;
}
