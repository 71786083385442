@import "../../../assets/sass/global";

.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2f696c;
  border-radius: 50%;
  cursor: pointer;

  p {
    color: $seity-white;
    font-size: 1.3125rem;
    font-family: $font-source-sans;
    font-weight: 600;
    line-height: 1.75rem;
    margin: 0;
    z-index: 2;
    text-transform: uppercase;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}
