.dragon-item-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-top: 20px;
}

.dragon-item-image {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  margin-right: 16px;
  object-fit: cover;
}

.dragon-item-text-container {
  flex: 1;
}

.dragon-item-header {
  font-size: 18px;
  font-weight: 600;
  color: #343B43;
  margin: 0 0 5px 0;
}

.dragon-item-text {
  font-size: 14px;
  font-weight: 400;
  color: #343B43;
  line-height: 20px;
  margin: 0;
}
