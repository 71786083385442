@import "../../../assets/sass/global";

.choose-affirmation {
  @include main-width;
  padding: rem(16) rem(24);
  @include respond-to(tablet) {
    padding: rem(32) rem(45);
  }
  @include respond-to(desktop) {
    padding: rem(32) rem(97);
  }
  .ca-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
    span {
      font: 600 rem(16) $font-source-sans;
      color: #939393;
      line-height: 1.14;
      height: rem(20);
    }
    .more-info {
      height: 1.5rem;
      width: 1.5rem;
      cursor: pointer;
    }
  }
  > h3 {
    font: 600 rem(42) $font-source-serif;
    color: #202223;
  }
  .ca-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: rem(22) rem(20);
    @include respond-to(desktop) {
      grid-gap: rem(24) rem(22);
    }
    .affirmation-item {
      background-color: white;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
      padding: 15px;
      border-radius: 20px;
      box-shadow: 1px 1px 30px 0 rgba(0, 0, 0, 0.1);
      cursor: pointer;
      > h3 {
        margin-top: rem(46);
        font: 600 rem(26) $font-source-sans;
        color: #939393;
        @include respond-to(mobile) {
          font-size: rem(21);
        }
      }
      &.selected {
        background-color: $seity-orange;
        > h3 {
          color: #fff;
        }
      }
    }
  }
  > button {
    margin-top: rem(59);
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    @include respond-to(desktop) {
      width: rem(568);
    }
  }
}
