@import "../../../assets/sass/global";

.contact-list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 9.375rem;

  &-char-group {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;

    > h2 {
      font: normal rem(18) $font-source-sans;
      color: $seity-dark-grey;
      margin: 0;
    }
  }

  .selected-account {
    background: #bbdadb80;
    padding: 0 1rem;
  }

  .unselected-account {
    padding: 0 1rem;
  }
}
