@import "../../../assets/sass/global";

.fsFamilyName {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 19;
  background-color: $seity-white;
  padding: rem(76) rem(24) rem(48) rem(24);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include respond-to(tablet) {
    padding: rem(76) rem(72) rem(48) rem(72);
  }
  @include respond-to(desktop) {
    padding: rem(76) rem(208) rem(48) rem(208);
  }
  > h1 {
    margin-top: rem(16);
    font: 600 rem(26) $font-source-serif;
    color: black;
    text-align: left;
    @include respond-to(tablet) {
      font-size: rem(27);
    }
    @include respond-to(desktop) {
      font-size: rem(42);
    }
  }
  > input {
    background: none;
    border: none;
    outline: none;
    border-bottom: rem(3) solid #4F7E81;
    width: 90%;
    font: 600 rem(27) $font-source-serif;
    color: black;
    margin-top: rem(112);
    @include respond-to(desktop) {
      font-size: rem(42);
    }
  }
  .close-button {
    position: absolute;
    top: rem(42);
    right: rem(72);
  }
  &-continue {
    width: 100%;
    margin-top: auto;
  }
}
