@import '../../../assets/sass/global';

.ev {
  &-container {
    display: inline-block;
    align-items: center;
    justify-content: center;
    margin-top: rem(50);
    &.showNav {
      margin-top: 0;
    }
  }
}
