@import '../../../../../assets/sass/global';

.ts-detail {
  width: 100%;
  > img {
    width: 100%;
    object-fit: cover;
    @include respond-to(desktop) {
      position: relative;
    }
    @include respond-to(large-desktop) {
      position: relative;
    }
  }
  &__content {
    padding: rem(52) rem(24);
    > h1 {
      font: 600 rem(27) $font-source-serif;
      color: $seity-black;
      text-align: left;
      @include respond-to(desktop) {
        font-size: rem(42);
      }
    }
    > button {
      width: rem(342);
      @include respond-to(mobile) {
        width: 100%;
      }
    }
    > h3 {
      font: 600 rem(26) $font-source-sans;
      color: $seity-black;
    }
  }
}