@import "../../../assets/sass/global";

.seity-hyper-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: rem(18);
  line-height: rem(20);
  color: $seity-world;
  font-weight: bold;
  font-family: $font-source-sans-bold;
}

.seity-hyper-text-container-discard {
  cursor: pointer;
  font-size: rem(18);
  line-height: rem(20);
  color: $seity-warning;
  font-weight: bold;
  font-family: $font-source-sans-bold;
}

.seity-hyper-text-container-underline {
  @extend .seity-hyper-text-container;
  display: block;
  text-decoration: underline;
  cursor: pointer;
}
