@import '../../../assets/sass/global';

.seity-google-login-failed-alert-modal {
  border-radius: rem(8) !important;
  background-color: white !important;
  max-width: rem(568);
  max-height: rem(500) !important;
  width: 80%;
  border-bottom: 5px solid $seity-red-orange !important;

  h3 {
    font-family: $font-source-sans;
    font-size: rem(28);
    font-weight: 600;
  }

  .seity-google-login-failed-alert-icon {
    width: 22px;
    height: 22px;
  }

  .seity-google-login-failed-alert-close-icon {
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
    cursor: pointer;
  }

  .seity-google-login-failed-alert-support-text {
    color: $seity-teal-medium;
  }
}



