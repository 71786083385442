@import "../../../assets/sass/global";

.seity-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: rem(8);
  padding: rem(32);
  max-height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: rem(309);
  min-height: rem(200);
  max-width: 50%;
  z-index: 20;
  > h2 {
    color: $seity-black;
    font: 600 rem(24) $font-source-serif;
    width: 100%;
  }
  > p {
    color: $seity-black;
    font: 400 rem(16) $font-source-sans;
    width: 100%;
    margin-bottom: rem(24) !important;
  }
  &__imgCard {
    object-fit: contain;
  }
  &__btnWrap {
    width: 100%;
    button {
      width: 100%;
      height: rem(48);
      margin-bottom: rem(24);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @include respond-to(mobile) {
    max-width: 100%;
  }
}

.seity-modal:focus {
  outline: none;
  border: none;
}

.seity-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(52, 59, 67, 0.8);
  z-index: 20;
}
