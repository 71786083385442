@import "../../../assets/sass/global";

.seity-input {
  width: 100%;
  height: 100%;
  border-radius: rem(8);
  background-color: white;
  border: rem(1) solid $seity-middle-grey;
  padding: 0 rem(14);
  outline: none;
  font: rem(18) $font-source-sans;
  &:focus {
    border-color: $seity-blue;
    border-width: rem(2);
  }
  &-wrapper {
    position: relative;
    width: 100%;
    height: rem(48);
  }
  &-padding-left {
    padding-left: rem(48);
  }
  &-padding-right {
    padding-right: rem(45);
  }
  &-left-icon {
    position: absolute;
    width: rem(24);
    height: rem(24);
    left: rem(10);
    bottom: rem(12);
  }
  &-right-icon {
    position: absolute;
    width: rem(20);
    right: rem(15);
    bottom: rem(16);
    &-close {
      width: rem(22);
      bottom: rem(14);
    }
  }
  &-error {
    border: rem(1.5) solid $seity-orange !important;
  }
  &::placeholder {
    color: $seity-middle-grey;
  }
}
.seity-input-with-label {
  margin: rem(10) 0 rem(30) 0;
  label {
    font: rem(18) $font-source-sans;
    color: $seity-dark-green;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font: rem(18) $font-source-sans;
      color: red;
      font-style: italic;
    }
  }
}
