@import "../../../assets/sass/global";

.fsi-modal-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24;
  overflow-y: auto;
  align-items: flex-start;
  text-align: left;

  > h2 {
    color: $seity-black;
    font: 600 rem(24) $font-source-serif;
    width: 100%;
    text-align: center;
  }

  @include respond-to(tablet) {
    max-width: rem(701);
  }

  @include respond-to(desktop) {
    max-width: rem(750);
  }

  @include respond-to(large-desktop) {
    max-width: rem(782);
  }

  .fsi-modal-header {
    font: rem(24) $font-source-serif !important;
    color: $seity-black !important;
    font-weight: 600 !important;
    margin: 0 0 0 0;
    align-self: center;
  }

  .fsi-modal-policy-container {
    margin-top: rem(24);
    height: fit-content;
    min-height: rem(200);
    overflow-y: auto;
  }

  .fsi-modal-button-container {
    width: 100%;
    margin-block: rem(32);
  }

  .fsi-modal-button {
    margin-top: rem(24);
    width: 100%;
  }

  .fsi-modal-overlay {
    text-align: center;
    h2 {
      margin-top: rem(-25);
    }
  }
}
