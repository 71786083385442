/*
RESPONSIVE BREAKPOINTS
*/
$breakpoints: (
  'small-mobile': (
    max-width: 400px,
  ),
  'mobile': (
    max-width: 768px,
  ),
  'landscape': (
    orientation: landscape,
  ),
  'tablet': (
    min-width: 768px,
  ),
  'desktop': (
    min-width: 1152px,
  ),
  'large-desktop': (
    min-width: 1366px,
  ),
) !default;

@mixin respond-to($breakpoint) {
  @if $breakpoint == 'mobileLandscape' {
    @media (max-device-height: 414px) and (max-device-width: 767px) and (orientation: landscape) {
      @content;
    }
  } @else if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: mobile, tablet, desktop, landscape, mobileLandscape";
  }
}

/*
RESPONSIVE PADDING
*/

// NOTE: for responsive container padding all sides
@mixin padding-responsive(
  $mobile: $rem-mobile-padding,
  $tablet: $rem-tablet-padding,
  $desktop: $rem-desktop-padding
) {
  padding: $mobile;
  @include respond-to(tablet) {
    padding: $tablet;
  }
  @include respond-to(desktop) {
    padding: $desktop;
  }
}

// TODO: create Left/right only padding option
// TODO: create Bottom/Top only padding option
