.progressbar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 20px; // Adds space below the progress bar
  box-sizing: border-box;
}

.progressbar-step {
  flex: 1;
  height: 4px;
  border-radius: 2px;
  margin: 0 4px;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}
