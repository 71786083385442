@import '../../../assets/sass/global';

.seity-alert {
  height: 100%;
  width: 90%;
  overflow: hidden;
  justify-content: space-between;
  &__title {
    font-size: rem(28);
    line-height: rem(32);
    color: $seity-black;
    margin-top: rem(10) !important;
    @include font-base($font-source-serif, bold, 700);
  }
  &__subtitle {
    font-size: rem(20);
    line-height: rem(28);
    text-align: center;
    color: $seity-black;
    word-break: break-word;
    margin: rem(10) rem(20) rem(20) !important;
    white-space: pre-wrap;
    @include font-base($font-source-sans, bold, 500);
  }
  &-button {
    max-width: rem(284);
    width: 100%;
  }
  &-modal {
    background-color: white;
    max-width: rem(568);
    height: rem(324);
    width: 90%;
    // height: 90%;
  }
}
