@import '../../../../assets/sass/global';

.assessment-progressbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  &__backbar {
    height: rem(4);
    width: 100%;
    background-color: $seity-grey;
    border-radius: rem(2);
  }
  .assessment-progressbar__progression {
    height: 100%;
    background-color: $seity-dark-green;
    border-radius: rem(2);
    transition-duration: 0.4s;
  }
  span {
    display: inline-block;
    margin-left: rem(20);
    font-size: 1rem;
    @include font-base($font-source-sans, normal, 400);
  }
}
