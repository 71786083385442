@import '../../../../assets/sass/global';

.touchable-card {
  margin-bottom: 10px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.subcategory-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #FFFFFF;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba($seity-black, 0.1);
  border: 1px solid #C1C7CD;
  position: relative;
  height: 120px;
}

.subcategory-image {
  margin-right: 12px;
}

.subcategory-text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.subcategory-text {
  font-size: 16px;
  font-weight: 600;
  font-family: $font-source-sans;
  color: $seity-black;
  margin-bottom: 4px;
}

.time-estimate-text {
  font-size: 12px;
  font-family: $font-source-sans;
  color: #677280;
}

.svg-placeholder {
  background-color: #E4E4E4;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 20px;
  display: flex;
}

.svg-placeholder-text {
  font-size: 10px;
  color: $seity-dark-grey;
}

.start-now-tag,
.incomplete-tag,
.retake-tag {
  position: absolute;
  bottom: 0;
  right: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 8px 16px;
  border: 1px solid #C1C7CD;
}

.start-now-tag {
  background-color: #18A661;
}

.incomplete-tag {
  background-color: #DE5135;
}

.retake-tag {
  background-color: #FFA500;
}

.start-now-text,
.incomplete-text,
.retake-text {
  font-size: 16px;
  font-family: $font-source-sans;
  color: $seity-white;
  font-weight: 600;
}

.status-icon-container {
  position: absolute;
  top: 20px;
  right: 10px;
}

.status-icon {
  width: 34px;
  height: 34px;
}
