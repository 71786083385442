@import "../../../assets/sass/global";

.core-value-history {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 20px;
  }

  &__title {
    flex: 1;
    text-align: center;
    font-family: 'Source Serif Pro', serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #343B43;
    margin: 0;
  }

  &__back-button {
    flex: 0;
    img {
      width: 2rem;
    }
  }

  &__main {
    padding: 20px;
  }

  &__group {
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }

  &__date {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 21px;
    line-height: 28px;
    color: #343B43;
    margin-bottom: 10px;
  }

  &__images {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  &__descriptions {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
  }

  &__description {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15.08px;
    color: #677280;
  }

  &__error {
    color: red;
    text-align: center;
    margin-top: 20px;
  }
}
