@import "../../../assets/sass/global";

.journals {
  &__header {
    background-color: #fff;
    width: 100%;
    height: rem(172);
    left: 0;
    box-shadow: 0 2px 4px 0 rgba(96, 97, 112, 0.16), 0 0 1px 0 rgba(40, 41, 61, 0.04);
    h3 {
      margin-bottom: 0;
      margin: 0 auto;
      color: $seity-black;
      @include font-base($font-source-serif, normal, 600);
      font-size: rem(42);
    }
    .header-wrap {
      @include main-width;
      .header-back-button img {
        padding: 1rem;
        object-fit: contain;
      }
    }
    .journals-tabmenu {
      max-width: rem(566);
      width: 100%;
      justify-content: space-between;
      button {
        border: none;
        background-color: transparent;
        border-bottom: rem(4) solid transparent;
        width: rem(149);
        height: rem(50);
        @include font-base($font-source-sans, normal, 400);
        font-size: rem(20);
        color: #677280;
      }
      button.active {
        @include font-base($font-source-sans, normal, 600);
        border-bottom: rem(4) solid #2f696c;
        color: #2f696c;
      }
    }
  }

  &__list {
    margin-top: rem(15) !important;
    list-style: none;
    max-width: rem(568);
    li {
      margin-bottom: rem(24);
      overflow: hidden;
      padding: rem(24);
      border-radius: 16px;
      box-shadow: 0 8px 16px 0 rgba(96, 97, 112, 0.16), 0 2px 4px 0 rgba(40, 41, 61, 0.04);
      border: solid 1px var(--gray-2);
      background-color: #fff;
      > img {
        height: rem(256);
        border-radius: rem(16);
        object-fit: cover;
        object-position: top;
      }
      .listitem-top {
        cursor: pointer;
        flex: 1;
        min-width: 300px;
        > span {
          background-color: #f0f0f0;
          padding: rem(8);
          border-radius: rem(10);
          width: rem(54);
          height: rem(61);
          text-align: center;
          @include font-base($font-source-sans, normal, 600);
          font-size: rem(16);
          color: #677280;
        }
        > div {
          overflow: hidden;
          flex: 1;
          .text-title {
            margin: rem(0);
            color: $seity-black;
            @include font-base($font-source-serif, normal, 600);
            font-size: rem(21);
          }
          span {
            color: #677280;
            @include font-base($font-source-serif, normal, 400);
            font-size: rem(13);
          }
        }
      }
      .listitem-bottom {
        .listitem-coreval {
          border-right: rem(1) solid #c1c7cd;
        }
        .listitem-coreval-img {
          margin-left: rem(-8);
          &:first-child {
            margin-left: 0;
          }
        }
        .listitem-indivi {
          span {
            @include font-base($font-source-serif, normal, 600);
            font-size: rem(16);
            color: #677280;
          }
        }
      }
    }
  }
  &__popup {
    position: absolute;
    z-index: 9;
    padding: 0 rem(16);
    border-radius: rem(16);
    box-shadow: 0 16px 24px 0 rgba(96, 97, 112, 0.16), 0 2px 8px 0 rgba(40, 41, 61, 0.04);
    background-color: #fff;
    button {
      @include font-base($font-source-sans, normal, 600);
      font-size: rem(14);
      color: #000;
      padding: rem(15) 0;
      &:first-child {
        color: #f75a3b;
        border-bottom: rem(1) solid #c1c7cd;
      }
    }
  }
}
