.outcome-assessment-incentive-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: Arial, sans-serif;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../../assets/outcome-assessment-incentive-bg.png') no-repeat center center;
  background-size: cover;
  z-index: 1;
}

.main-container {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.text01 {
  font-weight: 600;
  font-size: 48px;
  color: #000;
  text-align: center;
  margin-bottom: 20px;
  white-space: pre-wrap;
  padding: 0 10px;
}

.footer-container {
  margin-bottom: 100px;
  text-align: center;
}

.text02 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  text-align: center;
  margin-bottom: 20px;
  padding: 0 10px;
}

.error-text {
  font-size: 18px;
  font-weight: 600;
  color: red;
  text-align: center;
  margin-top: 50px;
}

/* Button wrapper and styling adjustments */
.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

/* Assuming SeityButton is styled internally, we can still control its container */
.button-wrapper button {
  width: 50%;       /* Make it wider on larger screens */
  max-width: 300px; /* Limit the maximum width */
  margin: 0 auto;   /* Center horizontally */
}

/* On mobile screens, make the button full width */
@media (max-width: 768px) {
  .button-wrapper button {
    width: 100%;
    max-width: none;
  }
}
