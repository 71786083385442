@import '../../../../assets/sass/global';

.register-seven {
  &-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    color: $seity-dark-green;
    padding: rem(15) 0 rem(10) 0;
    h1 {
      font: rem(42) $font-source-serif !important;
      color: $seity-black !important;
      font-weight: 600 !important;
      margin: 0 0 rem(5) 0;
      @include respond-to(mobile) {
        font: rem(35) $font-source-serif;
      }
    }
    h5 {
      font: rem(18) $font-source-sans;
      width: 100%;
      margin: rem(30) 0 rem(20) 0;
      letter-spacing: 1px;
      font-weight: bold;
      @include respond-to(mobile) {
        font: rem(16) $font-source-serif;
        margin: rem(20) 0 rem(10) 0;
      }
    }
    h6 {
      font: rem(16) $font-source-sans;
      line-height: rem(20);
      color: $seity-black;
      width: 100%;
      max-width: rem(326);
      display: inline-block;
      margin-bottom: rem(30);
    }
    form {
      text-align: left;
      width: 95%;
      display: inline-block;
    }
    &-error {
      text-align: center;
      color: $seity-orange;
      height: 2.5em;
      line-height: rem(24);
      font-size: rem(18);
      margin-top: rem(32);
      margin-bottom: rem(32);
      max-width: rem(700);
    }
  }
  &-next-button {
    width: 100%;
    max-width: rem(284);
  }
}

.textbox-form {
  max-width: rem(300);
}
