@import "../../../assets/sass/global";

.plotline {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  align-items: center;
  position: absolute;
  background-color: $seity-white;
  &.bg-10,
  &.bg-7 {
    background-color: white;
  }
  &.fullpage {
    max-height: 100vh;
    height: 100%;
    .plotline-actionBtn-wrap {
      background-color: transparent !important;
      box-shadow: none;
    }
    .plotline-content {
      max-height: 100vh;
      height: 100%;
    }
    .plotline__header {
      background-color: transparent !important;
      &.bottom {
        .plotline-header-body {
          margin-top: auto;
          margin-bottom: rem(156);
        }
      }
    }
    .plotline__body {
      position: absolute;
      height: 100%;
      &-content {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        @include respond-to(mobile) {
          padding: 0;
        }
      }
    }
  }
  &.halfpage {
    .headerBottom > .plotline__header {
      padding-bottom: rem(156);
    }
    .headerTop > .plotline__body {
      padding-bottom: rem(156);
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    &.hasModal {
      padding-top: rem(100);
    }
    &.headerTop {
      .plotline__header {
      }
      .plotline__body {
      }
    }
    &.headerBottom {
      .plotline__header {
        height: 100%;
        background-color: white;
        .plotline__header-content {
          min-height: rem(180);
        }
      }
      .plotline__body {
        // height: auto;
        // when the image template is at the top
        &.template-id-2 {
          max-height: 40%;
          @include respond-to(mobile) {
            max-height: 50%;
          }
        }
      }
    }
  }
  &-actionBtn-wrap {
    width: calc(100% - 30px);
    position: absolute;
    top: calc(100vh - 156px);
    z-index: 10;
    background-color: white;
    height: rem(156);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: -255px -255px 0px -255px rgba(0,0,0,0.45),
    0px -13px 12px -9px rgba(0,0,0,0.1);
    @include respond-to(mobile) {
      width: calc(100% - 16px);
    }
    button {
      width: calc(100% - 48px);
      @include respond-to(tablet) {
        width: rem(570);
      }
      @include respond-to(desktop) {
        width: rem(570);
      }
    }
  }
  
  &__header {
    width: 100%;
    z-index: 9;
    &-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      @include main-width;
      @include respond-to(mobile) {
        padding: 0 rem(24);
      }
    }
  }
  &__body {
    width: 100%;
    &-content {
      height: 100%;
      @include main-width;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include respond-to(mobile) {
        padding: 0 rem(24);
      }
    }
  }
  &__modalTrigger {
    position: absolute;
    top: rem(30);
    left: rem(50);
    height: rem(37);
    z-index: 7;
    font-size: rem(16);
    @include respond-to(mobile) {
      left: rem(20);
    }
  }
}
