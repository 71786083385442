@import '../../assets/sass/global';

.sj-container {
  display: flex;
  justify-content: center;
  margin: 0 rem(20);
  width: 100%;
}

.sj-wrapper-grow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: rem(24);
  h1 {
    margin: rem(56) 0;
    @include font-style(
      rem(42),
      600,
      #000,
      center,
      rem(44),
      $family: $font-source-serif
    );
  }
  div {
    cursor: pointer;
  }
}

.sj-item {
  display: flex;
 
  justify-content: center;
  width: 100%;
  color: $seity-cream;
  max-width: rem(550);
  height: rem(180);
  margin-bottom: rem(24);
  padding: rem(40);
  box-shadow: 0 8px 16px 0 rgba(96, 97, 112, 0.16),
    0 2px 4px 0 rgba(40, 41, 61, 0.04);

  &:last-child {
    margin: 0;
  }
  &-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    h2 {
      @include font-style(
        rem(28),
        600,
        $seity-white,
        left,
        rem(32),
        $family: $font-source-serif
      );
    }
    h4 {
      display: flex;
      justify-content: flex-start;
      @include font-style(
        rem(18),
        normal,
        $seity-white,
        start,
        $family: $font-source-sans
      );}
  }
  &-caret {
   margin: rem(5);
   height: rem(20);
   width: rem(13);
  }
  &-journals {
    background-color: $seity-teal;
    border-radius: 0 rem(40) 0 rem(40);
  }
  &-syncs {
    background-color: $seity-world;
    border-radius: rem(40) 0 rem(40) 0;
  }
  &-story {
    background-color: $seity-teal;
    border-radius: rem(40) 0 rem(40) 0;
  }
  &-learnSeity {
    background-color: $seity-world;
    border-radius: 0 rem(40) 0 rem(40);
  }
}

.sj-content {
  display: flex;
  width: 100%;
  padding: rem(10) 0 0 rem(24);
}
.sj-caret {
  margin-left: rem(30);
}
.grow-icon {
  width: rem(52);
  height: rem(52);
  margin-bottom: rem(30);
}

.seity-syncs-story-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 0;
  background: $seity-white;
  max-width: rem(360);
  max-height: rem(564);
  border-radius: rem(8);
}

.seity-syncs-story-modal {
  padding-top: 0 !important;
  h1 {
    margin-bottom: rem(12);
    @include font-style(
      rem(27),
      600,
      $seity-black,
      center,
      rem(36),
      $family: $font-source-serif
    );
  }

  img {
    height: rem(317);
    max-width: rem(360);
    margin-bottom: rem(20);
  }

  h3 {
    @include font-style(
      rem(16),
      normal,
      $seity-black,
      center,
      rem(16),
      $family: $font-source-sans
    );
  }

  h4 {
    margin: rem(24) 0;
    @include font-style(
      rem(18),
      600,
      $seity-black,
      center,
      rem(24),
      $family: $font-source-sans
    );
  }
}

.seity-syncs-story-button {
  margin: 0 auto;
  background: $seity-light-yellow !important;
  box-shadow: 0 4px 8px 0 rgba(96, 97, 112, 0.16),
    0 0 2px 0 rgba(40, 41, 61, 0.04) !important;
  padding: rem(12) rem(100) !important;
  border-width: rem(0) !important;
  color: $seity-black !important;
}