@import '../../../assets/sass/global';

.checkin-complete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.checkin {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $seity-world;
  width: 100%;
  padding: rem(40);
  border-radius: rem(8);
  &-title {
    & > h1 {
      margin-bottom: rem(24);
      @include font-style(
        rem(27),
        600,
        #ffffff,
        center,
        1.05,
        $family: $font-source-serif
      );
    }
  }
  &-icon {
    width: rem(65);
    height: rem(65);
    z-index: 1000;
    position: absolute;
    top: rem(25);
    left: 50%;
    transform: translateX(-50%);
  }
  &-score {
    margin-top: rem(24);
    & > h3 {
      margin-bottom: 1px;
      @include font-style(
        rem(24),
        bold,
        #ffffff,
        center,
        1.17,
        $family: $font-source-sans
      );
    }
    & > p {
      @include font-style(
        rem(18),
        bold,
        #ffffff,
        center,
        1.33,
        uppercase,
        rem(1),
        $family: $font-source-sans
      );
    }
  }
  &-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: rem(8);
    padding: rem(24) rem(30);
    margin-top: rem(24);
  }
  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & > h1 {
      @include font-style(
        rem(26),
        bold,
        $seity-onyx,
        center,
        rem(32),
        $spacing: normal,
        $family: $font-source-sans
      );
    }
    & > p {
      @include font-style(
        rem(16),
        bold,
        $seity-dark-grey,
        center,
        rem(24),
        $spacing: rem(1),
        $family: $font-source-sans
      );
    }
    &-prizeImg {
      margin: 0 auto;
      margin: rem(16);
      border-radius: rem(10);
      width: rem(91);
      height: rem(68);
      box-shadow: 0 8px 16px 0 rgba(96, 97, 112, 0.16),
        0 2px 4px 0 rgba(40, 41, 61, 0.04);
    }
    & > h3 {
      @include font-style(
        rem(16),
        normal,
        $seity-black,
        center,
        rem(20),
        $family: $font-source-sans
      );
    }
    & > h4 {
      margin: 0;
      @include font-style(
        rem(13),
        bold,
        $seity-dark-grey,
        center,
        1.54,
        $family: $font-source-sans
      );
    }
  }
  &-button {
    margin-top: rem(24);
    padding: rem(16) rem(130) !important;
  }
}

.checkin-progress {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &-icon {
    position: absolute;
    top: 40%;
    z-index: 122;
    width: rem(64);
  }
}

#checkin-progress-bar {
  display: flex;
  justify-content: center;
  width: 100%;

  svg {
    width: rem(180);
    height: rem(100);
    filter: drop-shadow(0px 0px 1px rgba(40, 41, 61, 0.08))
      drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.32));
  }

  path {
    stroke-linecap: round;
    stroke-width: 8;
  }

  path.lightYellow {
    stroke: #fde8b9;
  }

  path.yellow {
    stroke: #fcbd31;
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    transition: 1s ease;
  }
}
