@import '../../../../assets/sass/global';

.outcome-item-container {
  margin-top: rem(16);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.outcome-item-inner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: rem(40);
  width: 100%;
  max-width: rem(360);
  background-color: $seity-world;
  border-bottom-left-radius: rem(20);
  border-top-right-radius: rem(20);
  margin-bottom: rem(16);
}

.outcome-item-text {
  @include font-style(
    rem(18),
    400,
    $seity-white,
    left,
    rem(24),
    $family: $font-source-sans
  );
}

.outcome-first-item-text {
  margin-left: rem(20) !important;
  font-weight: 400;
}

.outcome-item-text-bold {
  font-weight: 700;
  margin-left: rem(3) !important;
  margin-right: rem(3) !important;
}
