@mixin header-height {
  height: $rem-mobile-header-height;
  @include respond-to(tablet) {
    height: $rem-desktop-header-height;
  }
  @include respond-to(desktop) {
    height: $rem-desktop-header-height;
  }
}

@mixin main-margin-top {
  margin-top: $rem-mobile-header-height;
  @include respond-to(tablet) {
    margin-top: $rem-desktop-header-height;
  }
  @include respond-to(desktop) {
    margin-top: $rem-desktop-header-height;
  }
}

@mixin main-padding-top {
  padding-top: $rem-mobile-header-height;
  @include respond-to(tablet) {
    padding-top: $rem-desktop-header-height;
  }
  @include respond-to(desktop) {
    padding-top: $rem-desktop-header-height;
  }
}

@mixin top-position {
    top: -$rem-mobile-header-height;
    @include respond-to(tablet) {
        top: -$rem-desktop-header-height;
    }
    @include respond-to(desktop) {
        top: -$rem-desktop-header-height;
    }
}

@mixin main-width {
  width: 100%;
  @include respond-to(tablet) {
    width: rem(768);
  }
  @include respond-to(desktop) {
    width: rem(1158);
  }
}

@mixin main-padding {
  padding: rem(16) rem(24);
  @include respond-to(tablet) {
    padding: rem(32) rem(45);
  }
  @include respond-to(desktop) {
    padding: rem(32) rem(97);
  }
}

@mixin main-full-width {
  width: 100%;
  padding: 0 rem(20);
  @include respond-to(tablet) {
    width: rem(768);
  }
  @include respond-to(desktop) {
    width: rem(1158);
  }
  @include respond-to(large-desktop) {
    width: 100%;
    padding: 0 rem(104);
  }
}

@mixin main-medium-width {
  width: 100%;
  padding: 0 rem(20);
  @include respond-to(tablet) {
    width: rem(600); // Adjust the width as needed
  }
  @include respond-to(desktop) {
    width: rem(900); // Adjust the width as needed
  }
  @include respond-to(large-desktop) {
    width: rem(1024); // Adjust the width as needed
    padding: 0 rem(104);
  }
}

@mixin main-small-width {
  width: 100%;
  padding: 0 rem(20);
  @include respond-to(tablet) {
    width: rem(480); // Adjust the width as needed
  }
  @include respond-to(desktop) {
    width: rem(720); // Adjust the width as needed
  }
  @include respond-to(large-desktop) {
    width: rem(840); // Adjust the width as needed
    padding: 0 rem(104);
  }
}


@mixin main-min-height {
  min-height: calc(100vh - 4rem);
  @include respond-to(tablet) {
    min-height: calc(100vh - 4.6875rem);
  }
  @include respond-to(desktop) {
    min-height: calc(100vh - 4.6875rem);
  }
}

@mixin main-max-height {
  max-height: calc(100vh - 4rem);
  @include respond-to(tablet) {
    max-height: calc(100vh - 4.6875rem);
  }
  @include respond-to(desktop) {
    max-height: calc(100vh - 4.6875rem);
  }
}
