@import '../../../assets/sass/global';

.back-button-container {
  position: fixed;
  top: rem(20);
  left: rem(40);
  z-index: 10;
  
  :hover {
    cursor: pointer;
  }
  &-header {
    top: $rem-desktop-header-height;
    @include respond-to(mobile) {
      left: rem(20);
    }
  }
  img {
    width: rem(32);
  }
}
