@import '../../../assets/sass/global';

.intentions-delete-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: rem(8);
  padding: rem(32) rem(40);
  max-height: 85%;
  display: flex;
  flex-direction: column;
  min-width: rem(504);
  min-height: rem(215);
  @include respond-to(mobile) {
    min-width: rem(340);
  }
  h3 {
    @include font-base($font-source-serif, normal, 600);
    font-size: rem(28);
  }
  p {
    margin-top: rem(5) !important;
    @include font-base($font-source-sans, normal, 400);
    font-size: rem(14);
  }
}

.intentions-delete-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 20;
  button {
    @include font-base($font-source-sans, normal, 600);
    font-size: rem(18);
    padding: rem(10) rem(32);
    color: black;
    border-radius: rem(8);
    &:last-child {
      background-color: $seity-teal;
      color: white;
    }
  }
}
