@import '../../../assets/sass/global';

.seity-mi-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: rem(8);
  max-height: 85%;
  display: flex;
  flex-direction: column;
  width: rem(469);
  height: rem(609);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  > div:first-child {
    padding: 0 rem(16);
    margin: rem(20.5) 0;
    > span {
      @include font-base($font-source-serif, normal, 600);
      color: $seity-black;
      font-size: rem(16);
    }
  }
  > *:not(:first-child) {
    padding: 0 rem(16);
    height: rem(74);
    transition: height 0.15s ease-out;
    border-top: rem(1) solid $seity-grey;
    > button {
      margin: rem(25) 0;
    }
    h3 {
      margin: 0 !important;
      margin-right: auto !important;
      color: $seity-black;
      @include font-base($font-source-serif, normal, 600);
      font-size: rem(18);
    }
    p {
      margin-top: rem(5) !important;
      @include font-base($font-source-sans, normal, 400);
      font-size: rem(16);
      color: $seity-black;
    }
    img {
      transition: 0.70s;
      -webkit-transition: 0.70s;
      -moz-transition: 0.70s;
      -ms-transition: 0.70s;
      -o-transition: 0.70s;
    }
    img.open {
      transition: 0.70s;
      -webkit-transition: 0.70s;
      -moz-transition: 0.70s;
      -ms-transition: 0.70s;
      -o-transition: 0.70s;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  > div:nth-child(2).open {
    height: rem(242);
    transition: height 0.23s ease-in;
    > div {
      span {
        color: $journal-gray;
        @include font-base($font-source-sans, normal, 600);
        font-size: rem(16);
      }
    }
  }
  > div:nth-child(3).open {
    height: rem(469);
    transition: height 0.23s ease-in;
    > div * {
      position: relative;
      span {
        color: $seity-black;
        @include font-base($font-source-sans, normal, 600);
        font-size: rem(16);
        text-transform: capitalize;
        position: absolute;
        top: rem(10);
      }
    }
  }
  > div:nth-child(4).open {
    height: rem(610);
    transition: height 0.23s ease-in;
  }
  > div:nth-child(4) {
    > div * {
      background-color: #f0f0f0;
      span {
        color: $seity-black;
        @include font-base($font-source-sans, normal, 600);
        font-size: rem(16);
        text-transform: capitalize;
      }
    }
  }
}

.seity-mi-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 20;
}

// @-moz-keyframes spin { 100% { -moz-transform: rotate(180deg); } }
// @-webkit-keyframes spin { 100% { -webkit-transform: rotate(180deg); } }
// @keyframes spin { 100% { -webkit-transform: rotate(180deg); transform:rotate(180deg); } }
