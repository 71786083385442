@import '../../../../../assets/sass/global';

.ts-asset-item {
  color: $seity-cream;
  margin-right: rem(10);
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0px 0px;
  cursor: pointer;
  width: rem(176);
  @include respond-to(mobile) {
    width: rem(153);
  }
  &-image {
    width: rem(176);
    height: rem(176);
    border-radius: 20px;
    background-size: cover;
    object-fit: cover;
    @include respond-to(mobile) {
      width: rem(153);
      height: rem(148);
    }
  }
  &-image:hover {
    -webkit-filter: brightness(60%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

  &-bottom {
    opacity: 0.9;
    color: $seity-dark-green;
    padding: rem(5) rem(10);

    h2 {
      font: rem(18) $font-source-serif;
      font-weight: bold;
      margin: rem(2) !important;
      margin-left: 0 !important;
    }

    h3 {
      font: rem(16) $font-source-serif;
    }

    p {
      font-family: $font-source-sans;
    }

    p img {
      margin: 0 rem(20) rem(5) 0;
    }
  }

  &-lower {
    display: flex;
    justify-content: flex-start;
    width: rem(195);
    font: rem(17) $font-source-sans;
    margin: rem(10) 0 0 rem(35);
  }
}