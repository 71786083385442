@import '../../assets/sass/global';

.survey-wrap-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: rem(16);
  margin-top: rem(16);
  width: 100%;

  border-top: 1px solid $seity-gallery;
  &-button {
    min-width: rem(120);
    color: $seity-world;
    display: flex;
    align-self: flex-start;
  }
  &-wrapper {
    width: 100%;
    padding-left: rem(16);
    padding-right: rem(16);
  }
  h1 {
    color: $seity-dark-green;
    text-align: center;
    @include font-base($font-source-serif, normal, 400);
    font-size: rem(40);
  }
  h2 {
    @include font-base($font-source-serif, normal, 400);
    color: $seity-dark-green;
    font-size: rem(30);
  }
  p {
    color: $seity-dark-green;
    text-align: center;
    @include font-base($font-source-sans, normal, 400);
    font-size: rem(18);
  }
}

.seity-button-survey {
  max-width: rem(284);
  width: 100%;
}
.practices-progressbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: rem(350);
  &__backbar {
    height: rem(4);
    width: 90%;
    background-color: $seity-grey;
    border-radius: rem(2);
  }
  .practices-progressbar__progression {
    height: 100%;
    background-color: $seity-dark-green;
    border-radius: rem(2);
    transition-duration: 0.4s;
  }
  span {
    display: inline-block;
    margin-left: auto;
    font-size: 1rem;
    @include font-base($font-source-sans, normal, 400);
  }
}
.practices-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  width: 100%;
  &__options {
    display: flex;
    flex-direction: row;
  }
}
.explore-text {
  font-size: rem(24);
  color: $seity-dark-green;
  @include font-base($font-source-sans, normal, 400);
}
