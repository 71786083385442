@import '../../../assets/sass/global';

.seity-permissions-modal {
  width: rem(500);
  height: rem(300);
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: rem(8);
  padding: 0;
  max-height: 85%;
  display: flex;
  flex-direction: column;
  @include respond-to(mobile) {
    width: 90%;
  }
  h3 {
    @include font-base($font-source-serif, normal, 600);
    font-size: rem(28);
    text-align: center;
  }
  h4 {
    font: 400 rem(16) $font-source-sans;
    color: $seity-dark-grey;
  }
  p {
    margin-top: rem(5) !important;
    @include font-base($font-source-serif, normal, 600);
    line-height: rem(27);
    color: $seity-black;
    text-align: center;
  }

}

.seity-permissions-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 102;
}

.permission-modal-link {
  color: $seity-teal-medium;
  cursor: pointer;
  text-decoration: underline;
}

.permission-modal-body {
  max-width: 90%;
}
