@import "../../../assets/sass/global";

.help-recources-container {
  display: flex;
  flex-direction: column;
  @include main-width;
  align-items: center;
  padding: 0 rem(20) rem(40);
}

.help-recources-title {
  font-family: $font-source-serif;
  font-size: 64px;
  color: $seity-dark-green;
  font-style: normal;
  font-weight: 400;
  margin-top: rem(40);
  margin-bottom: rem(5);
}
