@import '../../../../../assets/sass/global';

.ce {
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $seity-black;
    margin: 0 0 rem(10) 0;
  }
  &-title {
    font: rem(42) / rem(44) $font-source-serif-semi-bold;
    margin-bottom: clamp(10px, 6vh, 40px);
    @include respond-to(mobile) {
      font-size: rem(40);
      margin-bottom: rem(10);
    }
  }
  &-graphic {
    margin-bottom: rem(24);
    @include respond-to(mobile) {
      width: rem(120);
      margin-bottom: rem(10);
    }
  }
  &-description {
    font: rem(20) $font-source-sans;
    margin-bottom: rem(16);
    @include respond-to(mobile) {
      text-align: center;
      margin-bottom: rem(10);
    }
  }
  &-codeInput {
    border-radius: rem(10);
    border: rem(1) solid $seity-grey;
    font: rem(18) $font-source-sans;
    padding-left: rem(45);

    &:focus {
      box-shadow: none;
    }
  }
  &-codeInputContainer {
    width: rem(310);
    height: rem(56);
  }
  &-codeInputIcon {
    height: rem(22);
    bottom: rem(16);
    left: rem(15);
  }
  &-verify {
    margin-bottom: rem(20);
    @include respond-to(mobile) {
      margin-bottom: rem(10);
    }
  }
  &-error {
    font: rem(18) / rem(24) $font-source-sans;
    color: $seity-orange;
    height: rem(40);
    margin-top: clamp(10px, 1vh, 80px);
    @include respond-to(mobile) {
      height: rem(30);
      margin-top: 0;
    }
  }
}
