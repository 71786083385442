.outcome-assessment-outro-container {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 20px;
  box-sizing: border-box;
}

.loadingContainer {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}

.errorText {
  color: red;
  font-size: 16px;
  text-align: center;
  margin: 10px 0;
}

.outro-content-container {
  padding-bottom: 20px;
}

.outro-heading-container {
  margin-bottom: 10px;
}

.outro-category-heading {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #539fa3;
  margin-bottom: 4px;
}

.outro-section-name {
  font-size: 27px;
  font-weight: 600;
  line-height: 36px;
  color: #202223;
  margin: 0;
}

.outro-header-image-placeholder {
  width: 100%;
  border-radius: 12px;
  margin-bottom: 20px;
  background: #f0f0f0;
}

.outro-header-image {
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
  margin-bottom: 20px;
  transition: opacity 0.3s ease;
}

.outro-image-invisible {
  opacity: 0;
  position: absolute;
  left: -9999px;
}

/* Results Container Styles */
.results-container {
  margin-bottom: 20px;
}

.score-text {
  font-size: 20px;
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
  color: #202223;
  margin-bottom: 10px;
}

.results-text {
  font-size: 18px;
  font-weight: 400;
  font-family: "Source Sans Pro", sans-serif;
  color: #343B43;
  line-height: 24px;
  white-space: pre-wrap; /* To honor new lines from the replace */
}

.outro-footer-container {
  position: sticky;
  bottom: 0;
  padding-top: 20px;
}

.outro-footer-container button {
  display: block;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .outro-footer-container button {
    width: 100%;
    max-width: none;
  }
}
