@import '../../../../assets/sass/global';

.bubble-list-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.bubble-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.modal-core-value-4-selected {
  border-radius: rem(8) !important;
  background-color: white;
  max-width: rem(568);
  max-height: rem(160);
  width: 80%;
  height: 100%;
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    align-items: center;
    text-align: center;
    padding-left: rem(30);
    padding-right: rem(30);
    font-family: $font-source-sans;
    font-size: 20px;
    line-height: 1.4;
  }
}
.bubble-list-wrapper-grid4 {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, rem(210));
  @include respond-to(small-mobile) {
    grid-template-columns: repeat(2, rem(180));
  }
  @include respond-to(tablet) {
    grid-template-columns: repeat(2, rem(250));
  }
  @include respond-to(desktop) {
    grid-template-columns: repeat(4, rem(250));
  }
}
.bubble-list-wrapper-grid6 {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, rem(210));
  @include respond-to(small-mobile) {
    grid-template-columns: repeat(2, rem(180));
  }
  @include respond-to(tablet) {
    grid-template-columns: repeat(2, rem(250));
  }
  @include respond-to(desktop) {
    grid-template-columns: repeat(5, rem(230));
  }
}

.bubble-content-button {
  width: 100%;
  max-width: rem(284);
  margin-top: rem(30) !important;
}