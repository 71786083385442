@import '../../../assets/sass/global';

.loader-container {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.loader-mask {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100%;
  height: 100%;
}
.loader-content {
  width: rem(52);
  height: rem(52);
  z-index: 1;
}

.loader-content-hidden {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $seity-white;
}
