@import "../../../../assets/sass/global";

.detail-wrapper {
  width: 100%;
}

.detail-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
}

.resource-detail-text {
  font-family: $font-source-sans;
  font-style: normal;
  font-weight: bold;
  font-size: rem(42);
  text-align: center;
  color: $seity-black;
  overflow-wrap: break-word;
  @include respond-to(mobile) {
    font-size: rem(30);
  }
  &.link {
    display: block;
    text-decoration: underline;
    cursor: pointer;
  }
}

.emergency-detail-text {
  font-family: $font-source-sans;
  font-style: normal;
  font-weight: bold;
  font-size: rem(42);
  text-align: center;
  color: $seity-black;
  @include respond-to(mobile) {
    font-size: rem(30);
  }
}

.resource-icon {
  width: rem(72);
  height: rem(72);

}

.image-container {
 margin: rem(15) 0;
}

.detail-container {
  width: 80%;
  padding-left: rem(4);
}

.detail-footer {
  text-align: center;
  font-family: $font-source-sans;
  font-style: normal;
  font-size: rem(20);
  color: $seity-dark-green;
  margin-bottom: rem(20);
}

.detail-header {
  text-align: center;
  font-family: $font-source-sans;
  font-style: normal;
  font-size: rem(20);
  color: $seity-dark-green;
  margin-top: rem(10);
}