@import '../../assets/sass/global';

.mystory-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &-wrapper {
    display: flex;
    flex-direction: column;
    width: rem(766);
    max-width: rem(1200);
    padding: rem(40) 0;
    @include respond-to(mobile) {
      width: 100%;
    }
    &-info {
      display: flex;
      margin-bottom: rem(50);
      width: 100%;
    }
  }
}
