// TODO: Need to import styleguide (bootstrap) once we confirm that
// @import '';

// Variables
@import 'variables';

// Utility
@import 'mixins/typography';
@import 'mixins/layout';

// Fonts
@import 'fonts';

// Colors
@import 'colors';

// Common Mixins
@import 'mixins/common';

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: underline; }
a:active { text-decoration: none; }

.mb-fs-layout {
  @include respond-to(mobile) {
    position: absolute;
    top: 0;
    margin: 0 !important;
    background-color: $seity-white;
    z-index: 9;
  }
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
}

// Custom Styling
.seity-hover-button {
  background-color: $seity-teal;
  color: $seity-cream;
  padding: rem(13) rem(33);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: rem(20);
  font-family: $font-source-sans;
  cursor: pointer;
  transition-duration: 0.4s;
  border: rem(2) solid $seity-teal;
  border-radius: rem(36);
  line-height: rem(22);
  &:hover {
    background-color: $seity-cream;
    color: $seity-teal;
    border: rem(2) solid $seity-teal;
    text-decoration: none;
  }
}

.intention-button {
  background-color: $seity-teal-medium;
  &:hover {
    opacity: 0.6;
  }
  &:active {
    opacity: 1;
    background-color: $seity-world;
  }
}

.intention-ancho {
  &:hover {
    opacity: 0.6;
  }
  &:active {
    opacity: 1;
    color: $seity-world;
  }
}

.border-button {
  background-color: $seity-cream;
  color: $seity-teal;
  border: rem(2) solid $seity-teal;
  &:hover {
    background-color: $seity-teal;
    color: $seity-cream;
  }
}
/* border radius */
.rounded-none {
  border-radius: 0px;
}
.rounded-sm {
  border-radius: 0.125rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-lg-2 {
  border-radius: 0.625rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-2xl {
  border-radius: 1rem;
}
.rounded-3xl {
  border-radius: 1.5rem;
}
.rounded-full {
  border-radius: 9999px;
}

/* margins */
.m-sm {
  margin: 0.25rem;
}
.m-md {
  margin: 0.5rem;
}
.m-lg {
  margin: 1rem;
}
.m-xl {
  margin: 1.5rem;
}
.m-xxl {
  margin: 2rem;
}
.ml-sm {
  margin-left: 0.25rem;
}
.ml-md {
  margin-left: 0.5rem;
}
.ml-lg {
  margin-left: 1rem;
}
.ml-xl {
  margin-left: 1.5rem;
}
.ml-xxl {
  margin-left: 2rem;
}
.mr-sm {
  margin-right: 0.25rem;
}
.mr-md {
  margin-right: 0.5rem;
}
.mr-lg {
  margin-right: 1rem;
}
.mr-xl {
  margin-right: 1.5rem;
}
.mr-xxl {
  margin-right: 2rem;
}
.mlr-sm {
  margin: 0 0.25rem;
}
.mlr-md {
  margin: 0 0.5rem;
}
.mlr-lg {
  margin: 0 1rem;
}
.mlr-xl {
  margin: 0 1.5rem;
}
.mlr-xxl {
  margin: 0 2rem;
}
.mt-sm {
  margin-top: 0.25rem;
}
.mt-md {
  margin-top: 0.5rem;
}
.mt-lg {
  margin-top: 1rem;
}
.mt-xl {
  margin-top: 1.5rem;
}
.mt-xxl {
  margin-top: 2rem;
}
.mb-sm {
  margin-bottom: 0.25rem;
}
.mb-md {
  margin-bottom: 0.5rem;
}
.mb-lg {
  margin-bottom: 1rem;
}
.mb-xl {
  margin-bottom: 1.5rem;
}
.mb-xxl {
  margin-bottom: 2rem;
}
.mtb-sm {
  margin: 0.25rem 0;
}
.mtb-md {
  margin: 0.5rem 0;
}
.mtb-lg {
  margin: 1rem 0;
}
.mtb-xl {
  margin: 1.5rem 0;
}
.mtb-xxl {
  margin: 2rem 0;
}
.mr-auto {
  margin-right: auto;
}
.ml-auto {
  margin-left: auto;
}

/* paddings */

.p-sm {
  padding: 0.25rem;
}
.p-md {
  padding: 0.5rem;
}
.p-lg {
  padding: 1rem;
}
.p-xl {
  padding: 1.5rem;
}
.p-xl {
  padding: 2rem;
}
.p-0 {
  padding: 0;
}
.pl-sm {
  padding-left: 0.25rem;
}
.pl-md {
  padding-left: 0.5rem;
}
.pl-lg {
  padding-left: 1rem;
}
.pl-xl {
  padding-left: 1.5rem;
}
.pl-xxl {
  padding-left: 2rem;
}
.pr-sm {
  padding-right: 0.25rem;
}
.pr-md {
  padding-right: 0.5rem;
}
.pr-lg {
  padding-right: 1rem;
}
.pr-xl {
  padding-right: 1.5rem;
}
.pr-xxl {
  padding-right: 2rem;
}
.plr-sm {
  padding: 0 0.25rem;
}
.plr-md {
  padding: 0 0.5rem;
}
.plr-lg {
  padding: 0 1rem;
}
.plr-xl {
  padding: 0 1.5rem;
}
.plr-xxl {
  padding: 0 2rem;
}
.pt-sm {
  padding-top: 0.25rem;
}
.pt-md {
  padding-top: 0.5rem;
}
.pt-lg {
  padding-top: 1rem;
}
.pt-xl {
  padding-top: 1.5rem;
}
.pt-xxl {
  padding-top: 2rem;
}
.pb-sm {
  padding-bottom: 0.25rem;
}
.pb-md {
  padding-bottom: 0.5rem;
}
.pb-lg {
  padding-bottom: 1rem;
}
.pb-xl {
  padding-bottom: 1.5rem;
}
.pb-xxl {
  padding-bottom: 2rem;
}
.ptb-sm {
  padding: 0.25rem 0;
}
.ptb-md {
  padding: 0.5rem 0;
}
.ptb-lg {
  padding: 1rem 0;
}
.ptb-xl {
  padding: 1.5rem 0;
}
.ptb-xxl {
  padding: 2rem 0;
}

/* cursors */
.pointer {
  cursor: pointer;
}
.arrow {
  cursor: default;
}

/* text */
.ta-c {
  text-align: center;
}
.ta-l {
  text-align: left;
}
.ta-r {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

/* flex */

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-ai-c {
  display: flex;
  align-items: center;
}

.flex-jc-sb {
  justify-content: space-between;
}

.flex-ai-fs {
  align-items: flex-start;
}

.flex-jc-fs {
  display: flex;
  justify-content: flex-start;
}

.flex-jc-fe {
  display: flex;
  justify-content: flex-end;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-col-jc-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
// Sizes
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}