@import '../../../../assets/sass/global';

.bubble-wrapper {
  padding: rem(10) rem(12) 0 rem(12);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  @include respond-to(tablet) {
    height: rem(215);
  }
  @include respond-to(desktop) {
    height: rem(215);
  }
  &-not-clicked {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    padding: rem(13) 0;
    h4 {
      margin: auto 0 0 0;
      font-family: $font-source-serif;
      color: $seity-dark-green;
      font-weight: bold;
    }
  }

  &-clicked {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: rem(15) 0 rem(5) 0;
    > div {
      display: flex;
      flex-direction: column;
      flex: 1;
      > p {
        margin: auto 0 !important;
        color: #ffffff;
        font-family: $font-source-sans;
        line-height: 1.3;
        overflow: scroll;
        width: 100%;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    h4 {
      margin-top: auto;
      margin-bottom: 0;
      color: #ffffff;
      font-weight: bold;
      font-family: $font-source-sans;
    }
  }
}
.bubble-content {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: rem(30) 0 rem(30) rem(30);
  padding: 0 rem(12) 0 rem(12);
}