@import "../../../assets/sass/global";

.corevalue-button {
  box-shadow: 0 2px 4px 0 rgba(96, 97, 112, 0.16), 0 0 1px 0 rgba(40, 41, 61, 0.04);
  width: rem(64);
  height: rem(64);
  border-radius: rem(32);
  margin: 0 rem(10%);
  background-color: #adb1b8;
  &.selected {
    background-color: #f0422e;
  }
}

.no-corevalue-text {
  color: $journal-gray;
  @include font-base($font-source-sans, normal, 500);
  font-size: rem(16);
}
