@import "../../../assets/sass/global";

.wellbeing-info-modal-container {
  overflow-y: scroll;

  .close-icon {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    position: absolute;
    right: 2rem;
    top: 2rem;
  }

  .header {
    display: flex;
    gap: 1.5rem;
    padding: 1.5rem 0 2rem 0;
    border-bottom: 1px solid #f0f0f0;

    img {
      height: 3.3rem;
      width: 3.3rem;
    }

    .content {
      h1 {
        font: 600 1.69rem $font-source-sans-pro-regular;
        line-height: 2.25rem;
        text-align: left;
      }

      p {
        font: 400 1rem $font-source-sans;
      }
    }
  }

  .body {
    padding: 2rem 0 1.5rem 0;

    h2 {
      font: 600 1.5rem $font-source-sans-pro-regular;
      line-height: 1.75rem;
    }

    .markers {
      display: flex;
      flex-direction: column;
      gap: 2.25rem;
      margin-top: 2.5rem;

      div {
        display: flex;
        gap: 1.5rem;

        img {
          height: 2.75rem;
          width: 2.75rem;
        }

        div {
          display: block;
          .title {
            font: 600 1.3rem $font-source-sans;
            line-height: 1.5rem;
          }

          .subtitle {
            font: 400 1rem $font-source-sans;
          }
        }
      }
    }
  }

  .close-button {
    width: 100%;
  }
}
