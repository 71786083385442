@import '../../../assets/sass/global';

.coreVals-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: rem(10);
  border-top:  1px solid $seity-gallery;

  h1 {
    color: $seity-dark-green;
    text-align: center;
    @include font-base($font-source-serif-semi-bold, normal, 400);
    font-size: rem(40);
    margin-bottom: rem(10);
  }
  p {
    color: $seity-dark-green;
    text-align: center;
    @include font-base($font-source-sans, normal, 400);
    font-size: rem(18);
    @include respond-to(desktop) {
      width: rem(600);
    }
  }
}
.coreVals-progressbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  &__backbar {
    height: rem(4);
    width: 90%;
    background-color: $seity-grey;
    border-radius: rem(2);
  }
  .coreVals-progressbar__progression {
    height: 100%;
    background-color: $seity-dark-green;
    border-radius: rem(2);
    transition-duration: 0.4s;
  }
  span {
    display: inline-block;
    margin-left: auto;
    font-size: 1rem;
    @include font-base($font-source-sans, normal, 400);
  }
}
.coreVals-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__options {
    display: flex;
    flex-direction: row;
  }
  @include respond-to(mobile) {
    margin-top: rem(5);
  }
}

.core-value-green-title {
  color: $seity-world !important;
}

.assess-core-value-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.core-value-action-button {
  margin-bottom: rem(10);
  width: 100%;
  max-width: rem(284);
}
.core-value-header {
  width: 100%;
  padding: 0 rem(15);
  &-title {
    text-align: center;
    margin-top: rem(16) !important;
    margin-bottom: rem(16) !important;
  }

  &-button {
    min-width: rem(120);
    color: $seity-world;
    display: flex;
    align-self: flex-end;
  }
}
 .hide-block-mobile {
    display: none;
    @include respond-to(desktop) {
      display: inline;
    }
 }

