@import "../../../../assets/sass/global";
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px rgba($seity-cream, 1) inset;
}

.confirmation-container {
  position: fixed;
  align-items: center;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: $seity-world;
  background-image: url("../../../../assets/graphics/web_pattern_login.png");
  background-repeat: repeat-x;
  background-size: rem(1440) clamp(450px, 66vh, 506px);
  overflow: auto;
  @include respond-to(mobile) {
    background-size: 100%;
    padding: clamp(20px, 2vh, 100px) 0 0 0;
  }
  @include respond-to(mobileLandscape) {
    padding: rem(14) rem(44) rem(23);
  }

  .confirmation-bubble {
    &-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: calc(100% - 40px);
      text-align: center;
      padding: rem(40) rem(24) rem(48) rem(24);
      background-color: white;
      box-shadow: rem(4) 0 rem(4) rgba(0, 0, 0, 0.25);
      color: $seity-dark-green;
      max-width: rem(712);
      border-radius: rem(40) rem(40) rem(40) rem(0);
      gap: rem(40);

      @include respond-to(mobileLandscape) {
        width: 100%;
        height: 100%;
        flex-direction: row;
        padding: rem(40) rem(20);
      }
      @include respond-to(tablet) {
        width: rem(712);
        max-width: rem(712);
      }

      .confirmation-inner-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: rem(310);
        text-align: center;
        gap: rem(40);
      }

      .confirmation-content-header {
        font-family: Source Serif Pro;
        font-size: rem(27);
        font-weight: 600;
        line-height: rem(36);
        letter-spacing: 0;
        text-align: center;
      }

      .confirmation-content-text {
        font-family: Source Sans Pro;
        font-size: rem(18);
        font-weight: 400;
        line-height: rem(24);
        letter-spacing: 0;
        text-align: center;
      }
      .confirmation-change-email {
        display: block;
        cursor: pointer;
        color: $seity-slate-grey;
        font-family: Source Sans Pro;
        font-size: rem(16);
        font-weight: 700;
        line-height: rem(24);
        letter-spacing: 0;
        text-align: center;
      }
    }
    &-logo {
      width: rem(180);
      @include respond-to(mobileLandscape) {
        width: rem(68);
        margin: 0;
      }
    }

    &-button {
      display: flex;
      flex-direction: column;
      gap: rem(16);
      justify-content: center;
      align-items: center;

      @include respond-to(mobileLandscape) {
        margin: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 60%;
      }
      &-width {
        margin: 0 auto;
        color: white;
        font: 600 rem(21) $font-source-sans !important;
        width: rem(310);
      }
    }
  }
  .confirmation-footer-wrapper {
    margin-top: rem(24);
    max-width: rem(436);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: rem(8);
    justify-content: space-around;
    align-items: center;
    font-family: Source Sans Pro;
    font-size: rem(14);
    font-weight: 400;
    line-height: rem(22);
    letter-spacing: 0;
    text-align: center;
    color: $seity-white;
    img {
      margin-right: rem(5);
    }
    span {
      color: $seity-white;
      font: rem(13) $font-source-sans;
      font-weight: 600;
    }
    a {
      font-weight: 700;
      line-height: rem(20);
      color: $seity-white;
    }
  }
}
