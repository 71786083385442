@import '../../assets/sass/global';

.sap-wrapper {
  @include main-width;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: rem(55) 0;
}

.sap-title {
  margin-bottom: rem(32);
  @include font-style(
    rem(25),
    600,
    $seity-black,
    center,
    rem(20),
    $family: $font-source-serif
  );
}

.permission-item-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1rem;
  width:90%;
  border-radius: 0.625rem;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.16), 0px 0px 2px 0px rgba(40, 41, 61, 0.04);
  margin-top: 1rem;
  cursor: pointer;

  > div {
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
      font: 600 1.3125rem $font-source-sans;
    }
  }

  > img {
    height: 1.5rem;
    width: auto;
  }
}

.cvPermissionTextContainer {
  width: 70%;
  text-align: left;
  h4 {
    font: 400 rem(20) $font-source-sans;
    color: $seity-dark-grey;
  }
}

.permission-list {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(96, 97, 112, 0.16),
    0 0 2px 0 rgba(40, 41, 61, 0.04);
  margin-top: rem(32);
  padding: 0 rem(32);

  &-item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(24) 0;
    position: relative;
    border-bottom: rem(2) solid $seity-gallery;

    &:last-child {
      border: none;
    }

    > img {
      width: rem(32);
      height: rem(32);
      margin-right: rem(24);
    }

    > h2 {
      font: normal rem(18) $font-source-sans;
      margin: 0;
      align-self: center;
    }
  }

  &-radio {
    margin: 0 !important;
  }
}