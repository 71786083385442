@import '../../../../assets/sass/global';

.register-three {
  &-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: $seity-dark-green;
    padding: rem(10) 0 rem(10) 0;
    h1 {
      font: rem(42) $font-source-serif;
      color: $seity-black !important;
      font-weight: 600 !important;
      margin: 0 0 rem(5) 0;
      @include respond-to(mobile) {
        font: rem(35) $font-source-serif;
      }
    }
    form {
      text-align: left;
      width: 90%;
    }

    p {
      margin-bottom: rem(10) !important;
      color: $seity-dark-green;
    }

    ul {
      padding-inline-start: rem(40) !important;
      color: $seity-dark-green !important;
      font-size: rem(16);
    }

    li {
      margin-bottom: rem(10) !important;
    }

    ol {
      font-size: rem(16);
    }

    section {
      margin: rem(20) auto rem(20) auto;
      padding: rem(15) rem(25) rem(15) rem(30);
      width: 95%;
      overflow-y: scroll;
      max-width: rem(1050);
      height: calc(100vh - 450px);
    //   border: solid rem(1) $seity-dark-green;
    //   border-radius: rem(8);
      font: rem(18) $font-source-sans;

      @include respond-to(mobile) {
        padding: rem(15) rem(5) rem(15) rem(20);
      }

      &::-webkit-scrollbar {
        width: rem(8); /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: $seity-white; /* color of the tracking area */
      }

      &::-webkit-scrollbar-thumb {
        background-color: $seity-grey; /* color of the scroll thumb */
        border-radius: rem(90); /* roundness of the scroll thumb */
        border: rem(1) solid $seity-grey; /* creates padding around scroll thumb */
      }
    }
  }
  &-btns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: rem(284);
    height: rem(120);
    margin: rem(40) 0 rem(30) 0;
    @include respond-to(mobile) {
      margin: rem(20) 0 rem(10) 0;
    }
  }
}