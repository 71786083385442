@import '../../../../assets/sass/global';

.checkin-container {
  display: flex;
  flex-direction: column;
  margin-top: rem(25);
  width: 100%;
  min-width: rem(320);
  align-items: center !important;
  h1 {
    @include font-style(
      rem(42),
      600,
      $seity-black,
      center,
      rem(44),
      $family: $font-source-serif
    );
    @include respond-to(mobile) {
      font-size: rem(35);
    }
  }
}

.outcome-container {
  width: 100%;
  padding-bottom: rem(32);
  @include respond-to(mobile) {
    margin-top: 0;
  }
}

.outcome-buttons-container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  & > button {
    &:first-child {
      border-width: rem(0) !important;
      @include font-style(
        rem(18) !important,
        700!important,
        $seity-world!important,
        center!important,
        $family: $font-source-sans!important
      );
    }
    &:last-child {
      padding: rem(16) rem(122) !important;
      @include font-style(
        rem(21) !important,
        600!important,
        #ffffff!important,
        center!important,
        rem(28) !important,
        $family: $font-source-sans!important
      );
    }
  }
}
.checkin-start-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: rem(20);
  @include respond-to(mobile) {
    margin-top: rem(30);
  }
}

.checkin-submit-button {
  width: 100%;
  max-width: rem(284);
  margin-bottom: rem(20);
  @include respond-to(mobile) {
      margin-top: rem(30);
  }
  &.journal-modal {
      margin-top: rem(20);
  }
}

.check-in-error-content {
  h2 {
    @include font-style(
      rem(21),
      400,
      $seity-black,
      left,
      rem(30),
      $family: $font-source-sans
    );
    text-align: center;
  }
  button {
    width: rem(262);
  }
}

.check-in-error-modal {
  background-color: white;
  width: rem(423);
  height: rem(280);
  border-radius: rem(8);
  box-shadow: 0 20px 32px 0 rgba(96, 97, 112, 0.24), 0 2px 8px 0 rgba(40, 41, 61, 0.08);
}
