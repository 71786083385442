@import "../../../assets/sass/global";

.chart-body {
  .circular-progress {
    position: relative;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    width: calc(100vh * 0.3);
    height: calc(100vh * 0.3);
    max-width: rem(197);
    max-height: rem(197);
    @include respond-to(tablet) {
      width: calc(100vh * 0.3);
      height: calc(100vh * 0.3);
      max-width: rem(219);
      max-height: rem(219);
    }
    @include respond-to(desktop) {
      width: calc(100vh * 0.3);
      height: calc(100vh * 0.3);
      max-width: rem(300);
      max-height: rem(300);
    }

    .circle-inner {
      content: "";
      position: absolute;
      border-radius: 50%;
      background-color: white;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;

      width: calc(100vh * 0.3 - 32px);
      height: calc(100vh * 0.3 - 32px);
      max-width: rem(165);
      max-height: rem(165);
      @include respond-to(tablet) {
        width: calc(100vh * 0.3 - 34px);
        height: calc(100vh * 0.3 - 34px);
        max-width: rem(185);
        max-height: rem(185);
      }
      @include respond-to(desktop) {
        width: calc(100vh * 0.3 - 40px);
        height: calc(100vh * 0.3 - 40px);
        max-width: rem(276);
        max-height: rem(276);
      }
    }

    .content {
      z-index: 2;
      text-align: center;
      font-family: "Source Sans Pro";
      font-style: normal;
      font-weight: 600;
      img {
        height: 4.5rem;
        width: 4.5rem;
      }
      .progress-value {
        font-size: 1.625rem;
        line-height: 2.625rem;
        color: $seity-black;
      }
      p {
        font-size: 1rem;
        line-height: 1.5rem;
        color: #677280;
      }
    }
  }
}
