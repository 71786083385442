@import "../../assets/sass/global";


.rl-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  color: $seity-dark-green;
  padding: rem(20) rem(10) rem(20) rem(10);
  h1 {
    font: rem(36) $font-source-serif;
    margin: rem(21) 0 rem(50) 0;
    @include respond-to(mobile) {
      margin: rem(11) 0 rem(30) 0;
    }
  }
}

.rl-crisis {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  width: 100%;
  max-width: rem(375);
  height: auto;
  background: $seity-light-grey;
  border-radius: rem(40) rem(40) rem(40) rem(40);
  padding: rem(71) rem(35) rem(64) rem(35);
  margin: 0 0 rem(70) 0;
  @include respond-to(mobile) {
    padding: rem(33) rem(34) rem(33) rem(34);
    margin: 0 0 rem(40) 0;
  }
  h2 {
    font: 700 rem(20) $font-source-sans;
    margin: 0 0 rem(25) 0;
  }
}

.rl-tech-support {
  width: 100%;
  max-width: rem(350);
  h3 {
    font: rem(24) $font-source-serif;
  }
  h5 {
    font: rem(18) $font-source-sans;
    margin: 0 0 rem(50) 0;
    @include respond-to(mobile) {
      margin: 0 0 rem(30) 0;
    }
  }
}
