@import "../../../assets/sass/global";

.dragon-detail {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: clamp(65px, 8vh, 80px);
  margin: 10px 0px;
  cursor: pointer;

  @include respond-to(small-mobile) {
    height: rem(65);
  }

  h4 {
    width: 57%;
    font: rem(24) $font-source-serif;
    margin: 0 rem(5) 0 0;
  }

  h5 {
    width: 12%;
    max-width: rem(300);
    font: rem(24) $font-source-sans;
  }

  &-icon {
    width: rem(70);
    height: rem(70);
    margin: 0 rem(16) 0 rem(6);

    @include respond-to(small-mobile) {
      width: rem(60);
      height: rem(60);
    }
  }

  &-caret {
    margin: 0 rem(6) rem(5) rem(20);
  }
}

.dragon-detail-empty {
  margin-top: rem(70);
  font: rem(26) $font-source-serif;
  font-weight: bold;
  text-align: center;
}

.dragons-title-container {
  display: flex;
  flex-direction: column;
  margin-top: rem(20);
  margin-top: rem(24);

  h3 {
    text-align: center;
    border-bottom: 1px solid white;
    width: 100%;
    margin: 0px auto;
    padding-bottom: rem(20);
    margin-bottom: rem(20);
    font: rem(22) $font-source-serif;
    @include respond-to(mobile) {
      width: 95%;
      padding-right: rem(6);
      padding-left: rem(6);
    }
  }
  span {
    margin: 0px auto;
    font: rem(18) $font-source-sans;
  }
}
