@import '../../../../assets/sass/global';

.rls {
  &-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    color: $seity-dark-green;
    padding: rem(15) 0 rem(10) 0;
    h1 {
      font: rem(42) $font-source-serif !important;
      color: $seity-black !important;
      font-weight: 600 !important;
      margin: 0 0 rem(5) 0;
      @include respond-to(mobile) {
        font: rem(35) $font-source-serif !important;
      }
    }

    &-bottom {
      width: 100%;
      margin-top: auto;
      margin-bottom: rem(50);
    }
  }

  &-languages {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: rem(32) 0;
  }

  &-language-item {
    padding: rem(16);
    border: rem(1) solid $seity-dark-grey;
    margin-bottom: rem(24);
    border-radius: rem(10);
    display: flex;
    width: 100%;
    max-width: rem(300);

    &:last-child {
      margin-bottom: 0;
    }

    &-select {
      border: rem(3) solid $seity-green;
      font-weight: bold;
      background-color: rgba($seity-spirit, 0.3);
    }
  }

  &-next-button {
    width: 100%;
    max-width: rem(284);
    margin: 0 auto;
  }
}
