@import '../../assets/sass/global';

.challenge-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: rem(34) 0;

  @include respond-to(desktop) {
    width: 70%;
  }

  h1 {
    font: rem(42) $font-source-serif;
    padding: 0 rem(16);
    margin: 0 0 rem(24) 0;
    font-weight: 600;
    text-align: left;
  }
}

.challenge-upper-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.challenge-image {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1.9;
}

.challenge-lower-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $seity-dark-green;
  padding: rem(24) 0 0 0;

  @include respond-to(mobile) {
    padding: rem(24) rem(16) 0 rem(16);
  }

  h4 {
    font: rem(16) $font-source-sans;
  }
  h5 {
    font: 700 rem(18) $font-source-sans;
    color: $seity-dark-grey;
    margin: 0 0 rem(20) 0;
  }

  &-description {
    font: rem(18) $font-source-sans;
    line-height: rem(27);
    word-wrap: normal;
    white-space: pre;
    width: 100%;
    margin-bottom: rem(10);
  }

  &-button {
    align-self: center;
    justify-self: center;
    width: 100%;
    margin: rem(10) 0 0 0;
  }
}

.challenge-completion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $seity-dark-green;
  width: 100%;

  @include respond-to(mobile) {
    margin: rem(15) 0 rem(15) 0;
  }

  hr {
    width: 100%;
    border: rem(1) solid #e4e4e4;
  }

  h5 {
    font: italic rem(17) $font-source-sans;
  }

  &-button {
    width: 100%;
    align-self: center;
    justify-self: center;
    margin: rem(30) 0 rem(30) 0;
    background-color: $seity-white !important;
    border: rem(1) solid #34a77e !important;
    color: #34a77e !important;

    &:disabled {
      background-color: $seity-white !important;
      border: rem(1) solid #34a77e !important;
      color: #34a77e !important;
      opacity: 0.5;
    }
  }

  &-rating {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    padding: rem(12) 0;
    font: 600 rem(18) $font-source-sans;
  }

  &-wait {
    color: $seity-dark-grey;
    font: rem(18) $font-source-sans;
    border: rem(1) solid $seity-dark-grey;
    border-radius: rem(8);
    padding: rem(16) rem(8);
    text-align: center;
    margin-bottom: rem(20);
  }

  &-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: $seity-white;
    border-radius: rem(8);
    padding: rem(40) rem(124);
    display: flex;
    flex-direction: column;
    align-items: center;
    outline: none;

    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($seity-black, 0.5);
      z-index: 20;
    }
  }
}

.challenge-completion-modal-content {
  width: rem(320);
  text-align: center;

  h1 {
    font: 600 rem(42) $font-source-serif;
    color: $seity-black;
  }

  p {
    font: 400 rem(20) $font-source-sans !important;
    color: $seity-black;
    padding: rem(20) 0 rem(40) 0 !important;
  }

  &-btn {
    width: 80%;
  }
}

.challenge-status {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  color: $seity-dark-green;
  width: 100%;
  max-width: rem(400);

  h1 {
    font: rem(40) $font-source-serif;
  }
  h4 {
    font: rem(16) $font-source-sans;
  }
  &-button {
    align-self: center;
    justify-self: center;
    margin: rem(20) 0 rem(30) 0;
  }
}
