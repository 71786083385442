@font-face {
  font-family: 'DM Serif Display';
  src: url('../fonts/DMSerifDisplay-Regular.ttf') format('truetype');
}

@font-face {
  //body
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face {
  //body
  font-family: 'Source Sans Pro Bold';
  src: url('../fonts/SourceSansPro-Bold.ttf') format('truetype');
}

@font-face {
  //body
  font-family: 'Source Sans Pro SemiBold';
  src: url('../fonts/SourceSansPro-SemiBold.ttf') format('truetype');
}

@font-face {
  // headers
  font-family: 'Source Serif Pro';
  src: url('../fonts/SourceSerifPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Serif Pro Bold';
  src: url('../fonts/SourceSerifPro-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Serif Pro SemiBold';
  src: url('../fonts/SourceSerifPro-SemiBold.ttf') format('truetype');
}
