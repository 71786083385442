@import '../../../assets/sass/global';


.mo-main-container {
  @include main-width;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  overflow: hidden;
  width: 90%;

  h1 {
    color: $seity-black;
    font: rem(42) $font-source-serif;
    line-height: rem(44);
    font-weight: 600;
    margin: clamp(5px, 2vh, 20px) 0 rem(30) 0;
    width: 90%;
  }

  &-image {
    width: rem(155);
    z-index: 1;
    box-shadow: 0 8px 16px 0 rgba(96, 97, 112, 0.16),
    0 2px 4px 0 rgba(40, 41, 61, 0.04);
    border-radius: rem(80);
  
    @include respond-to(small-mobile) {
      width: rem(108);
    }
  }
}

.mo-back-button {
  top: $rem-desktop-header-height;
  margin-top: rem(16);
}

.mo-background {
  background: rgba($seity-pink, 0.3);

  max-width: rem(770);
  box-shadow: 0 8px 16px 0 rgba(96, 97, 112, 0.16),
    0 2px 4px 0 rgba(40, 41, 61, 0.04);

  border-radius: 0 rem(52) rem(52) rem(52);
  margin: rem(-80) 0 rem(16) 0;

  @include respond-to(small-mobile) {
    margin: rem(-50) 0 0 0;
  }

  &-score {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
    left: rem(150);
    top: rem(5);

    color: $seity-world;
    font: rem(32) $font-source-serif;
    margin-top: rem(16);

    @include respond-to(small-mobile) {
      font-size: rem(26);
    }

    @include respond-to(small-mobile) {
    left: 33%;
  }
  @include respond-to(mobile) {
    left: 33%;
  }

  }
  &-progress-bar {
    margin: rem(-5) 0 0 0;
    border: rem(1) solid $seity-world;
    width: rem(78);

    @include respond-to(small-mobile) {
      width: rem(72);
    }
  }
}

.mo-foreground {
  background: $seity-pink;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
  width: 100%;
  max-width: rem(770);
  height: auto;

  margin: rem(30) 0 0 0;
  border-radius: 0 rem(52) 0 rem(52);
  padding: rem(45);

  @include respond-to(small-mobile) {
    margin: rem(20) 0 0 0;
  }

  h6 {
    font: rem(18)/rem(24) $font-source-sans;
    margin: rem(10) 0 0 0;
    color: $seity-white;
    
    @include respond-to(small-mobile) {
      font-size: rem(17);
    }
  }
  h2 {
    font: 700 rem(21) $font-source-serif;
    line-height: rem(28);
    width: 80%;
    color: $seity-white;
  }
}
