@import '../../../assets/sass/global';

.show-affirmation {
  @include main-width;
  padding: rem(16) rem(24);
  @include respond-to(tablet) {
    padding: rem(32) rem(45);
  }
  @include respond-to(desktop) {
    padding: rem(32) rem(97);
  }
  .sa-header {
    display: flex;
    span {
      font: 600 rem(14) $font-source-sans;
      color: #677280;
      line-height: 1.14;
      height: rem(16);
    }
    h5 {
      font: 600 rem(26) $font-source-sans;
      color: #07072e;
      height: rem(25);
    }
  }
  .sa-content {
    margin-top: auto;
    text-align: center;
    font: 600 rem(42) $font-source-serif;
    color: #07072e;

    white-space: pre-wrap;      /* CSS3 */   
    white-space: -moz-pre-wrap; /* Firefox */    
    white-space: -pre-wrap;     /* Opera <7 */   
    white-space: -o-pre-wrap;   /* Opera 7 */    
    word-wrap: break-word; 
  }
  .sa-footer {
    display: flex;
    justify-content: center;
    margin-top: auto;
    > button {
      max-width: rem(281);
      width: 100%;
      height: rem(60);
      border-radius: rem(56);
      box-shadow: 0 4px 8px 0 rgba(96, 97, 112, 0.16), 0 0 2px 0 rgba(40, 41, 61, 0.04);
      background-color: var(--white);
      color: $seity-teal-medium;
      font: 600 rem(14) $font-source-sans;
      &:last-child {
        background-color: $seity-teal-medium;
        color: white;
      }
    }
    &:first-child {

    }
  }
}
