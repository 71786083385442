@import "../../../assets/sass/global";

.dragon-list-main-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @include main-width;

  overflow: hidden;
  width: 90%;

  h1 {
    color: $seity-black;
    font: rem(42) $font-source-serif;
    line-height: rem(44);
    font-weight: 600;
    margin: rem(16) 0 rem(16) 0;
    width: 90%;
  }
}

.dragon-list-logoImage {
  width: rem(108);
  z-index: 1;
  box-shadow: 0 8px 16px 0 rgba(96, 97, 112, 0.16),
    0 2px 4px 0 rgba(40, 41, 61, 0.04);
  border-radius: rem(80);
}

.dragon-list-main-back-button {
  top: $rem-desktop-header-height;
  margin-top: rem(16);
}

.dragon-list-background-wrapper {
  max-width: rem(770);
  box-shadow: 0 8px 16px 0 rgba(96, 97, 112, 0.16),
    0 2px 4px 0 rgba(40, 41, 61, 0.04);

  background-color: $seity-timberwolf;
  border-radius: 0 rem(52) rem(52) rem(52);
  margin: rem(16) 0 rem(16) 0;
}

.dragon-list-descripntion {
  position: relative;
  margin-top: rem(32);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding-left: rem(52);
  padding-right: rem(52);

  @include respond-to(mobile) {
    padding-left: rem(16);
    padding-right: rem(16);
  }

  h3 {
    color: $seity-black;
    font: rem(18) $font-source-sans;
    margin-bottom: rem(32);
  }
}

.dragon-list-progress-bar-container {
  margin: rem(-5) 0 0 0;
  border: rem(1) solid $seity-world;
  width: rem(78);

  @include respond-to(small-mobile) {
    width: rem(72);
  }
}

.dragon-list-foreground-wrapper {
  width: 100%;
  max-width: rem(770);
  background-color: $seity-world;
  border-radius: 0 rem(52) 0 rem(52);
  padding: rem(20) rem(52);

  @include respond-to(small-mobile) {
    margin: rem(20) 0 0 0;
  }
  @include respond-to(mobile) {
    padding-left: rem(16);
    padding-right: rem(16);
  }
}
