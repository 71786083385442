@import "../../assets/sass/global";

.today-container {
  @include main-width;
  padding: rem(16) rem(24);
  @include respond-to(tablet) {
    padding: rem(32) rem(45);
  }
  @include respond-to(desktop) {
    padding: rem(32) rem(97);
  }
  > h3 {
    font: 600 rem(26) $font-source-sans;
    color: $seity-black;
  }
  > h5 {
    font: 600 rem(14) $font-source-sans;
    color: $seity-dark-grey;
  }
  .complete-box {
    padding: rem(16);
    background-color: white;
    border-radius: rem(20);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.869);
    > img {
      width: rem(24);
      height: rem(24);
    }
    .complete-content {
      > span {
        font: 600 rem(14) $font-source-sans;
        color: $seity-dark-grey;
      }
      > h3 {
        font: 600 rem(26) $font-source-sans;
        color: $seity-black;
      }
      > h5 {
        font: 400 rem(16) $font-source-sans;
        color: #07072e;
      }
      > .check-in-again-btn {
        font: 600 rem(12) $font-source-sans;
        color: $seity-dark-grey;
      }
    }
  }
  .wellbeing-checkin {
    .progressbar {
      width: rem(55);
      height: rem(55);
      .CircularProgressbar-text {
        font: 600 rem(18) $font-source-sans;
      }
    }
  }
  .checklist-wrap {
    h1 {
      font: 600 rem(27) $font-source-serif;
      color: #202223;
      text-align: left;
    }
    h4 {
      font: 400 rem(16) $font-source-sans;
      color: $seity-dark-grey;
    }
    .checklist-content {
      display: flex;
      flex-direction: column;
      position: relative;
      .checklist-liner {
        width: rem(1);
        height: calc(100% - 70px);
        position: absolute;
        top: rem(35);
        left: rem(10);
        background-image: linear-gradient(#939393 33%, rgba(255, 255, 255, 0) 0%);
        background-position: right;
        background-size: 1px 20px;
        background-repeat: repeat-y;
      }
      .checklist-item {
        height: rem(74);
        display: flex;
        align-items: center;
        margin-bottom: rem(24);
        &:last-child {
          margin-bottom: rem(0);
        }
        .check-icon {
          width: rem(21);
          height: rem(21);
          border-radius: rem(10);
          z-index: 9;
          &.incomplete {
            border: rem(1) solid #939393;
            background-color: $seity-white;
          }
          &.complete {
            background-color: $seity-teal-medium;
          }
        }
        .item-right {
          img:first-child {
            width: rem(24);
            height: rem(24);
            border-radius: rem(12);
          }
          div {
            > span {
              font: 600 rem(12) $font-source-sans;
              color: #677280;
            }
            > h3 {
              font: 600 rem(21) $font-source-sans;
              color: $seity-black;
            }
          }
          &.incomplete {
            cursor: pointer;
            background-color: white;
            border-radius: rem(20);
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.869);
          }
        }
      }
    }
  }
}

.affirmation-warning-modal {
  width: rem(341);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 20px 32px 0 rgba(96, 97, 112, 0.24), 0 2px 8px 0 rgba(40, 41, 61, 0.08);
  background-color: var(--white);
  @include respond-to(tablet) {
    width: rem(462);
  }
  @include respond-to(desktop) {
    width: rem(568);
  }
  &__content {
    text-align: center;
    > h2 {
      font: 600 rem(28) $font-source-serif;
      color: $seity-black;
      line-height: 1.25;
      text-align: center;
    }
    > p {
      margin-top: rem(24);
      font: 400 rem(20) $font-source-sans;
      color: $seity-black;
      text-align: center;
    }
    > button {
      margin: 0 auto;
      margin-top: rem(24);
      padding: rem(10) rem(106) !important;
    }
  }
}
