.practice-assessment-navigationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 16px 0;
  margin-bottom: 20px;

  .errorText {
    color: red;
    font-size: 14px;
    margin-bottom: 16px;
    text-align: center;
  }

  button {
    padding: 16px 80px;
    font-size: 18px;
    font-weight: 600;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 8px;

    button {
      width: 100%;
      padding: 16px;
    }

    .errorText {
      margin-bottom: 8px;
    }
  }
}
