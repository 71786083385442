@import "../../assets/sass/global";

.directory-container {
  position: relative;

  .permission-modal-link {
    color: $seity-teal-medium;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .permission-text {
    font: 400 rem(16) $font-source-sans;
    color: $seity-dark-grey;
  }
  
  .filters-container {
    z-index: 999;
    top: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 23.625rem;
    background: $seity-white;
    box-shadow: 0px 20px 32px 0px rgba(96, 97, 112, 0.24), 0px 2px 8px 0px rgba(40, 41, 61, 0.08);
  }

  .fixed-header {
    position: fixed;
    width: 100%;
    margin: 0;
    top: rem(75);
    left: 0;
    padding: 4.6875rem 6.5rem 0 6.5rem;
    z-index: 9;
    background-color: $seity-white;

    @include respond-to(mobile) {
      top: rem(64);
      padding: rem(64) 1rem 0 1rem;
    }
  }

  .cta-row {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    .search-input {
      background-color: white;
      border-radius: 0.625rem;
      border: 1px solid var(--gray-1, #c1c7cd);
      display: flex;
      justify-content: center;
      &-container {
        height: rem(40);
      }
      &-icon {
        bottom: rem(7);
      }
    }

    .filter-button {
      height: fit-content;
      width: fit-content;
      padding: 0.25rem;
      border-radius: 0.3125rem;
      cursor: pointer;
      position: relative;

      .filter-count {
        width: fit-content;
        height: fit-content;
        background-color: #e1f6f7;
        border-radius: 50%;
        position: absolute;
        top: -0.75rem;
        right: -0.75rem;
        padding: 0.15rem 0.25rem;

        p {
          font: normal 0.8rem $font-source-sans;
          color: $seity-black;
          text-align: center;
          width: fit-content;
        }
      }

      img {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}

.done-button-container {
  position: fixed;
  width: 100%;
  margin: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  background-color: $seity-white;
  padding: 2rem 6rem;
  .personal-list-done-button {
    width: 100%;
    margin: auto;
  }
}

.scroll-container {
  padding-top: 7rem;
  padding-bottom: 10rem;
}

