@import '../../../assets/sass/global';

.google-login-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.seity-google-login-button-container {
  margin: 15px 0;
}

.seity-google-login-button {
  color: $seity-teal-medium !important;
  background-color: #fff !important;
  border: rem(2) solid #fff !important;
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04),
  0px 8px 16px rgba(96, 97, 112, 0.16) !important;
  width: 310px;
  padding: rem(13) rem(33) !important;
  text-align: center !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: rem(20) !important;
  font-family: $font-source-sans !important;
  font-weight: bold !important;
  cursor: pointer !important;
  transition-duration: 0.4s !important;
  border-radius: rem(36) !important;
  line-height: rem(22) !important;

  .seity-google-login-button-text-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;

    .seity-google-login-button-text {
      padding-left: 10px
    }

    .seity-google-login-button-icon {
      width: 17px;
      height: 17px;
    }

  }

}