@import '../../../assets/sass/global';

.sap-item-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: rem(24) 0;
  margin: 0  rem(32);
  border-bottom: 2px solid #f0f0f0;
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
  img {
    margin-right: rem(10);
  }
  & > h1 {
    margin-bottom: 0;
    @include font-style(
      rem(15),
      normal,
      $seity-black,
      center,
      rem(20),
      $family: $font-source-sans
    );
  }
}
