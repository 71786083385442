@import "../../../assets/sass/global";

.seity-textarea {
  width: 100%;
  height: 100%;
  border-radius: rem(8);
  background-color: white;
  border: rem(1) solid $seity-middle-grey;
  padding: rem(14) rem(14);
  outline: none;
  font: rem(18) $font-source-sans;
  &:focus {
    border-color: $seity-blue;
    border-width: rem(2);
  }
  &-wrapper {
    position: relative;
    width: 100%;
  }
  &-padding-left {
    padding-left: rem(48);
  }
  &-padding-right {
    padding-right: rem(45);
  }
  &-error {
    border: rem(1.5) solid $seity-orange !important;
  }
  &::placeholder {
    color: $seity-middle-grey;
  }
}
.seity-textarea-with-label {
  margin: rem(10) 0 rem(30) 0;
  label {
    font: rem(18) $font-source-sans;
    color: $seity-dark-green;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font: rem(18) $font-source-sans;
      color: $seity-orange;
      font-style: italic;
    }
  }
}
