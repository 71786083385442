@import '../../../../assets/sass/global';

.error-text {
  color: $seity-red-orange;
  font-size: 16px;
  text-align: center;
  margin: 10px 0;
}

.accordion-container {
  margin-bottom: 10px;
}
