@import '../../../../assets/sass/global';

.ayp-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: rem(15) 0 0 0;
  h2 {
    margin-top: rem(10);
    font: rem(42) $font-source-serif;
    font-weight: 600;
    margin-bottom: rem(26);
    color: $seity-black;
    text-align: center;
  }
}

.ayp-practice-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: rem(80);
  max-width: rem(340);
  width: 90%;

  margin: rem(5) 0 rem(5) 0;
  border-radius: rem(20);
  img {
    width: rem(49);
    height: rem(49);
    margin: 0 rem(17) 0 rem(25);
  }
  h3 {
    font: rem(18) $font-source-sans;
    width: rem(150);
    margin: rem(2) 0 0 0;
    font-weight: bold;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      font: rem(28) $font-source-sans;
      margin: 0 0 rem(2) rem(15);
    }
  }
}

.ayp-world {
  background: $seity-world;
  color: $seity-cream;

  h1 {
    color: $seity-cream;
  }
}

.ayp-body {
  background: $seity-teal;
  color: $seity-cream;
  h1 {
    color: $seity-cream;
  }
}

.ayp-mind {
  background: $seity-mind;
  color: $seity-dark-green;
}

.ayp-spirit {
  background: $seity-spirit;
  color: $seity-dark-green;
}

.ayp-progress-bar {
  width: rem(72);
}
