@import "../../../assets/sass/global";

.contact-list-item-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;

  .user-details {
    .user-name {
      font: 1rem $font-source-sans;
      font-weight: 400;
      line-height: 1.25rem;
      color: $seity-black;

      .highlighted {
        font-weight: bold;
      }
    }

    .user-cv-images {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    .user-cv-names {
      font: 0.75rem $font-source-sans;
      font-weight: 600;
      line-height: 1rem;
      color: $seity-dark-grey;
    }
  }
}
