@import "../../../assets/sass/global";

.tyd-container {
  display: flex;
  justify-content: center;
  width: 100%;
  @include respond-to(mobile) {
    margin: 0;
  }
}

.tyd-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: rem(32) rem(40);
  background-color: $seity-timberwolf;
  color: $seity-dark-green;
  border-radius: 0 rem(40) 0 rem(40);

  @include respond-to(mobile) {
    padding: rem(32);
    max-height: rem(700);
  }

  h1 {
    margin-bottom: rem(12);
    @include font-style(rem(28), 600, $seity-black, center, rem(32), $family: $font-source-serif);
  }
  h4 {
    margin-bottom: rem(32);
    max-width: rem(800);
    @include font-style(rem(18), normal, $seity-black, center, $font-source-sans);
  }
  div {
    cursor: pointer;
  }
}

.tyd-item {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: rem(620);
  padding: rem(16);
  border-radius: rem(10);
  margin-bottom: rem(32);
  cursor: pointer;
  color: $seity-cream;
  @include respond-to(mobile) {
    max-width: 100%;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &-container {
    display: flex;
    flex-direction: row;
  }
  &-icon {
    width: rem(64);
    height: rem(64);
    margin-left: rem(20);
    @include respond-to(mobile) {
      margin: 0;
      align-self: center;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    margin-left: rem(15);
    font-size: rem(20);

    @include respond-to(mobile) {
      font-size: rem(18);
      font-family: $font-source-sans-bold;
      max-width: 67%;
      margin-left: rem(10);
    }
  }

  .tyd-item:last-of-type {
    margin-bottom: rem(0);
  }

  &-content-score {
    display: flex;

    div {
      font-size: rem(16);

      @include respond-to(mobile) {
        font-size: rem(14);
        font-family: $font-source-sans;
      }
    }

    b {
      font-size: rem(20);
      line-height: rem(22);
      margin-right: rem(5);

      @include respond-to(mobile) {
        font-size: rem(18);
        line-height: rem(18);
      }
    }
  }

  &-caret {
    position: absolute;
    right: rem(20);
    width: rem(11);
    height: rem(22);
  }
}
