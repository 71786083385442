@import "../../../assets/sass/global";

.intentions {
  &__header {
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: rem(104);
    left: 0;
    top: 0;
    z-index: 9;
    h3 {
      width: 100%;
      text-align: center;
      color: $seity-black;
      @include font-base($font-source-serif, normal, 600);
      font-size: rem(42);
    }
    .header-wrap {
      @include main-width;
      .header-back-button {
        position: absolute;
        top: rem(30);
        left: rem(10);
        @include respond-to(tablet) {
          left: rem(72);
        }
        @include respond-to(desktop) {
          left: rem(120);
        }
        img {
          object-fit: contain;
        }
      }
    }
  }
  .no-intentions {
    @include font-base($font-source-sans, normal, 400);
    font-size: rem(21);
    color: $seity-black;
    text-align: center;
  }
  &__list {
    margin-top: rem(100) !important;
    list-style: none;
    width: 100%;
    padding: 0 rem(10) !important;
    @include respond-to(tablet) {
      width: rem(463);
    }
    @include respond-to(desktop) {
      width: rem(568);
    }
    li {
      margin-bottom: rem(32);
      .date-text {
        color: $journal-gray;
        @include font-base($font-source-serif, normal, 600);
        font-size: rem(20);
      }
      .listitem-content {
        overflow: hidden;
        height: rem(291);
        position: relative;
        border-radius: rem(16);
        .listitem-content-wrap {
          z-index: 7;
          p {
            text-align: center;
            @include font-base($font-source-serif, normal, 600);
            font-size: rem(18);
            color: white;
            white-space: pre-line;
          }
          .content-top {
            span {
              color: white;
            }
            text-align: right;
          }
        }
        .background-img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
        .background-mask {
          @extend .background-img;
          background-color: black;
          opacity: 0.4;
        }
        .listitem-overlay {
          z-index: 8;
          background: rgba(0, 0, 0, 0.5);
          opacity: 0;
          position: absolute;
          transition: .5s ease;
          &:hover {
            opacity: 1;
            cursor: pointer;
          }
          button {
            width: rem(32);
            height: rem(32);
            border-radius: rem(16);
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
          }
          .btn-check {
            border: solid rem(2) white;
            z-index: 4;
            img {
              opacity: 0;
            }
            &:hover {
              img {
                opacity: 1;
              }
            }
          }
          .btn-delete {
            z-index: 4;
            &:hover {
              background-color: #dfdddd;
              img {
                filter: invert(31%) sepia(75%) saturate(5000%) hue-rotate(342deg) brightness(95%) contrast(97%);
              }
            }
          }
          .btn-check.checked {
            background-color: #2e996b;
            border: 0;
            img {
              opacity: 1;
            }
          }
          > div {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
  &__plus-button {
    border-radius: rem(28);
    width: rem(56);
    height: rem(56);
    position: fixed;
    bottom: rem(32);
    right: rem(64);
    z-index: 9;
    @extend .intention-button;
  }
}
