@import '../../../assets/sass/global';

.dashboard-mcv-header-wrapper {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: rem(40);
  max-width: rem(624);
  @include respond-to(tablet) {
    flex-direction: row;
    max-width: 100%;
  }
  @include respond-to(desktop) {
    flex-direction: row;
    max-width: 100%;
  }

  > h1 {
    position: absolute;
    max-width: rem(450);
    left: 50%;
    transform: translateX(-50%);
    margin: 0;

    @include font-style(
      rem(42),
      600,
      $seity-black,
      center,
      rem(44),
      $family: $font-source-serif
    );

    @include respond-to(mobile) {
      @include font-style(rem(37));
      position: unset;
      left:auto;
      transform: none;
    }
  }
}
.dashboard-mcv-container {
  @include main-width;
  padding: rem(10);
  margin: rem(30) 0 rem(20) 0 !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  &-back-caret {
    width: rem(30);
    height: rem(25);
    margin-right: auto;
  }
  &-values {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: auto;

    @include respond-to(desktop) {
      flex-direction: row;
      align-items: center;
    }
  }
  &-button {
    margin-left: auto;
    padding: rem(16) rem(32) !important;
    box-shadow: 0 2px 4px 0 rgba(96, 97, 112, 0.16),
      0 0 1px 0 rgba(40, 41, 61, 0.04) !important;
    @include font-style(
      rem(20) !important,
      bold!important,
      #ffffff!important,
      center!important,
      rem(22) !important,
      $family: $font-source-sans
    );
    @include respond-to(mobile) {
      margin-left: unset;
      margin-top: rem(15);
    }
  }
}

.mcv-core-value {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: rem(10);
  padding: rem(24) !important;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(96, 97, 112, 0.16),
    0 2px 4px 0 rgba(40, 41, 61, 0.04) !important;

  width: 100%;
  margin-bottom: rem(24) !important;
  @include respond-to(desktop) {
    margin: 0 rem(10);
  }
  &-order {
    font: rem(36) $font-source-sans-bold;
    color: rgba(49, 73, 71, 0.3);
    margin: rem(10) 0 rem(10) 0;
  }
  &-img {
    width: rem(104);
    height: rem(104);
  }
  &-title {
    margin: rem(25) 0 rem(20) 0;
    font: rem(18) $font-source-serif-bold;
  }
  &-text-wrapper {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    height: 100%;
  }
  &-description {
    width: 100%;
    height: 100%;
    margin: rem(32) 0;

    @include font-style(
      rem(18),
      600,
      $seity-black,
      center,
      $family: $font-source-sans
    );

    @include respond-to(desktop) {
      width: rem(220);
      height: rem(130);
      margin: rem(24) 0 rem(24) 0;
    }
  }
  &-quote {
    display: flex;
    align-items: center;
    font-style: italic;
    height: 100%;
    margin-bottom: rem(32);
    @include font-style(
      rem(16),
      normal,
      $seity-black,
      center,
      1.1,
      $family: $font-source-sans
    );

    @include respond-to(desktop) {
      height: rem(130);
      margin-bottom: rem(10);
    }
    &-author {
      font-style: italic;
      @include font-style(
        rem(16),
        normal,
        $seity-black,
        center,
        1.25,
        $family: $font-source-sans
      );
    }
  }
}
