@import '../../../assets/sass/global';


.seity-network-alert {
display: flex;
flex-direction: column;
align-items: center;
  &-title {
    margin-top: rem(24);
    font: 600 rem(42) $font-source-serif;
  }
  &-subtitle {
    margin-top: rem(24);
    font: normal rem(18) $font-source-sans;
    color: $seity-dark-grey;
    max-width: rem(300);
    text-align: center;
  }

  &-button {
    margin: rem(24) 0;
  }
  >img {
    width: rem(338);
    height: rem(335);
  }
}