@import "../../assets/sass/global";

.core-values-only-container {
  padding: 0 12rem 2rem 12rem;

  .directory-cta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 0.9375rem;
    background: #fff;
    box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 3.5rem;
    cursor: pointer;

    p {
      font: 600 1.625rem $font-source-sans;
      line-height: 2.625rem;
      color: $seity-black;
    }

    button {
      height: 2.5rem;
    }
  }

  .member-core-values {
    &__header {
      display: flex;
      > button {
        margin-left: auto;
      }
    }
    p {
      font: 600 1.625rem $font-source-sans;
      line-height: 2.625rem;
      color: $seity-black;
    }

    .more-info {
      height: 1rem;
      width: 1rem;
      cursor: pointer;
      margin-bottom: 1.5rem;
      margin-left: 0.2rem;
    }

    .cv-item-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.875rem 1rem;
      border-radius: 0.625rem;
      background: #fff;
      box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.16), 0px 0px 2px 0px rgba(40, 41, 61, 0.04);
      margin-top: 1rem;
      cursor: pointer;

      > div {
        display: flex;
        align-items: center;
        gap: 1rem;

        p {
          font: 600 1.3125rem $font-source-sans;
        }
      }

      > img {
        height: 1.5rem;
        width: auto;
      }
    }

    .core-values-cta {
      border-radius: 0.934rem;
      background-color: #fff;
      box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.1);
      margin-top: 1rem;

      .banner-image {
        background-image: url(../../assets/graphics/cv-banner.png);
        background-size: cover;
        height: 10.5rem;
        border-radius: 0.934rem 0.934rem 0 0;
      }

      .content {
        padding: 1rem 2rem;
        color: $seity-black;
        .title {
          font: 600 1.625rem $font-source-sans;
          line-height: 2.625rem;
        }
        .subtitle {
          font: 400 1.125rem $font-source-sans;
          line-height: normal;
          margin-bottom: 0.6rem !important;
        }
        .time-commitment {
          font: 600 0.875rem $font-source-sans;
          line-height: 1rem;
        }
        .current-core-values {
          p {
            font: 600 0.875rem $font-source-sans;
            line-height: 1rem;
            color: #677280;
          }
          .cv-icons {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin-top: 0.5rem;
          }
        }
        button {
          margin-top: 1.3rem;
          height: 3rem;
          width: 100%;
        }
      }
    }
  }

  .resources {
    margin-top: 3rem;

    &-list {
      display: flex;
      gap: 1.25rem;
    }

    &-title {
      font: 600 1.625rem $font-source-sans;
      line-height: 2.625rem;
      color: $seity-black;
    }

    &-image {
      width: rem(176);
      height: rem(176);
      border-radius: 20px;
      background-size: cover;
      object-fit: cover;
      cursor: pointer;
      margin-top: 1.5rem;
      margin-bottom: 1rem;

      @include respond-to(mobile) {
        width: rem(153);
        height: rem(148);
      }
    }
    &-image:hover {
      -webkit-filter: brightness(60%);
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      -o-transition: all 1s ease;
      -ms-transition: all 1s ease;
      transition: all 1s ease;
    }

    &-subtitle {
      font: 400 1.25rem $font-source-sans;
      line-height: 1.75rem;
      color: $seity-black;
    }
  }
}


.ellipsis-menu {
  background-color: white;
  border-radius: rem(16);
  position: absolute;
  right: rem(160);
  top: rem(180);
  z-index: 8;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
  &__button {
    @include font-base($font-source-sans, normal, 600);
    font-size: rem(16);
    color: $seity-dark-grey;
  }
}

.ellipsis-menu__button {
  padding: 1rem 2.0rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ellipsis-menu__link {
  display: "inline-block";
  color: "blue";
  text-decoration: none !important;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 2rem;
  font: 600 0.75rem $font-source-sans;
}

.icon-right {
  margin-left: auto; 
}

.button-text {
  padding-right: 8.5rem;
}