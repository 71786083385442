@import '../../../assets/sass/global';


.content-container {
  display: flex;
  flex-direction: column;
  align-items: center !important; 
  margin-top: rem(50);
  width: 100%;
}

.heading-label-triage {
  font-family: $font-source-sans !important;
  font-weight: bold;
  color: $seity-dark-green;
  margin-bottom: rem(15);
  text-align: center
}

.subheading-label-triage {
  font-family: $font-source-sans;
  color: $seity-dark-green;
  margin-top: rem(5) !important;
  width: rem(700);
  text-align: center;
  margin-bottom: rem(70) !important;
  font-size: rem(22)
}

.footer-text {
  font-family: $font-source-sans;
  text-align: center;
  margin-top: rem(50) !important;
  margin-bottom: rem(25) !important;
  font-size: rem(18);
  width: rem(130) !important;
  text-align: center !important;
}

.triage-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.triage-message-div {
  display: flex;
  flex-direction: column;
  margin-top: rem(40) !important;
  align-items: center;  
}

.triage-message {
  font-family: $font-source-sans;
  color: $seity-dark-green;
  margin-top: rem(30) !important;
  width: rem(700);
  text-align: center;
  margin-bottom: rem(30) !important;
  font-size: rem(22)
}