.dragon-info-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dragon-info-modal {
  position: relative;
  background-color: #fff;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  border-radius: 8px;
  outline: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 24px;
  line-height: 24px;
  cursor: pointer;
}

.modalScrollContent {
  overflow-y: auto;
  padding: 40px 20px 20px;
}

.loadingContainer {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}

.errorText {
  color: red;
  font-size: 16px;
  text-align: center;
  margin: 10px 0;
}

.dragonInfoHeader {
  font-size: 26px;
  font-weight: 500;
  color: #343B43;
  margin-bottom: 10px;
  line-height: 32px;
}

.dragonInfoText {
  font-size: 18px;
  font-weight: 400;
  color: #343B43;
  line-height: 24px;
  margin-bottom: 20px;
  white-space: pre-wrap;
}

.dragonItemContainer {
  margin-bottom: 20px;
}

.dragonItemImage {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  margin-right: 16px;
  object-fit: cover;
}

.dragonItemTextContainer {
  flex: 1;
}

.dragonItemHeader {
  font-size: 18px;
  font-weight: 600;
  color: #343B43;
  margin: 0 0 5px 0;
}

.dragonItemText {
  font-size: 14px;
  font-weight: 400;
  color: #343B43;
  line-height: 20px;
  margin: 0;
}
