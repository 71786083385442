@import '../../assets/sass/global';

.pol-container {
  @include main-width;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: $seity-dark-green;
}

.pol-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: rem(20);
  background-color: #fff;
  border-radius: rem(40) 0;
  box-shadow: 0 4px 8px 0 rgba(96, 97, 112, 0.16),
    0 0 2px 0 rgba(40, 41, 61, 0.04);
}

.pol-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: rem(56) 0 0 0;
  @include respond-to(mobile) {
    margin: rem(30) 0 0 0;
  }

  h1 {
    margin-bottom: rem(24);
    @include font-style(
      rem(42),
      600,
      $seity-black,
      center,
      rem(44),
      $family: $font-source-serif
    );
    @include respond-to(mobile) {
      font-size: rem(32);
      margin: 0 0 rem(20) 0;
    }
  }
  h3 {
    margin-bottom: rem(20);
    @include font-style(
      rem(16),
      bold,
      $seity-dark-grey,
      center,
      rem(24),
      $family: $font-source-sans
    );
  }
}

.pol-bubble-blue {
  width: 100%;
  height: auto;
  padding: rem(20) rem(56) rem(10) rem(56);
  @include respond-to(mobile) {
    padding: rem(20) rem(30) rem(20) rem(30);
  }
  h4 {
    text-align: left;
    @include font-style(
      rem(26),
      600,
      $seity-black,
      left,
      rem(32),
      $family: $font-source-serif
    );
    margin: 0 0 rem(10) 0;
  }
  h5 {
    @include font-style(
      rem(18),
      600,
      $seity-black,
      left,
      $family: $font-source-sans
    );
  }
  p {
    @include font-style(
      rem(16),
      normal,
      $seity-dark-green,
      left,
      rem(19),
      $family: $font-source-sans
    );
  }

  section {
    margin: rem(20) auto rem(10) auto;
    width: 100%;
    font: rem(18) $font-source-sans;

    @include respond-to(mobile) {
      padding: 0 0 rem(15) 0;
    }
    ul {
      padding-inline-start: rem(40) !important;
      color: $seity-dark-green !important;
      font-size: rem(16);
    }
    li {
      margin-bottom: rem(10) !important;
    }
    ol {
      font-size: rem(16);
    }
    p {
      margin-bottom: rem(10) !important;
      color: $seity-dark-green;
    }
  }
}

.pol-bubble-grey {
  @extend .pol-bubble-blue;
}

.pol-list {
  ul {
    margin: 0 0 0 rem(25);
    li {
      @include font-style(
        rem(16),
        normal,
        $seity-dark-green,
        left,
        rem(20),
        $family: $font-source-sans
      );
    }
  }
  &-inner {
    ul {
      margin: 0 0 0 rem(30);
      li {
        @include font-style(
          rem(16),
          normal,
          $seity-dark-green,
          left,
          rem(20),
          $family: $font-source-sans
        );
      }
    }
  }
  &-inner-alphabet {
    ul {
      list-style: lower-alpha;
      li {
        @include font-style(
          rem(16),
          normal,
          $seity-dark-green,
          left,
          1.25,
          $family: $font-source-sans
        );
        margin: 0 0 0 rem(20);
      }
    }
  }
}

.pol-backCaret {
  position: absolute;
  top: rem(130);
  left: rem(0);
}

.margin-style {
  &-1 {
    margin: rem(5) 0 rem(35) 0;
  }
  &-2 {
    margin: rem(35) 0 rem(5) 0;
  }
  &-3 {
    margin: rem(35) 0 rem(5) 0;
  }
  &-4 {
    margin: rem(35) 0 rem(35) 0;
  }
  &-5 {
    margin: rem(20) 0 rem(30) 0;
  }
  &-6 {
    margin: rem(5) 0 rem(5) 0;
  }
  &-7 {
    margin: rem(5) 0 rem(-10) 0;
  }
}
