@import "../../../../assets/sass/global";

.profile-avatar-container {
  position: relative;
  width: fit-content;
  margin-right: auto;
  margin-bottom: 1.7rem;
}

.photo-action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e4e4e4;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;

  img {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
  }
}

.photo-action-drawer {
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0px 20px 32px 0px rgba(96, 97, 112, 0.24), 0px 2px 8px 0px rgba(40, 41, 61, 0.08);
  padding: 0rem 1rem;
  width: 11.5rem;
  z-index: 3;
  position: absolute;
  right: -10rem;
  margin-top: -1rem;

  > div {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 1rem 0;

    img {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 1rem;
    }

    p {
      font-size: 1.125rem;
      font-family: $font-source-sans;
      font-weight: 600;
      line-height: 1.25rem;
    }
  }

  > div:nth-child(2) {
    border-top: 1px solid #f0f0f0;

    p {
      color: #f75a3b;
    }
  }

  input {
    width: 0;
    height: 0;
  }
}
