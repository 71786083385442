@import '../../../../../assets/sass/global';


.guide-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  &-header {
    width: 100%;
    padding: rem(40) 0 rem(20) 0;
    box-shadow: inset 0 4px 5px 0 rgb(96 97 112 / 16%);

    &-highlited {
      background-color: #fff;
    }

    > h1 {
      font: 600 rem(40) $font-source-serif;
    }
  }

.tabs {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-buttons-container {
    background-color: #fff;
    display: flex;
    width: 100%;
    justify-content: space-around;
    >li {
      font: normal rem(21) $font-source-sans;
      color: $seity-dark-grey;
      padding: rem(24);
      list-style-type: none;
      cursor: pointer;
      outline: none;
    }
  }
}
}
.react-tabs__tab--selected {
  color: $seity-world !important;
  font-weight: bold !important;
  position: relative;
}

.react-tabs__tab--selected:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: $seity-world;
  height: rem(4);
  border-radius:4px;
}

.guide-content{
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: rem(764);
  padding: 0 rem(32) ;
  margin-bottom: rem(100);

  &-devider {
    height: rem(3);
    border-bottom: rem(1) solid #e4e4e4;
    margin: rem(30) 0 0 0;
    width: 100%;
  }

  &-subcategories {
    width: 100%;
    display: flex;
    flex-direction: column;
    .subcategory {
      width: 100%;
      &-header {
        display:flex;
        margin-top: rem(40);
        align-items: center;

        >img {
          width: rem(40);
          height: rem(40);
          margin-right: rem(8);
        }
        >h2 {
          font: 600 rem(21) $font-source-sans;
          color: $seity-black;
          margin: 0;
        }
      }

      >h4 {
        font: normal rem(16) $font-source-sans;
          color: $seity-black;
          margin-top: rem(16);
      }
    }
  }
  >img {
    margin: rem(50) 0 0 0;
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
    box-shadow: 0 4px 8px 0 rgba(96, 97, 112, 0.16), 0 0 2px 0 rgba(40, 41, 61, 0.04);
  }
  >h2 {
    font: 600 rem(21) $font-source-serif;
    margin-top: rem(30);
  }
  >h4 {
    font: normal rem(16) $font-source-sans;
    margin-top: rem(10);
  }
}
.core-values-subpage {
  width: 90%;

  @include respond-to(tablet) {
    width: 80%;
  }

  @include respond-to(desktop) {
    width: 60%;
  }
}