@import '../../../assets/sass/global';

.seity-idk-modal {
  width: rem(568);
  height: rem(652);
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: rem(8);
  padding: 0;
  max-height: 85%;
  display: flex;
  flex-direction: column;
  @include respond-to(mobile) {
    width: 100%;
  }
  h3 {
    @include font-base($font-source-serif, normal, 600);
    font-size: rem(28);
  }
  p {
    margin-top: rem(5) !important;
    @include font-base($font-source-serif, normal, 600);
    font-size: rem(26);
    line-height: rem(27);
    color: $seity-black;
    text-align: center;
  }
  .close-button {
    margin-top: rem(29);
    margin-right: rem(29);
    margin-bottom: rem(23);
    text-align: right;
  }
  img {
    margin-top: auto;
    pointer-events: none;
  }
  .btn-next {
    position: absolute;
    background-color: $seity-teal;
    width: rem(40);
    height: rem(40);
    border-radius: rem(20);
    bottom: rem(16);
    right: rem(16);
    img {
      margin: 0;
      width: rem(20) !important;
    }
  }
  .btn-prev {
    position: absolute;
    background-color: $seity-teal;
    width: rem(40);
    height: rem(40);
    border-radius: rem(20);
    bottom: rem(16);
    right: rem(16);
    img {
      width: rem(20) !important;
    }
  }
  .seity-idk-carousel {
    margin-top: auto;
    height: 100% !important;
    @include respond-to(mobile) {
      margin-top: rem(50);
    }
    img {
      pointer-events: none;
    }
    .rec-slider-container {
      margin: 0;
      .rec-slider {
        height: 100%;
      }
    }
    .rec-item-wrapper {
      height: 100%;
      img {
        width: 100% !important;
      }   
    }
    .rec-carousel {
      height: 100% !important;
    }
  }
}

.seity-idk-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 102;
}
