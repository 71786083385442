@import '../../../assets/sass/global';

.dragon-container {
  @include main-width;
  display: flex;
  justify-content: center;
  position: relative;
  &-back-caret {
    cursor: pointer;
    position: absolute;
    top: rem(50);
    left: 0;
    width: rem(32);
    height: rem(32);
}
  
}

.dragon-wrapper-learn {
  width: 100%;
  background-color: transparent;
  border-radius: 0 rem(40) rem(40) rem(40);
  margin: rem(5) 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: rem(40) 0 0 0;
  h1 {
    font: 700 rem(40) $font-source-serif;
    margin-bottom: rem(30);
  }
  h4 {
    max-width: rem(650);
    font: normal rem(20) $font-source-sans;
    text-align: center;
    margin: 0 rem(50) rem(40) rem(50);
  }
  div {
    cursor: pointer;
  }
}

.syncs-border-radius {
  border-radius: rem(20) rem(20) rem(20) 0;
}

.story-border-radius {
  border-radius: 0 rem(20) rem(20) rem(20);
}


.dragon-sections-container {
  display: flex;
  justify-content: center;
  margin-top: rem(20);

  @include respond-to(mobile) {
    flex-direction: column;
  }
}

.dragon-item-learn {
  width: 100%;
  background-color: $raging-dragon;
  border-radius: rem(20);
  margin-right: rem(30);
  display: flex;
  flex-direction: column;
  padding-bottom: rem(50);
  min-width: rem(270);
  max-width: rem(350);

  &:last-child {
    margin-right: 0;
  }

  @include respond-to(mobile) {
   margin-bottom: rem(30);
   margin-right: 0;
  }
  
  
  &-detail{
      margin: 0 rem(20);
      color:white;
      padding-bottom: 20px;
      text-align: left;
      font: normal rem(16) $font-source-sans;
  }
  
  .dragon-header div .dragon-title{
    font-weight: 700;
    font-size: rem(20);
  }
  }

  .dragon-header{
    padding: rem(20) 0px;
    border-radius: rem(30) 0 rem(30) 0;
    display: flex;
    align-items: center;
    color: $seity-cream;
    cursor: pointer;
    margin: 0px rem(20);
    &-title {
      font: rem(18) $font-source-serif;
      margin-bottom: rem(8);
    }
    &-description {
      font: rem(14) $font-source-serif;
    }
    img {
      margin-right: rem(20);
      width: rem(80);
    }
    div{
      display: flex;
      flex-direction: column;
    }
  

  }
  .awake-dragon{
    background-color: $awake-dragon;
  }

  .tame-dragon{
    background-color: $tame-dragon;
  }
  
  