@import "../../../../assets/sass/global";

.outcome-value-selection-container {
  display: grid;
  // justify-content: space-between;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  width: 90%;
  max-width: rem(320);
  height: rem(55);
  img {
    margin: 0 auto;
    cursor: pointer;
  }
}