.seity-progress-container {
  background-color: #56a3a6;
  border-radius: 2px;
  border: none;
  width: 100%;
  height: 4px;
}

.seity-progress-track {
  height: 100%;
  border-radius: 2px;
  background-color: red;
}
