@import '../../../../assets/sass/global';

.finish-survey-later {
  max-width: rem(500);
  overflow: hidden;
  justify-content: space-between;
  height: 100%;
  &__title {
    font-size: rem(18);
    line-height: rem(24);
    color: $seity-black;
    margin-top: rem(10) !important;
    white-space: pre-wrap;
    text-align: center;
    @include font-base($font-source-sans, bold, 500);
  }

  h3 {
    font-family: $font-source-sans;
    font-size: rem(28);
    font-weight: 600;
  }

  &-wrapper {
    height: 100%;
  }
  &-title-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    width: 90%;
  }
}

.finish-survey-later button {
  width: 100%;
}

.finish-survey-button {
  width: 100%;
  max-width: rem(284);
}

.finish-survey-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: rem(90);
}

.seity-modal-survey {
  border-radius: rem(8) !important;
  background-color: white;
  max-width: rem(568);
  max-height: rem(360);
  width: 80%;
  height: 100%;
}
