@import "../../../assets/sass/global";

.latest-check-in-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  border-radius: 1.25rem;
  background: #fff;
  flex: 1;
  height: 100%;

  @include respond-to(mobile) {
    flex-direction: column;
  }

  .content {
    width: 20rem;

    .heading {
      display: flex;
      gap: 0.5rem;

      .more-info {
        height: 1rem;
        width: 1rem;
        cursor: pointer;
      }

      h3 {
        font: 600 1.625rem $font-source-sans;
        margin-bottom: 1.5rem;
      }
    }

    h5 {
      font: 600 1rem $font-source-sans;
    }
    .check-in-button {
      width: 16.5rem;
    }
    .view-history-button {
      padding-left: 0;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include respond-to(mobile) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    div {
      display: flex;
      align-items: center;

      img {
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 0.62rem;
      }

      .label {
        font: 400 1rem $font-source-sans;
        color: #677280;
        margin-right: 3.5rem !important;
        flex-grow: 1;
      }

      .level {
        font: 700 1.125rem $font-source-sans;
        color: $seity-black;
      }
    }
  }
}
