@import '../../../../assets/sass/global';

.dv-container {
  @include main-width;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
  h1 {
    font: rem(42) $font-source-serif;
    color: $seity-black;
    font-weight: 600;
    margin-top: rem(25);
  }
  h4 {
    font: rem(16) $font-source-sans;
    color: $seity-black;
    text-align: center;
    margin-top: rem(16);
    margin-bottom: rem(16);
  }
}
.placeholder-style {
  width: 100%;
  justify-content: space-between;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 0;
  padding: rem(10) rem(5) 0 rem(5);
}
.dv-wrapper {
  display: flex;
  flex-direction: row-reverse;
  margin-top: rem(40);

  @include respond-to(desktop) {
    flex-direction: column;
    height: 80%;
  }
  &-position {
    position: relative;
    width: 100%;
  }
  &-placeholders {
    @extend .placeholder-style;
    &-horizontal {
      @extend .placeholder-style;
      padding: rem(10) rem(11.5) 0 rem(5);
      @include respond-to(desktop) {
        flex-direction: row;
      }
    }
    @include respond-to(desktop) {
      flex-direction: row;
    }
    &-choice {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;
      align-items: center;

      border: rem(2) dashed rgba(49, 73, 71, 0.3);
      border-radius: rem(30) rem(30) rem(30) 0;
      margin-bottom: rem(24);

      width: rem(166);
      height: rem(120);
      @include respond-to(tablet) {
        width: rem(264);
        height: rem(120);
      }
      @include respond-to(desktop) {
        width: rem(264);
        height: rem(120);
      }
      h1 {
        position: absolute;
        font: rem(48) $font-source-sans-bold;
        color: rgba(49, 73, 71, 0.3);
        @include respond-to(mobile) {
          font-size: rem(35);
          margin: 0;
        }
      }
    }
  }
  &-rank {
    display: flex;
    flex-direction: column;
    z-index: 0;
    padding: rem(10) rem(5) 0 rem(5);
    height: rem(600);
    @include respond-to(desktop) {
      flex-direction: row;
      height: rem(154);
    }
    &-choice {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: rem(300);
      height: rem(380);
      margin: rem(15) rem(1) rem(15) rem(30);
    }
  }
  &-values {
    width: 100%;
    padding: rem(10) rem(5) 0 rem(5);
    &-choice {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      & > div {
        z-index: 1;
      }
      &-left {
        width: 100% !important;
      }
      @include respond-to(desktop) {
        flex-direction: row;
      }
    }
  }
  &-primary-button {
    width: 100%;
    max-width: rem(284);
    @include font-style(rem(21), 600, #ffffff, center, rem(28));
  }
  &-cancel-button {
    width: 100%;
    max-width: rem(284);
  }
}

.dv-values-item {
  display: flex;
  flex-direction: column;
  align-items: center;

  background: $seity-timberwolf;
  border-radius: rem(30) rem(30) rem(30) 0;
  z-index: 100;
  box-shadow: 0 8px 16px 0 rgba(96, 97, 112, 0.16),
    0 2px 4px 0 rgba(40, 41, 61, 0.04);

  width: rem(166);
  height: rem(120);
  margin: 0 0 rem(24) 0;
  @include respond-to(tablet) {
    width: rem(264);
    height: rem(120);
  }
  @include respond-to(desktop) {
    width: rem(264);
    height: rem(120);
    margin: 0 rem(28.5) rem(24) 0;
  }
  &__cvImg {
    margin: rem(10) 0 0 0;
    -webkit-user-drag: none;
  }
  h4 {
    font: 700 rem(18) $font-source-sans-bold;
    color: $seity-dark-green;
  }
  &-selected {
    background-color: $seity-spirit;
  }
}

.dv-values-placeholder {
  display: flex;
  flex-direction: row;
  width: rem(166);
  height: rem(120);
  border-radius: rem(30) rem(30) rem(30) 0;
  user-select: none;
  cursor: default;
  margin: 0 0 rem(24) 0;

  @include respond-to(desktop) {
    width: rem(264);
    height: rem(120);
    margin: 0 rem(28.5) rem(24) 0;
  }
}
