$seity-teal-medium: #00a8a8;

.seity-checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.seity-checkbox {
  width: 24px;
  height: 24px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  margin-right: 8px;

  &:checked {
    background-color: $seity-teal-medium;
    border-color: $seity-teal-medium;

    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 7px;
      width: 8px;
      height: 16px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}

.seity-checkbox-text {
  font-size: 16px; // Adjust font size as needed
}