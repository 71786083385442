@import '../../../../../app/App.scss';

.nam-cream {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: $seity-white;
  border-radius: rem(4);
  padding: rem(48);
  width: 95%;
  max-width: rem(425);
  max-height: rem(524);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 20;
  justify-content: center;

  @include respond-to(mobile) {
    padding: rem(32);
    max-height: none;
  }
}

.nam-overlay-dark {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 11;
}

.nam-wrapper {
  color: $seity-dark-green;

  @include respond-to(mobile) {
    width: clamp(rem(278), 30vw, rem(450));
  }

  h2 {
    font: rem(24) / rem(24) $font-serif-display;

    @include respond-to(mobile) {
      font-size: rem(24);
      padding-right: 0;
    }
  }

  &-img {
    display: flex;
    justify-content: center;
  }

  img {
    width: 70%;
    margin-bottom: rem(24);
    @include respond-to(mobile) {
      width: clamp(rem(250), 30vw, rem(400));
      margin-top: rem(20);
    }
  }
}

.nam-content {
  display: flex;

  @include respond-to(mobile) {
    flex-direction: column;
    align-items: center;
  }


  &-text {

    h4 {
      margin-top: rem(32);
      margin-bottom: rem(24);
      font: rem(20) $font-source-sans;

      @include respond-to(mobile) {
        margin-top: rem(10);
        margin-bottom: rem(20);
        font: rem(18) $font-source-sans;
      }
    }
  }
}
