@import "../../../../../assets/sass/global";

.ev {
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $seity-black;
    margin: 0 0 rem(10) 0;
  }
  &-title {
    font: rem(42) / rem(44) $font-source-serif-semi-bold;
    margin-bottom: clamp(10px, 6vh, 56px);
    @include respond-to(mobile) {
      margin-bottom: rem(20);
      font-size: rem(40);
    }
  }
  &-codeSent {
    font: rem(20) / rem(28) $font-source-sans;
    color: $seity-dark-grey;
    margin-top: clamp(10px, 6vh, 56px);
    margin-bottom: rem(16);
  }
  &-email {
    font: rem(28) / rem(32) $font-source-sans-semi-bold;
    word-break: break-word;
    padding-inline: rem(12);
    margin-bottom: rem(48);
    @include respond-to(mobile) {
      margin-bottom: rem(20);
    }
  }
  &-codeExpires {
    font: rem(20) / rem(28) $font-source-sans;
    margin-bottom: rem(24);
  }
  &-codeInput {
    border-radius: rem(10);
    border: rem(1) solid $seity-grey;
    font: rem(40) $font-source-serif-bold;
    letter-spacing: rem(5);
    padding-left: rem(70);

    &:focus {
      box-shadow: none;
    }
  }
  &-codeInputContainer {
    max-width: rem(268);
    height: rem(84);
    margin-bottom: rem(24);
  }
  &-codeInputIcon {
    height: rem(33);
    bottom: rem(25);
    left: rem(25);
  }
  &-recivedCode {
    font: rem(18) / rem(23) $font-source-sans;
  }
  &-codeError {
    font: rem(18) / rem(24) $font-source-sans;
    color: $seity-orange;
    text-align: center;
    min-height: rem(40);
    margin-top: clamp(40px, 1vh, 80px);
    padding-inline: rem(12);
  }
  &-submit {
    max-width: rem(310);
    width: 90%;
    font-family: $font-source-sans-semi-bold !important;
  }
  &-notCorrectAddress {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    color: #4f7e81;
    span {
      font-size: 1rem;
      u {
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}
