@import '../../../../../assets/sass/global';

.sc-detail {
  width: 100%;
  h1 {
    font: 600 rem(32) $font-source-serif;
    color: $seity-black;
    text-align: left;
    @include respond-to(desktop) {
      font-size: rem(42);
    }
  }
  &__pdfview {
    span {
      font: 600 rem(12) $font-source-sans;
      color: #677280;
    }
    h5 {
      font: 600 rem(14) $font-source-sans;
      color: #677280;
    }
    > button {
      width: rem(342);
      @include respond-to(mobile) {
        width: 100%;
      }
    }
  }
  &__item {
    padding: rem(40) 0;
    border-bottom: rem(1) solid #E4E4E4;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    > img {
      max-height: rem(112);
      @include respond-to(desktop) {
        max-height: rem(178);
      }
    }
    h3 {
      font: 400 rem(18) $font-source-sans;
      color: $seity-black;
      @include respond-to(desktop) {
        font-size: rem(20);
      }
    }
    span {
      font: 600 rem(12) $font-source-sans;
      color: $seity-black;
      @include respond-to(desktop) {
        font-size: rem(14);
      }
    }
    h5 {
      font: 400 rem(12) $font-source-sans;
      color: $seity-black;
    }
  }
}