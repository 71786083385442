@import '../../../../assets/sass/global';

.questionContainer {
  background-color: #fff;
  border-radius: 10px;
  margin: 16px 32px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .radioLabel {
    color: $seity-dark-green;
    font-size: 14px;
  }

}

.questionText {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 8px;
  color: $seity-dark-green;
}
