@import '../../../assets/sass/global';

.locked-account {
  &-wrapper {
    background-color: $seity-white;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  &-support {
    color: $seity-teal;
    &:hover {
      color: $seity-teal;
    }
    &-wrapper {
      max-width: rem(320);
      margin-bottom: rem(20);
      font-size: rem(15);
    }
  }
  &-description-wrapper {
    max-width: rem(481);
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: $seity-dark-grey;
    h1 {
      @include font-style(
        rem(42),
        600,
        $seity-black,
        center,
        1.3,
        $family: $font-source-serif
      );
      margin-bottom: rem(24);
    }
    h5 {
      @include font-style(
        rem(20),
        400,
        $seity-black,
        center,
        1.3,
        $family: $font-source-sans
      );
      margin-bottom: rem(20);
    }
  }
  &-lock {
    width: rem(227);
    height: rem(245);
    margin-bottom: rem(24);
  }
  &-signature {
    color: $seity-black;
  }
}
