@import "../../assets/sass/global";

.whole-health-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 3rem 0;

  .heading {
    font: 600 1.7rem $font-source-sans-pro-regular;
    line-height: 2.25rem;
    letter-spacing: 0.0625rem;
    color: $seity-black;
    text-align: center;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    width: 54.5rem;
    max-width: 90rem;
    margin-top: 2.5rem;

    @include respond-to(mobile) {
      width: 100%;
    }
  }
}
