@import '../../../../assets/sass/global';
.cv-incentive {
  position: fixed;
  top: 0;
  left: 0;
  .cv-incentive-back {
    background-color: $seity-black;
    opacity: 0.7;
  }
  .cv-incentive-modal {
    position: absolute;
    width: rem(568);
    height: calc((100% - 75px) * 0.9);
    top: calc((100% - 75px) * 0.05);
    left: 50%;
    margin-left: rem(-284); //568/2
    background-color: $seity-white;
    margin-top: rem(75);
    border-radius: rem(16);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: rem(24);
    .cv-incentive-img {
      margin-bottom: rem(24);
    }
    .cv-incentive-header {
      width: 100%;
      text-align: left;
      font-family: $font-source-serif-semi-bold;
      font-size: rem(27);
      color: $seity-black;
      margin-bottom: rem(16) !important;
    }
    .cv-incentive-body {
      font-family: $font-source-sans;
      > p {
        font-size: rem(18);
      }
      > ul {
        padding-left: rem(24) !important;
        > li {
          margin-top: rem(16);
          font-size: rem(18);
        }
      }
    }
    .cv-incentive-footer {
      width: 100%;
      text-align: left;
      font-family: $font-source-sans-semi-bold;
      font-size: rem(21);
      color: $seity-black;
    }
    .cv-incentive-done {
      width: 100%;
      height: rem(60);
      background-color: $seity-teal-medium;
      border-radius: rem(56);
      font-family: $font-source-sans-semi-bold;
      font-size: rem(18);
      color: $seity-white;
      margin-top: rem(24);
      margin-bottom: re(24);
    }
  }
  @media only screen and (max-width: 600px) {
    .cv-incentive-modal {
      width: 100%;
      margin-left: 0;
      left: 0;
    }
  }
  @media only screen and (max-width: 400px) {
    .cv-incentive-modal {
      width: 100%;
      margin-left: 0;
      left: 0;
      .cv-incentive-body {
        font-family: $font-source-sans;
        > p {
          font-size: rem(14);
        }
        > ul {
          padding-left: rem(24) !important;
          > li {
            margin-top: rem(8);
            font-size: rem(12);
          }
        }
      }
      .cv-incentive-header {
        font-size: rem(22);
      }
      .cv-incentive-footer {
        font-size: rem(16);
      }
    }
  }
}
