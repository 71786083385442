@import "../../../assets/sass/global";

.cv-selection-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: rem(8);
  padding: rem(32) rem(40);
  max-height: 85%;
  display: flex;
  flex-direction: column;
  min-width: rem(522);
  min-height: rem(363);
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin-top: rem(5) !important;
    @include font-base($font-source-sans, normal, 500);
  }
  &__title {
    font-size: rem(20);
    color: $seity-black;
  }
  button {
    width: rem(326);
  }
  .btn-idk {
    @include font-base($font-source-sans, normal, 600);
    font-size: rem(18);
    color: #4f7e81;
  }
}

.cv-selection-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 20;
}
