@import "../../../assets/sass/global";

.seity-emotion-popup {
  width: calc(100% - 40px);
  padding: rem(32);
  border-radius: rem(16);
  box-shadow: 0 rem(20) rem(32) 0 rgba(96, 97, 112, 0.24), 0 rem(2) rem(8) 0 rgba(40, 41, 61, 0.08);
  background-color: #fff;
  z-index: 101;
  max-width: rem(900);
  h5 {
    @include font-base($font-source-sans, normal, 600);
    font-size: rem(18);
    color: $seity-black;
    text-align: center;
  }
  ul {
    list-style: none;
    @include respond-to(mobile) {
      overflow-y: scroll;
    }
    li {
      float: left;
      display: flex;
      justify-content: center;
      width: rem(80);
      padding: rem(12) 0;
      @include respond-to(desktop) {
        width: rem(84.2);
      }
      span {
        @include font-base($font-source-sans, normal, 400);
        font-size: rem(16);
        color: #677280;
        text-transform: capitalize;
      }
    }
  }
}
.seity-emotion-popup.fullscreen {
  position: absolute;
  width: calc(100% - 40px);
  height: calc(100% - 100px);
  margin: rem(20);
}
.seity-emotion-popup.modalscreen {
  position: fixed;
  max-width: rem(624);
  bottom: 30%;
  left: 0;
  margin: 0 rem(20);
  @include respond-to(mobile) {
    top: 0;
  }
  @include respond-to(tablet) {
    max-width: rem(624);
    left: calc(50% - 312px);
  }
  @include respond-to(desktop) {
    max-width: rem(906);
    left: calc(50% - 453px);
  }
}
