@import '../../../../assets/sass/global';

.accordion-container {
  background-color: $seity-white;
  border-bottom: 1px solid $seity-dark-grey;
}

.accordion-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 25px 16px;
  justify-content: space-between;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.title-section {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.subtext-section {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-text {
  font-size: 18px;
  font-weight: 600;
  font-family: $font-source-sans;
  color: $seity-black;
  line-height: 22px;
}

.subtext {
  font-size: 10px;
  color: #677280;
  text-align: center;
}

.accordion-body {
  padding: 16px;
  background-color: $seity-white;
}

.icon-section {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s;
}

.icon-expanded {
  transform: rotate(90deg);
}

.icon {
  margin-left: 0;
  font-size: 20px;
  color: $seity-black;
}
