@import "../../../../assets/sass/global";

.progress-bar-body {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1.5rem;
  margin-top: 3rem;

  &-4,
  &-5,
  &-8,
  &-9 {
    p {
      color: white;
      text-align: center;
    }
  }

  .back-arrow {
    height: 1rem;
    width: 1rem;
    cursor: pointer;
  }

  .progress-bar-outer {
    height: 0.25rem;
    border-radius: 0.3rem;
    background-color: $seity-timberwolf;
    width: 100%;

    .progress-bar-inner {
      height: 100%;
      border-radius: 0.3rem;
      background-color: $seity-world;
    }
  }

  .percentage {
    font-size: 1rem;
    font-weight: 400;
    font-family: "Source Sans Pro";
  }
}
