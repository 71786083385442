@import '../../../../assets/sass/global';

.see-all-container-wrapper {
  width: 100%;
  margin: rem(40) 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font: 700 rem(20) $font-source-serif;
    color: $seity-dark-green;
    text-align: center;
    font-size: rem(40);
    max-width: rem(850);
    margin-bottom: rem(20);
  }
}
.items-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 15px;
  @include respond-to(desktop) {
    justify-content: flex-start;
  }
}
