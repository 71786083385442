@import '../../../assets/sass/global';

.language-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $seity-dark-green;
  padding: rem(50) 0 rem(40) 0;
  width: 95%;
  max-width: rem(766);
  min-width: rem(400);

  @include respond-to(mobile) {
    padding: rem(20) 0 rem(20) 0;
    min-width: rem(300);
  }

  h1 {
    font: rem(42) $font-source-serif;
    color: $seity-black;
    font-weight: 600;
    margin: 0 0 rem(10) 0;

    @include respond-to(mobile) {
      margin: 0 0 rem(20) 0;
    }
  }
}

.language-list {
  width: 80%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(96, 97, 112, 0.16),
    0 0 2px 0 rgba(40, 41, 61, 0.04);
  margin-top: rem(32);
  padding: 0 rem(32);

  &-item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(24) 0;
    position: relative;
    border-bottom: rem(2) solid $seity-gallery;

    &:last-child {
      border: none;
    }

    > img {
      width: rem(32);
      height: rem(32);
      margin-right: rem(24);
    }

    > h2 {
      font: normal rem(18) $font-source-sans;
      margin: 0;
      align-self: center;
    }
  }

  &-radio {
    margin: 0 !important;
  }
}
