@import "../../../assets/sass/global";

.fp-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  color: $seity-dark-green;
  padding: rem(10);
  h1 {
    margin: rem(40) 0 rem(45) 0;
    font: rem(40) $font-source-serif;
  }
  h5 {
    margin: rem(17) 0 0 0;
    font: rem(18) $font-source-sans;
  }
  p {
    color: $seity-dark-green;
    width: rem(580);
    @include respond-to(mobile) {
      width: 100%;
    }
  }
}

.fp-email {
  margin: rem(60) 0 rem(50) 0;
  width: rem(350);
  h3 {
    font: rem(17) $font-source-sans;
    text-align: left;
    margin: 0 0 rem(10) rem(12);
  }
}

.fp-response-error {
  height: rem(50);
  font: rem(13) $font-source-sans;
  &-error {
    color: $seity-orange;
  }
}

.fp-buttons {
  display: flex;
  width: rem(250);
  justify-content: space-between;
}

.fp-succuess-modal {
  p {
    margin-bottom: rem(60) !important;
    margin-top: rem(25) !important;
    color: $seity-black;
  }
}

// .fp-succuess-modal {
//   margin-top: rem(100) !important;
// }

.fp-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  color: $seity-dark-green;

  .fp-body-top {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .fp-navigation {
      padding-top: rem(48);
      padding-bottom: rem(24);
      width: 70%;
      @include respond-to(tablet) {
        width: 81%;
      }
      @include respond-to(mobile) {
        width: 87%;
      }
      .fb-back-button {
        position: unset;
      }
    }

    .fp-form-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-width: rem(1032);
      width: 70%;
      @include respond-to(tablet) {
        width: 81%;
      }
      @include respond-to(mobile) {
        width: 87%;
      }

      h1 {
        padding: rem(48) 0 rem(24) 0;
        margin-bottom: 0;
        font-family: Source Serif Pro;
        font-size: rem(27);
        font-weight: 600;
        line-height: rem(36);
        letter-spacing: 0;
        text-align: left;

        @include respond-to(tablet) {
          font-size: rem(42);
          font-weight: 600;
          line-height: rem(53);
        }
      }

      .fp-form-content {
        display: flex;
        flex-direction: column;
        gap: rem(32);
        height: 100%;
        justify-content: space-between;

        .email-phone-container {
          display: flex;
          flex-direction: column;
          gap: rem(16);

          .divider {
            display: flex;
            align-items: center;

            .half-divider {
              height: rem(0.5);
              background-color: rgba($color: $seity-black, $alpha: 0.2);
              flex-grow: 1;
            }

            .divider-text {
              padding-inline: rem(8);
              font-family: Source Sans Pro;
              font-size: rem(16);
              font-weight: 600;
              line-height: rem(28);
              letter-spacing: 0em;
              text-align: center;
            }
          }

          .country-code-phone-number {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 1rem;
            position: relative;
            margin-top: 1rem;

            .seity-select-box-form-group {
              width: 5.25rem;
              label {
                position: absolute;
                top: -1.25rem;
              }
              .seity-select-box-trigger {
                padding-bottom: 0;
                div {
                  padding: 0.6rem;
                  text-align: center;
                }
                img {
                }
              }
              .seity-select-box-options {
                width: 19rem;
              }
            }

            .seity-input-with-label {
              width: 100%;
              label {
                display: none;
              }
            }
          }
        }

        label {
          margin: 0 !important;
          font-family: Source Sans Pro;
          font-size: rem(16);
          font-weight: 400;
          line-height: rem(24);
          letter-spacing: 0;
          text-align: left;
        }

        input::placeholder {
          font-family: Source Sans Pro;
          font-size: rem(16);
          font-weight: 400;
          line-height: rem(22);
          letter-spacing: 0;
          text-align: left;
          color: $seity-middle-grey;
        }

        .form-instruction-text {
          font-family: Source Sans Pro;
          font-size: rem(16);
          font-weight: 400;
          line-height: rem(24);
          letter-spacing: 0;
          text-align: left;
          color: $seity-dark-grey;

          .requirement-text {
            color: $seity-red-orange;
            font-style: italic;
          }
        }

        .dob-label-container {
          display: flex;
          justify-content: space-between;

          .requirement-text {
            color: $seity-red-orange;
            font-style: italic;
          }
        }
      }
    }
  }

  .fp-body-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    padding-block: rem(48);
    width: 70%;
    @include respond-to(tablet) {
      width: 81%;
    }
    @include respond-to(mobile) {
      width: 87%;
    }

    .next-button-container {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: auto;

      .next-button {
        height: rem(60);
        width: rem(570);

        @include respond-to(mobile) {
          width: rem(342);
        }
      }
    }
  }
}
