@import '../../../assets/sass/global';

.learn-wrapper {
  margin: rem(40) 0 rem(40) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  

  &-back-button {
    top: $rem-desktop-header-height;
    margin-top: rem(40);
  }

  &-description {
    margin-top: rem(40);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    text-align: center;
    background-color: #fff;
    padding: rem(20);

    >img {
      margin-bottom: rem(10);
      width: rem(48);
      height: rem(48);
    }

    >p {
      font: normal rem(18) $font-source-sans;
    }
  }
  >h1 {
    font: 600 rem(42) $font-source-serif;
  }
  >p {
    font: normal rem(16) $font-source-sans;
    margin: rem(20) 0 !important;
    color: $seity-black;
    text-align: center;
    max-width: rem(685);
    width: 95%;
  }
  > span {
    background-color: #FCBD31;
    padding: 0 rem(8);
    border-radius: rem(4);
    font: 600 rem(14) $font-source-sans;
    color: black;
  }
}

.learn-item {
  max-width: 100% !important;
  align-self: center;
}


.syncs-border-radius {
  border-radius: rem(20) rem(20) rem(20) 0;
}

.story-border-radius {
  border-radius: 0 rem(20) rem(20) rem(20);
}

.story-border-radius .story-icon {
  width: 50px;
}

.devider {
  height: rem(3);
  border-bottom: rem(1) solid #e4e4e4;
  margin: rem(40) 0 0;
  width: 100%;
}

.good-life-item {
  width: rem(372);
  .post-list-item-image {
    width: rem(372);
    height: rem(200);
  }
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: fit-content;
  margin: 0 !important;
}

.button-prev {
  position: absolute;
  top: rem(-40);
}

.next {
  position: absolute;
  right: 0;
  top: rem(-10);
  cursor: pointer;
  z-index: 2;
}
.prev {
  position: absolute;
  top: rem(-10);
  cursor: pointer;
  z-index: 2;
}