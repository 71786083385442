@import "../../../../assets/sass/global";

.normal-bubble {
  background-color: $seity-timberwolf;
  border: 4px solid $seity-grey-gainsboro;
  border-radius: rem(20);
  display: flex;
  flex-direction: column;
  align-items: center !important;
  margin-top: rem(10);
  margin-bottom: rem(20);
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
}

.emergency-bubble {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  border: solid 4px #df5135;
  border-radius: rem(12);
  background-color: #F8B3AB;
  margin-top: rem(10);
   width: 100%;
}

.resource-header {
  font-family: $font-source-sans;
  color: $seity-black;
  font-weight: bold;
  margin-top: rem(25);
  font-size: 16px;
  font-style: normal;
}

.resource-footer {
  font-family: $font-source-sans;
  color: $seity-dark-green;
  font-size: 18px;
  line-height: 25.14px;
  font-style: normal;
  font-weight: 500;
  margin: rem(16) rem(25) rem(25) rem(25);
  text-align: center;
}