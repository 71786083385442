/* styles.scss */

@import '../../../../assets/sass/global';

.cardContainer {
  margin-bottom: 10px;
}

.touchableCard {
  background: none;
  border: none;
  padding: 0;
  width: 100%;
  text-align: left;
}

.subcategoryCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #FFFFFF;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #C1C7CD;
  position: relative;
}

.subcategoryCard.expanded {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-width: 0;
}

.subcategoryImage {
  margin-right: 12px;
  width: 40px;
  height: 40px;
}

.svgPlaceholder {
  background-color: #E4E4E4;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 20px;
}

.svgPlaceholderText {
  font-size: 10px;
  color: #778595;
}

.subcategoryTextContainer {
  flex: 1;
}

.subcategoryText {
  font-size: 16px;
  font-weight: 600;
  font-family: $font-source-sans;
  color: $seity-black;
  margin-bottom: 4px;
}

.dragonImage {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.expandIcon {
  margin-left: 10px;
  transition: transform 0.2s;
}

.expandIcon.rotated {
  transform: rotate(90deg);
}

.expandedContent {
  background-color: #FFFFFF;
  padding: 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #C1C7CD;
  border-top: none;
}

.dragonScoreText {
  font-size: 28px;
  font-weight: 600;
  font-family: $font-source-sans;
  color: $seity-black;
  text-align: center;
  margin-bottom: 4px;
}

.dragonTitleText {
  font-size: 18px;
  font-weight: 600;
  font-family: $font-source-sans;
  color: $seity-black;
  text-align: center;
  margin-bottom: 20px;
}

.dragonText {
  font-size: 14px;
  font-family: $font-source-sans;
  color: $seity-black;
  margin-bottom: 10px;
}

.retakeSurveyButton {
  margin-bottom: 10px;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid $seity-black;
  border-radius: 56px;
  color: $seity-black;
  padding: 10px;
  font-family: $font-source-sans;
  font-weight: 600;
  cursor: pointer;
}

.historyTitle {
  font-size: 18px;
  font-weight: 600;
  font-family: $font-source-sans;
  color: $seity-black;
  line-height: 28px;
  text-align: left;
  margin-bottom: 10px;
}

.historyContentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scoreHistoriesContainer {
  display: flex;
  align-items: center;
}

.scoreHistoryItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
}

.scoreHistoryDate {
  font-size: 12px;
  font-family: $font-source-sans;
  color: #677280;
  text-align: center;
  margin-top: 5px;
  white-space: nowrap;
}

.historyText {
  flex: 1;
  font-size: 14px;
  font-family: $font-source-sans;
  color: $seity-black;
  text-align: left;
  padding-left: 10px;
}

.scrollContainer {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
}

.chevronLeft,
.chevronRight {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.scoreHistoriesScroll {
  display: flex;
  overflow-x: auto;
  margin: 0 10px;
}

.scoreHistoriesScroll::-webkit-scrollbar {
  display: none;
}
