@import '../../../assets/sass/global';



.pd-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  margin: clamp(5px, 2vh, 40px) 0 clamp(5px, 4vh, 40px) 0;
  width: 100%;

  overflow: hidden;

  @include respond-to(small-mobile) {
    margin: clamp(5px, 2vh, 40px) 0 0 0;
  }

  h1 {
    color: $seity-dark-green;
    margin: 0 0 clamp(5px, 2vh, 25px) 0;

    font: rem(40) $font-source-serif;

    @include respond-to(small-mobile) {
      font-size: rem(26); 
    }
  }

  img {
    width: rem(120);
    z-index: 1;

    @include respond-to(small-mobile) {
      width: rem(80);
    }
  }

  &-background {
    height: rem(251);
    width: 100%;
    max-width: rem(600);
    border-radius: rem(72) 0 rem(72) rem(72);
    margin: rem(-60) 0 0 0;

    @include respond-to(small-mobile) {
      margin: rem(-40) 0 0 0;
    }

    &-score {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      position: relative;
      left: rem(200);
      top: rem(5);

      font: rem(42) $font-source-sans;

      @include respond-to(small-mobile) {
        left: 35%;
        font-size: rem(24);
      }

    }
    &-progress-bar {
      margin: rem(-5) 0 0 0;
      border: rem(1) solid $seity-dark-green;
      width: rem(60);

      @include respond-to(small-mobile) {
        width: rem(68);
      }
    }
  }
  &-foreground {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
    width: 100%;
    max-width: rem(600);
    height: auto;

    margin: rem(20) 0 0 0;
    padding: rem(45) rem(122) rem(40) rem(122);
    border-radius: rem(72) 0 rem(72) rem(72);

    @include respond-to(small-mobile) {
      padding: rem(30) rem(15) rem(20) rem(30);
      border-radius: rem(72) 0 rem(50) rem(50);
      height: rem(1000);
    }

    h6 {
      font: rem(18)/rem(24) $font-source-sans;
      
      @include respond-to(small-mobile) {
        font-size: rem(17);
      }
    }
    b {
      font: 700 rem(20) $font-source-serif;
      margin: rem(50) 0 0 0;
    }
  }

}