@import "../../../assets/sass/global";

.settings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $seity-dark-green;
  padding: rem(50) 0 rem(40) 0;
  width: 95%;
  max-width: rem(766);
  min-width: rem(400);

  .settings-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-right: auto;
    margin-left: 4.5rem;
    margin-bottom: 2.5rem;

    .email {
      font: 0.9rem $font-source-serif;
      line-height: 1rem;
      margin: 0;
      color: $seity-dark-grey;
      font-weight: 600;
    }
  }

  @include respond-to(mobile) {
    padding: rem(20) 0 rem(20) 0;
    min-width: rem(300);
  }

  h1 {
    font: 600 1.8rem $font-source-serif;
    line-height: 2.2rem;
    margin: 0;
    color: $seity-black;
    text-align: left;
  }

  h2 {
    margin: rem(30) 0 0 0;
    align-self: flex-start;
    font: rem(26) $font-source-serif;
    font-weight: 600;
  }

  > h4 {
    font: bold rem(18) $font-source-sans;
    color: $seity-world;
    cursor: pointer;
    margin-bottom: rem(15);
    &:hover {
      text-decoration: underline;
    }
    &.disabled {
      user-select: none;
      pointer-events: none;
      opacity: 0.7;
    }
  }
}

.settings {
  &-button-wrapper {
    margin: 0 0 rem(16) 0;
    width: 100%;
    text-align: center;

    @include respond-to(mobile) {
      margin: 0 0 rem(20) 0;
    }
  }

  &-button {
    width: 100%;
    max-width: rem(284);
  }
}

.section-list {
  width: 80%;
  margin-top: rem(20);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(96, 97, 112, 0.16), 0 0 2px 0 rgba(40, 41, 61, 0.04);

  &-item:not(:last-child):before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    height: 1px;
    border-bottom: 1px solid $seity-gallery;
    width: 90%;
    transform: translateX(-50%);
  }

  &-item {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: rem(30);
    position: relative;

    &.disabled {
      user-select: none;
      pointer-events: none;
      opacity: 0.7;
    }

    .caret {
      position: absolute;
      right: rem(32);
      width: rem(9);
      height: rem(17);
    }

    .edit {
      position: absolute;
      right: rem(32);
      width: rem(24);
      height: rem(24);
    }

    > img {
      width: rem(32);
      height: rem(32);
      margin-right: rem(16);
    }

    > h2 {
      font: normal rem(18) $font-source-sans;
      margin: 0;
      align-self: center;
      @include respond-to(mobile) {
        max-width: rem(150);
      }
    }

    .item-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .item-content {
        margin-top: rem(10);
        > p {
          font: normal rem(14) $font-source-sans;
          color: $seity-dark-grey;
        }

        .add-text {
          text-decoration: underline;
          color: $seity-teal-medium;
        }
      }
    }

    .item-header {
      display: flex;
      align-items: center;

      > img {
        width: rem(32);
        height: rem(32);
        margin-right: rem(16);
      }

      > h2 {
        font: normal rem(18) $font-source-sans;
        margin: 0;
        align-self: center;
        @include respond-to(mobile) {
          max-width: rem(150);
        }
      }
    }
  }
}
