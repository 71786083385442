@import "../../../assets/sass/global";

.credential-verification-modal {
  width: rem(568);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: rem(32);
  border-radius: rem(8);
  box-shadow: rem(0) rem(20) rem(32) rem(0) rgba(96, 97, 112, 0.24), rem(0) rem(2) rem(8) rem(0) rgba(40, 41, 61, 0.08);
  background-color: var(--white);

  @include respond-to(tablet) {
    width: rem(462);
  }

  @include respond-to(desktop) {
    width: rem(568);
  }

  &__content {
    text-align: center;
    > h2,
    > h4 {
      line-height: 1.25;
      text-align: center;
    }
    > h2 {
      font-weight: 600;
      font-size: rem(24);
      color: $seity-black;
      margin-bottom: rem(24);
    }
    > h4 {
      font-weight: 400;
      font-size: rem(18);
      color: $seity-black;
    }
    > button {
      width: rem(262);
      margin-bottom: rem(16);
    }
  }

  &__hypertext {
    margin-top: rem(24);
    margin-bottom: rem(24);
  }
}
