@import '../../../assets/sass/global';

p {
  padding: 0 !important;
  margin: 0 !important; 
}

.todo-container {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  left: 0;
  opacity: 0;
  justify-content: flex-end;
}
.seity-todo-mask {
  width: 100%;
  height: 100%;
  position: fixed;
}
.seity-todo {
  width: 100%;
  min-width: rem(340);
  max-width: rem(390);
  height: calc(100vh - 173px);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: rem(550);
  top: rem(50);
  z-index: 19;
  padding: rem(10) rem(20);
  background-color: #fff;
  border-bottom-left-radius: rem(40);
  border-bottom-right-radius: rem(40);
  box-shadow: inset 0px 2px 10px -6px rgb(50 50 50 / 50%);
  a {
    margin-bottom: 0 !important;
  }
  &__title {
    width: 100%;
    text-align: center;
    border-bottom: rem(1.5) solid $seity-cream;
    color: $seity-cream;
    padding: rem(5);
    span {
      @include font-base($font-source-sans, bold, 700);
      font-size: rem(20);
    }
  }
  &__content {
    flex: 1;
    width: 100%;
    padding: rem(17) 0;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
.todo-item-container {
  width: '100%';
  padding: rem(18) rem(20);
  border-radius: rem(8);
  margin-bottom: rem(16);
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    text-decoration: none !important;
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
  }
  &__icon {
    width: rem(32);
    height: rem(32);
  }
  &__title {
    margin-left: rem(10) !important;
    @include font-style(
      rem(21),
      600,
      #fff,
      left,
      rem(28),
      $family: $font-source-serif
    );
  }
}
.up-to-date {
  @include font-style(
    rem(21),
    normal,
    $seity-dark-grey,
    center,
    rem(28),
    $family: $font-source-sans
  );
}
.todo-container.show-todo {
  opacity: 1;
  display: flex;
}
.todo-container.hide-todo {
  display: none;
}
.animation-slideDown {
  display: flex;
  animation-name: fadeInDown;
  animation-duration: 0.2s;
  animation-timing-function: ease;
}
.animation-slideUp {
  animation-name: fadeInUp;
  animation-duration: 0.9s;
  animation-timing-function: ease;
}
@keyframes fadeInDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes fadeInUp {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}
