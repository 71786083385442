@import "../../../assets/sass/global";

.cig {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  @include respond-to(mobile) {
    flex-direction: column;
  }
}

.cig-due {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;

  @include respond-to(mobile) {
    flex-direction: column;
  }
}

.cig-container {
  width: 100%;
  max-height: rem(1065);
  background: white;
  border-radius: rem(30) rem(30) rem(30) rem(30);
  padding: rem(24) rem(24);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: $font-source-sans;
  color: $seity-black;
  margin-bottom: 3.25rem;
  box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.1);

  @include respond-to(mobile) {
    padding: rem(25) rem(19) rem(34) rem(19);
  }

  h1 {
    @include font-style(rem(27), 600, #000, left, 1.14, $family: $font-source-serif);

    margin: 0 0 rem(8) 0;
  }

  &-wellbeing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: rem(12);
    &-header {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      &-description {
        @include font-style(rem(16), normal, #000, left, 1.33, $family: $font-source-sans);

        margin: 0 0 rem(20) 0;
        @include respond-to(mobile) {
          margin: 0 0 rem(10) 0;
        }
      }
    }
  }
  &-other {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: rem(30);
    margin-right: rem(12);
    @include respond-to(mobile) {
      margin-top: rem(30);
    }
    &-group {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
  &-button {
    align-self: center;
    margin-top: rem(40);
    padding: rem(16) rem(95) !important;
    @media only screen and (max-width: rem(768)) {
      width: 100%;
    }
    @media only screen and (min-width: rem(769)) {
      padding: rem(17) rem(75) rem(17) rem(75) !important;
    }
  }
}

.cig-graph-header-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: rem(16);
  max-width: rem(120);
  padding: 0 rem(5);
  width: rem(111);
  flex: 1;
  @include respond-to(mobile) {
    width: rem(78);
  }
  img {
    width: rem(50);
    height: rem(50);
  }
  &-text {
    margin: rem(3) 0 rem(3) 0;
    @include respond-to(mobile) {
      font-size: rem(16);
    }
  }
  &-bar {
    max-width: rem(101);
    width: 100%;
  }
}

.cig-check-in {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: rem(16);

  &-date {
    width: rem(150);
    height: rem(39);
    background-color: $seity-spirit;
    color: $seity-dark-green;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: rem(8);
    margin-left: rem(15);
    font: rem(17) $font-source-sans;
    text-transform: capitalize;
  }
}
.cig-triangle-selection-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}
.cig-triangle-selection {
  display: flex;
  margin-left: 17.5%;
  width: 76%;
  margin-top: rem(8);

  &-spacer {
    flex: 1;
  }

  @media only screen and (max-width: rem(400)) {
    margin-left: 12%;
  }
  @media only screen and (min-width: rem(400)) and (max-width: rem(450)) {
    margin-left: 14%;
  }
  @media only screen and (min-width: rem(450)) and (max-width: rem(550)) {
    margin-left: 16%;
  }
  @media only screen and (min-width: rem(550)) and (max-width: rem(768)) {
    margin-left: 16%;
  }
  @media only screen and (min-width: rem(768)) and (max-width: rem(900)) {
    margin-left: 16.5%;
  }
  @media only screen and (min-width: rem(900)) and (max-width: rem(1000)) {
    margin-left: 17%;
  }
  @media only screen and (min-width: rem(1000)) and (max-width: rem(1152)) {
    margin-left: 17%;
  }
}

#cig-container-wellbeing-graph {
  margin: rem(20) 0 0 0;
}

#cig-container-other-graph {
  margin: rem(10) 0 0 0;
}

.cig-complete-check-in {
  height: rem(339);
  border-radius: 0 rem(72) 0 rem(72);
  align-items: flex-end;
  align-self: flex-start;

  @include respond-to(mobile) {
    border-radius: rem(25) 0 rem(25) 0;
    height: rem(50);
    padding-bottom: rem(5);
  }
}

.cig-complete-check-in-hide {
  height: rem(339);
  border-radius: 0 rem(72) 0 rem(72);
  align-items: flex-end;
  align-self: flex-start;

  @include respond-to(mobile) {
    height: 0;
  }
}

.cig-line-chart-container {
  width: 100%;
}

.seity-chart {
  position: relative;
  height: min-content;
  border: none;
  overflow: hidden;
  border-radius: rem(30) 0 rem(30) 0;
  width: "100%";

  canvas {
    background-color: $seity-cream;
    border-radius: rem(30) 0 rem(30) 0;
    height: rem(100) !important;
  }

  &-data-label {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    right: 0;
    width: rem(65);
    height: rem(40);
    border-radius: rem(30) 0 rem(30) 0;
    z-index: 1;
    font: rem(17) / rem(21) $font-source-serif-bold;
  }

  &-header {
    display: flex;
    position: absolute;
    z-index: 1;
    top: rem(12);
    left: rem(10);
  }

  &-title {
    font: rem(18) / rem(23) $font-source-serif-bold;
    margin-left: rem(8);
  }
}
