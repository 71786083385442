@import "../../../assets/sass/global";
@import "../../familySharing/FSGroups/styles.scss";

.fsGroupsDetail {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: rem(172);
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(24);
    h1 {
      margin: 0;
      font: 600 rem(26) $font-source-serif;
      color: black;
      @include respond-to(tablet) {
        font-size: rem(27);
      }
      @include respond-to(desktop) {
        font-size: rem(42);
      }
    }
    &__name {
      display: flex;
      margin-top: rem(32);
      > button {
        margin-left: rem(10);
      }
    }
    &__help {
      position: absolute;
      right: rem(16);
    }
  }
  &-list {
    margin-top: rem(32);
    // overflow-y: auto;
    > h5 {
      font: 400 rem(16) $font-source-sans;
      @extend .fsGroups-margin !optional;
      margin-top: rem(16);
    }
    > h6 {
      font: 400 rem(16) $font-source-sans;
      @extend .fsGroups-margin !optional;
      margin-top: rem(16);
      color: black;
    }
    &__item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: rem(1) solid #f0f0f0;
      @extend .fsGroups-padding !optional;
      > span {
        font: 400 rem(16) $font-source-sans;
        margin-left: rem(8);
        margin-right: auto;
      }
      > button {
        height: 100%;
        padding: rem(15) rem(5) rem(15) rem(25);
      }
    }
  }
  &-add {
    position: fixed;
    bottom: rem(32);
    right: rem(32);
    background-color: $seity-teal-medium;
    width: rem(56);
    height: rem(56);
    border-radius: rem(44);
    > img {
      width: rem(25);
    }
    @include respond-to(desktop) {
      width: rem(88);
      height: rem(88);
      > img {
        width: rem(35);
      }
    }
  }
  &-delete {
    display: flex;
    align-items: center;
    margin-top: rem(24);
    @extend .fsGroups-margin !optional;
    > span {
      margin-left: rem(8);
      font: 600 rem(16) $font-source-sans;
      color: #de5135;
    }
  }
}

.fsGroup-avatar {
  position: relative;
  > img {
    width: rem(100);
    height: rem(100);
    border: rem(1) solid $seity-gray;
    border-radius: rem(50);
    object-fit: cover;
    @include respond-to(desktop) {
      width: rem(160);
      height: rem(160);
      border-radius: rem(80);
    }
  }
  &__camera {
    position: absolute;
    right: 0;
    bottom: rem(10);

    [type="file"] {
      height: 0;
      overflow: hidden;
      width: 0;
    }

    [type="file"] + label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(40);
      height: rem(40);
      border-radius: rem(20) !important;
      border: 1px solid #e4e4e4 !important;
      color: #fff;
      cursor: pointer;
      transition: all 0.3s;
      margin: 0;
      background-color: #fff;
      &:hover {
        background-color: #f0f0f0;
      }
      @include respond-to(desktop) {
        width: rem(64);
        height: rem(64);
        border-radius: rem(32) !important;
      }
    }
  }
}

.fsShortMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: rem(34);
  top: rem(50);
  padding: 0 rem(32);
  border-radius: var(--spacing-space-between, 8px);
  background: #fff;
  box-shadow: 0px 20px 32px 0px rgba(96, 97, 112, 0.24), 0px 2px 8px 0px rgba(40, 41, 61, 0.08);
  z-index: 9;
  @include respond-to(tablet) {
    right: rem(82);
  }
  @include respond-to(desktop) {
    right: rem(218);
  }
  button {
    padding: rem(32) 0;
    display: flex;
    align-items: center;
    border-bottom: rem(1) solid #f0f0f0;
    > img {
      margin-right: rem(16);
    }
    > span {
      font: 600 rem(16) $font-source-sans;
      color: $seity-black;
    }
  }
}

.fsRemoveMemberModal {
  width: rem(606);
  > h2 {
    text-align: center;
  }
  > button {
    margin-bottom: 0;
  }
}
